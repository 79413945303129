import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { ConsignmentForm } from '@/types/Consignment';
import { createSelector } from '@reduxjs/toolkit';
import { getAuthToken, getUserData } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import api from '@/redux/api/consignment';

/* Action Types */
export const SUBMIT_CONSIGNMENT_FAIL = 'la/user/consignment/FAIL';
export const SUBMIT_CONSIGNMENT_REQUEST = 'la/user/consignment/REQUEST';
export const SUBMIT_CONSIGNMENT_SUCCESS = 'la/user/consignment/SUCCESS';

// reducer
export type ConsignmentSlice = {
    error: boolean;
    errorMessage: any;
    submitted: boolean;
    success: boolean;
};

export const defaultConsignmentSlice: ConsignmentSlice = {
    error: false,
    errorMessage: undefined,
    submitted: false,
    success: false,
};

export default function reducer(state: ConsignmentSlice = defaultConsignmentSlice, action: any = {}): ConsignmentSlice {
    switch (action.type) {
        case SUBMIT_CONSIGNMENT_REQUEST:
            return {
                ...state,
                error: false,
                submitted: true,
                success: false,
            };

        case SUBMIT_CONSIGNMENT_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };

        case SUBMIT_CONSIGNMENT_FAIL:
            return {
                ...state,
                error: true,
                errorMessage: action.payload,
                submitted: false,
                success: false,
            };

        default:
            return state;
    }
}

const stateSelector = (state: GlobalState) => state;
export const getSubmitConsignment = createSelector(stateSelector, (state) => state.consignment);

/* ACTION CREATORS */
export const submitConsignment =
    (form: ConsignmentForm, file?: File) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const userData = getUserData(state);
            dispatch({
                type: SUBMIT_CONSIGNMENT_REQUEST,
            });
            await api.postConsignment({
                authToken,
                deployment,
                file,
                form,
                userData,
            });
            return dispatch({
                payload: form,
                type: SUBMIT_CONSIGNMENT_SUCCESS,
            });
        } catch (error) {
            return dispatch({
                error: true,
                payload: error,
                type: SUBMIT_CONSIGNMENT_FAIL,
            });
        }
    };
