import { createSlice } from '@reduxjs/toolkit';
import { defaultCatalogItemsSlice } from '@/redux/modules/catalog/items/catalogItems.types';
import { fetchCatalogItemSummaries } from '@/redux/modules/catalog/items/catalogItems.actions';

const catalogItemsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchCatalogItemSummaries.pending, (slice) => {
            slice.loading = true;
        });
        builder.addCase(fetchCatalogItemSummaries.fulfilled, (slice, { meta, payload }) => {
            // we don't want to save our cursored paging data in here
            if (meta.arg.query.index === undefined) {
                slice.loaded = Date.now();
                slice.byId[meta.arg.catalogId] = payload.items.map(({ itemId }) => itemId);
                slice.pagination = {
                    ...defaultCatalogItemsSlice.pagination,
                    ...meta.arg.query,
                    totalRecords: payload.recordCount || payload.items.length || 0,
                };
            }
            slice.loading = false;
        });
        builder.addCase(fetchCatalogItemSummaries.rejected, (slice) => {
            slice.loading = false;
        });
    },
    initialState: defaultCatalogItemsSlice,
    name: 'catalogItems',
    reducers: {},
});

export const { reducer: catalogItemsReducer } = catalogItemsSlice;
