import { CategoryAdState, emptyAdUnit, KeyValues } from './catalogAd.types';
import { createSelector } from '@reduxjs/toolkit';
import { generateAdKey } from './catalogAd.helpers';
import { GlobalState } from '@/redux/store';
import isEmpty from 'lodash/isEmpty';
import ms from 'ms';

export const CATALOG_AD_REDUX_STORE_TIME = ms('5s');

const stateSelector = (state: GlobalState) => state;
const catalogAdSlice = createSelector(stateSelector, (state) => state.catalogAd);

const keyIdSelector = (_: GlobalState, id: KeyValues) => id;
const idSelector = (_: GlobalState, id: string) => id;

export const getAllCatalogAds = createSelector(catalogAdSlice, (state: CategoryAdState) => state.byId);

export const getCatalogAd = createSelector(
    [getAllCatalogAds, keyIdSelector],
    (byId, { categoryIds, keywords, pageName }) => {
        const key = generateAdKey({ categoryIds, keywords, pageName });
        return byId[key] || emptyAdUnit;
    }
);

export const shouldFetchCatalogAd = createSelector([catalogAdSlice, idSelector], (state, key) => {
    if (isEmpty(key)) {
        return false;
    }
    const item = state.byId[key];
    if (item) {
        const loaded = state.loaded[key] || 0;
        const time = Date.now();
        const diff = time - loaded;
        if (diff < CATALOG_AD_REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = state.loading.includes(key);
    return !loading;
});
