import { ADD_LA_PAYMENT_CARD_SUCCESS, SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS } from '@/redux/modules/actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultCheckoutSlice } from '@/redux/modules/checkout/checkout.types';
import { fetchInvoice, reopenPaidInvoice } from '@/redux/modules/checkout/checkout.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

const checkoutSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchInvoice.pending, (state, { meta }) => {
            if (state.error[meta.arg]) {
                delete state.error[meta.arg];
            }
            state.loading = union(state.loading, [meta.arg]);
        });
        builder.addCase(fetchInvoice.fulfilled, (state, { meta, payload }) => {
            state.loading = difference(state.loading, [meta.arg]);
            if (Boolean(payload)) {
                state.loaded[meta.arg] = Date.now();
                state.byId[meta.arg] = payload;
            }
            if (state.initialLoad === 0) {
                state.initialLoad = Date.now();
            }
        });
        builder.addCase(fetchInvoice.rejected, (state, { meta, payload }) => {
            state.error[meta.arg] = payload;
            state.loading = difference(state.loading, [meta.arg]);
        });
        builder.addCase(ADD_LA_PAYMENT_CARD_SUCCESS, (state) => {
            state.addedPaymentMethodOnCheckout = true;
        });
        builder.addCase(SUBMIT_CREATE_PLAID_BANK_ACCOUNT_SUCCESS, (state) => {
            state.addedPaymentMethodOnCheckout = true;
        });
        builder.addCase(reopenPaidInvoice.pending, (state, { meta }) => {
            if (state.error[meta.arg.catalogId]) {
                delete state.error[meta.arg.catalogId];
            }
            state.loading = union(state.loading, [meta.arg.catalogId]);
        });
        builder.addCase(reopenPaidInvoice.fulfilled, (state, { meta, payload }) => {
            state.loading = difference(state.loading, [meta.arg.catalogId]);
            state.loaded[meta.arg.catalogId] = Date.now();

            if (Boolean(payload)) {
                state.byId[meta.arg.catalogId] = {
                    ...state.byId[meta.arg.catalogId],
                    invoice: {
                        ...state.byId[meta.arg.catalogId].invoice,
                        ...payload,
                    },
                };
            }

            if (state.initialLoad === 0) {
                state.initialLoad = Date.now();
            }
        });
        builder.addCase(reopenPaidInvoice.rejected, (state, { meta, payload }) => {
            state.error[meta.arg.catalogId] = payload;
            state.loading = difference(state.loading, [meta.arg.catalogId]);
        });
    },
    initialState: defaultCheckoutSlice,
    name: 'checkout',
    reducers: {},
});

export const { reducer: checkoutReducer } = checkoutSlice;
