import { CATALOG_ANNOUNCEMENT_SLICE_CACHE_TIME } from '@/redux/modules/catalog/announcement/catalogAnnouncement.types';
import { CatalogAnnouncement, emptyCatalogAnnouncement } from '@/types/CatalogAnnouncement';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import isEmpty from 'lodash/isEmpty';

const stateSelector = (state: GlobalState) => state;
const catalogAnnouncementSlice = createSelector(stateSelector, (state) => state.catalogAnnouncement);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(catalogAnnouncementSlice, (state) => state.byId);

const loadedSelector = createSelector(catalogAnnouncementSlice, (state) => state.loaded);

const loadingSelector = createSelector(catalogAnnouncementSlice, (state) => state.loading);

export const getCatalogHasAnnouncements = createSelector([byIdSelector, idSelector], (byId, id) => byId[id]?.id > 0);

export const getActiveCatalogAnnouncement = createSelector(
    catalogAnnouncementSlice,
    (state) => state.activeAnnouncement
);

export const getCatalogAnnouncement = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || emptyCatalogAnnouncement
);
export const getCatalogAnnouncementTitle = createSelector(getCatalogAnnouncement, ({ title }) => title || '');

const getLoadTimeForCatalogAnnouncement = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);

const isCatalogAnnouncementLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

export const shouldFetchCatalogAnnouncement = createSelector(
    [idSelector, getCatalogAnnouncement, getLoadTimeForCatalogAnnouncement, isCatalogAnnouncementLoading],
    (catalogId, announcement, loaded, loading) => {
        if (!catalogId) {
            return false;
        }
        if (!isEmpty(announcement) || Boolean(loaded)) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < CATALOG_ANNOUNCEMENT_SLICE_CACHE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);

export const shouldFetchFullCatalogAnnouncement = createSelector(
    [idSelector, getCatalogAnnouncement, getLoadTimeForCatalogAnnouncement, isCatalogAnnouncementLoading],
    (catalogId, announcement, loaded, loading) => {
        if (!catalogId) {
            return false;
        }
        if (!announcement || announcement.id === 0) {
            console.error(`ERROR: Must first fetch announcement for catalog ${catalogId}`);
            return false;
        }
        const hasBody = Boolean((announcement as CatalogAnnouncement).body);
        if (Boolean(loaded) && hasBody) {
            const time = Date.now();
            const diff = time - loaded;
            const isFresh = diff < CATALOG_ANNOUNCEMENT_SLICE_CACHE_TIME;
            return !isFresh;
        }
        return !loading;
    }
);
