import { ApiHosts, handleResponseOld, makeGet, makePost } from '../../api/helpers';

/**
 * @category Shipping API
 * @see fetchShippingQuote
 */
type FetchCheckoutShippingQuoteParams = {
    authToken: string;
    deployment: string;
    invoiceId: number;
};
/**
 * Gets ShippingQuote info via Get to Shipping service's payment API
 * @function fetchShippingQuote
 * @category Shipping API
 * @param {FetchCheckoutShippingQuoteParams}
 * @see https://shipping-DEPLOYMENT.liveauctioneers.com/invoice/${invoiceId}/quote
 * @see https://github.com/LIVEauctioneers/shipping-prod/blob/master/httpdocs/api/bidders.php#L198
 */
export const fetchCheckoutShippingQuote = ({ authToken, deployment, invoiceId }: FetchCheckoutShippingQuoteParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makeGet({
            apiPath: `invoice/${invoiceId}/quote`,
            authToken,
            deployment,
            path: ApiHosts.Shipping,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Mainhost API
 * @see postShippingQuote
 */
type SubmitCheckoutShippingQuoteParams = {
    amount: number;
    authToken: string;
    bidderId: number;
    deployment: string;
    invoiceId: number;
    usingLaShipping: boolean;
};
/**
 * Gets ShippingQuote info via Get to Shipping service's payment API
 * @function postCheckoutShippingQuote
 * @category Mainhost API
 * @param {SubmitCheckoutShippingQuoteParams}
 * @see https://shipping-DEPLOYMENT.liveauctioneers.com/bidders/${bidderId}/invoice/${invoiceId}/shipping
 */
export const postCheckoutShippingQuote = ({
    amount,
    authToken,
    bidderId,
    deployment,
    invoiceId,
    usingLaShipping,
}: SubmitCheckoutShippingQuoteParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `api/bidders/${bidderId}/invoice/${invoiceId}/shipping`,
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
        });
        request.send({
            amount,
            LAShipping: usingLaShipping,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
