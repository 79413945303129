import { ApprovalStatus } from '@/types/Approval';

export type CatalogRegistration = {
    approved: boolean;
    bidLimit: number;
    blockedByAuctioneer: boolean;
    catalogId: number;
    declined: boolean;
    limited: boolean;
    notify?: boolean;
    pending: boolean;
    registered: boolean;
    suspended: boolean;
};

export type CatalogRegistrationSlice = {
    byId: {
        [catalogId: number]: CatalogRegistration;
    };
    loading: number[];
    totalMinimumBidLimitApprovedCount?: number;
};

export const defaultCatalogRegistrationSlice: CatalogRegistrationSlice = {
    byId: {},
    loading: [],
    totalMinimumBidLimitApprovedCount: 0,
};

/**
 * @category Approval API
 * @see getCatalogRegistrationsForUserByIds
 */
export type GetCatalogRegistrationsForUserByIdsParams = {
    authToken: string;
    bidderId: number;
    catalogIds: number[];
    deployment: string;
};

/**
 * @category Approval API
 * @see getCatalogRegistrationsForUserByIds
 */
export type GetCatalogRegistrationsForUserByIdsResponse = {
    error: boolean;
    payload: {
        approvalStatus: ApprovalStatus;
        bidLimit: number;
        catalogId: number;
        notify: boolean;
    }[];
};

/**
 * @category Approval API
 * @see getBidderCatalogApprovals
 */
export type GetBidderCatalogApprovalsParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * Represents a bidder's approval for a catalog
 * @category Approval API
 */
export type BidderCatalogApproval = {
    approvalStatusId: ApprovalStatus;
    approvalTypeId: number;
    bidCount: number;
    bidLimit: number;
    bidderCreated: string;
    bidderId: number;
    bidderInitiated: boolean;
    bidderName: string;
    cardOnFile: boolean;
    catalogId: number;
    catalogTitle: string;
    closedDisputeDetails: null | object;
    created: string;
    createdTS: string;
    disputeDetails: null | object;
    hasClosedDisputes: boolean;
    hasDisputes: boolean;
    houseId: number;
    isFavorited: boolean;
    isPremium: boolean;
    notify: boolean;
    paymentInfo: string;
    personalDetails: object;
    prediction: string;
    recommendedFavorite: boolean;
    registrationId: number;
    smsNumberVerified: boolean;
};

/**
 * @category Approval API
 * @see getBidderCatalogApprovals
 */
export type GetBidderCatalogApprovalsResponse = {
    error: boolean;
    payload: {
        approvals: BidderCatalogApproval[];
        totalCount: number;
        totalMinimumBidLimitApprovedCount: number;
    };
};

/**
 * @category Approval API
 * @see postAutoApproval
 */
export type PostAutoApprovalResponse = {
    error: boolean;
    payload: {
        approvalStatus: number;
        bidLimit: number;
        bidderId: number;
        catalogId: number;
    };
};

/**
 * @category Approval API
 * @see postAutoApproval
 */
export type PostAutoApprovalParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    itemId: number;
    minimumBidLimit: boolean;
};

export const emptyCatalogRegistrationState: CatalogRegistration = {
    approved: false,
    bidLimit: 0,
    blockedByAuctioneer: false,
    catalogId: 0,
    declined: false,
    limited: false,
    notify: false,
    pending: false,
    registered: false,
    suspended: false,
};

/**
 * @category Approval API
 * @see postSetCatalogNotify
 */
export type PostSetCatalogNotifyParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    notify: boolean;
};

/**
 * @category Approval API
 * @see postSetCatalogNotify
 */
export type PostSetCatalogNotifyResponse = {
    data?: {
        catalogId: number;
        catalogRegistration: CatalogRegistration;
    };
    error: boolean;
    payload: string;
};
