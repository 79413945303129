import { createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    fetchAllCategories,
    FetchAllCategoriesResponse,
    fetchCategoriesByIds,
    FetchCategoriesByIdsResponse,
} from '@/redux/modules/category/category.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '../config';
import { whichCategoriesNeeded } from '@/redux/modules/category/category.selectors';

export const loadCategories = createAsyncThunk<FetchCategoriesByIdsResponse, number[]>(
    'la/domain/category/loadCategories',
    async (categoryIds, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        return await fetchCategoriesByIds({ authToken, categoryIds, deployment });
    }
);

export const fetchCategoriesIfNeeded = createAsyncThunk<Promise<unknown>, { categoryIds: number[] }>(
    'la/domain/category/fetchCategoriesIfNeeded',
    async ({ categoryIds }, { dispatch, getState }) => {
        const state = getState();
        const needed = whichCategoriesNeeded(state, categoryIds);
        if (needed.length) {
            return dispatch(loadCategories(needed));
        }
        return Promise.resolve();
    }
);

export const fetchAllCategoriesLandingPageData = createAsyncThunk<FetchAllCategoriesResponse>(
    'la/domain/category/fetchAllCategoriesLandingPageData',
    async (_, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        return await fetchAllCategories({ authToken, deployment });
    }
);
