import { createSlice } from '@reduxjs/toolkit';
import { defaultCheckoutShippingQuoteSlice } from './checkoutShippingQuote.types';
import { loadCheckoutShippingQuote } from '@/redux/modules/checkoutShippingQuote/checkoutShippingQuote.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

const checkoutShippingQuoteSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCheckoutShippingQuote.pending, (state, { meta }) => {
            state.loading = union(state.loading, [meta.arg]);
        });
        builder.addCase(loadCheckoutShippingQuote.fulfilled, (state, { payload }) => {
            const { actionTime, data, errorCode } = payload;
            state.shippingQuote = data;
            state.quoteError = errorCode;

            state.loaded[data.quoteId] = actionTime;
            state.loading = difference(state.loading, [Number(data.quoteId)]);
        });
        builder.addCase(loadCheckoutShippingQuote.rejected, (state, { meta }) => {
            state.quoteError = 500;
            state.loading = difference(state.loading, [meta.arg]);
        });
    },
    initialState: defaultCheckoutShippingQuoteSlice,
    name: 'checkoutShippingQuote',
    reducers: {},
});

export const { reducer: checkoutShippingQuoteReducer } = checkoutShippingQuoteSlice;
