import { ApprovalStatus } from '@/types/Approval';
import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchBidLimitBalance } from '@/redux/api/bidLimit';
import { fetchLiveCatalogStatus } from '@/redux/api/liveCatalogStatus';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import {
    getBidderCatalogApprovals,
    getCatalogRegistrationsForUserByIds,
    postAutoApproval,
    postSetCatalogNotify,
} from './catalogRegistration.api';
import {
    GetBidderCatalogApprovalsResponse,
    GetCatalogRegistrationsForUserByIdsResponse,
    PostAutoApprovalResponse,
    PostSetCatalogNotifyResponse,
} from './catalogRegistration.types';
import { getCatalogRegistration } from './catalogRegistration.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getFullUrl } from '@/utils/analytics/utils/getFullUrl';
import { getLiveCatalogApproved } from '../../console';
import {
    LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS,
    LoadLiveCatalogRegistrationSuccessAction,
} from '@/redux/modules/actions/registerForCatalog';
import { trackAuctionRegistrationSubmittedAnalytics, TrackAuctionRegistrationSubmittedType } from '@/utils/analytics';

/**
 * Submit user info for automatic catalog registration approval
 */
export const submitAutoApproval = createAsyncThunk<
    PostAutoApprovalResponse['payload'],
    { catalogId: number; itemId: number; minimumBidLimit?: boolean },
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogRegistration/submitCatalogRegistrationAutoApproval', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const bidderId = getBidderId(state);
    const deployment = getDeployment(state);

    try {
        const { payload } = await postAutoApproval({
            authToken,
            bidderId,
            catalogId: params.catalogId,
            deployment,
            itemId: params.itemId,
            minimumBidLimit: params.minimumBidLimit ?? false,
        });

        trackAuctionRegistrationSubmittedAnalytics({
            pageName: getAmplitudePageNameFromPath(window.location.pathname),
            registrationType: TrackAuctionRegistrationSubmittedType.Auto,
            url: getFullUrl(deployment, window.location.pathname),
        });

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in submitting catalog registration auto approval'));
    }
});

/**
 * Get the logged-in user's registrations for a given list of catalogIds
 */
export const fetchCatalogRegistrationsForUserByIdIfNeeded = createAsyncThunk<
    void,
    { catalogIds: number[]; force?: boolean }
>(
    'la/ui/catalogRegistration/fetchCatalogRegistrationsForUserByIdIfNeeded',
    async ({ catalogIds, force = false }, { dispatch, getState }) => {
        const shouldFetchRegistrations = Boolean(catalogIds.length) && isUserLoggedIn(getState());

        if (force || shouldFetchRegistrations) {
            await dispatch(loadCatalogRegistrationsForUserById({ catalogIds }));
            await dispatch(loadBidderCatalogApprovals());
        }

        return Promise.resolve();
    }
);

/**
 * fetches the live auction state for catalog, the user's bid limit for the current live item and the user's catalog registration
 * primarily used in live bidding
 */
export const loadLiveCatalogRegistrationById = createAsyncThunk<
    {
        approvalStatus: ApprovalStatus;
        approved: boolean;
        bidLimit: number;
        catalogId: number;
        created: number;
        wasApproved: boolean;
    },
    { catalogId: number; sellerId: number },
    AsyncThunkConfigWithRejectValue
>(
    'la/ui/catalogRegistration/loadLiveCatalogRegistrationById',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const wasApproved = getLiveCatalogApproved(state, params.catalogId);
        const bidderId = getBidderId(state);

        try {
            const liveAuctionState = await fetchLiveCatalogStatus({
                authToken,
                catalogId: params.catalogId,
                deployment,
                sellerId: params.sellerId,
            });

            const bidderApproval = await fetchBidLimitBalance({
                authToken,
                bidderId,
                catalogId: params.catalogId,
                deployment,
                itemId: liveAuctionState.data?.currentItem.itemId,
            });

            // console and consoleMessages rely on this action for the time being
            const action: LoadLiveCatalogRegistrationSuccessAction = {
                error: false,
                meta: {
                    actionTime: Date.now(),
                    catalogId: params.catalogId,
                },
                payload: {
                    approvalStatus: bidderApproval.payload.approvalStatus,
                    approved:
                        bidderApproval.payload.approvalStatus === ApprovalStatus.Approved ||
                        bidderApproval.payload.approvalStatus === ApprovalStatus.Limited,
                    bidLimit: bidderApproval.payload.bidLimit,
                    catalogId: params.catalogId,
                    created: Date.now(),
                    wasApproved,
                },
                type: LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS,
            };

            dispatch(action);

            return action.payload;
        } catch (error) {
            return rejectWithValue(new Error('Error in fetching user catalog registrations'));
        }
    }
);

/**
 * fetches the live auction state for catalog, the user's bid limit for the current live item and the user's catalog registration
 * primarily used in live bidding
 */
export const fetchLiveCatalogRegistrationByIdIfNeeded = createAsyncThunk<
    void,
    { catalogId: number; force?: boolean; sellerId: number }
>(
    'la/ui/catalogRegistration/fetchLiveCatalogRegistrationByIdIfNeeded',
    async ({ catalogId, force = false, sellerId }, { dispatch, getState }) => {
        const shouldFetchRegistrations = Boolean(catalogId) && Boolean(sellerId) && isUserLoggedIn(getState());

        if (force || shouldFetchRegistrations) {
            await dispatch(loadLiveCatalogRegistrationById({ catalogId, sellerId }));
        }

        return Promise.resolve();
    }
);

/**
 * Turns on the user's catalog notifications for a given catalogId
 */
export const toggleCatalogNotifications = createAsyncThunk<
    PostSetCatalogNotifyResponse['data'],
    { catalogId: number; notify: boolean },
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogRegistration/toggleCatalogNotifications', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const bidderId = getBidderId(state);
    const deployment = getDeployment(state);

    const catalogRegistration = getCatalogRegistration(state, params.catalogId);

    try {
        await postSetCatalogNotify({
            authToken,
            bidderId,
            catalogId: params.catalogId,
            deployment,
            notify: params.notify,
        });

        return {
            catalogId: params.catalogId,
            catalogRegistration: {
                ...catalogRegistration,
                notify: params.notify,
            },
        };
    } catch (error) {
        return rejectWithValue(new Error('Error in setting catalog notifications for user'));
    }
});

/**
 * Get the logged-in user's registrations for a given list of catalogIds
 */
export const loadCatalogRegistrationsForUserById = createAsyncThunk<
    GetCatalogRegistrationsForUserByIdsResponse['payload'],
    { catalogIds: number[] },
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogRegistration/loadCatalogRegistrationsForUserById', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const bidderId = getBidderId(state);
    const deployment = getDeployment(state);

    try {
        const { payload } = await getCatalogRegistrationsForUserByIds({
            authToken,
            bidderId,
            catalogIds: params.catalogIds,
            deployment,
        });

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in fetching user catalog registrations'));
    }
});

export const loadBidderCatalogApprovals = createAsyncThunk<
    GetBidderCatalogApprovalsResponse['payload'],
    undefined,
    AsyncThunkConfigWithRejectValue
>('la/ui/catalogRegistration/loadBidderCatalogApprovals', async (params, { getState, rejectWithValue }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const bidderId = getBidderId(state);
    const deployment = getDeployment(state);

    try {
        const { payload } = await getBidderCatalogApprovals({
            authToken,
            bidderId,
            deployment,
        });

        return payload;
    } catch (error) {
        return rejectWithValue(new Error('Error in fetching user catalog registrations'));
    }
});
