import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getDeployment } from './config';
import api from '@/redux/api/consoleBranding';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import ms from 'ms';
import union from 'lodash/union';

/* Action Types */
const LOAD_CONSOLE_BRANDING_FAIL = 'la/domain/consoleBranding/LOAD_FAIL';
const LOAD_CONSOLE_BRANDING_REQUEST = 'la/domain/consoleBranding/LOAD_REQUEST';
const LOAD_CONSOLE_BRANDING_SUCCESS = 'la/domain/consoleBranding/LOAD_SUCCESS';

const REDUX_STORE_TIME = ms('30m');

/* reducer */
export type ConsoleBrandingSlice = {
    byCatalogId: {};
    bySellerId: {};
    loadedCatalogs: {};
    loadedSellers: {};
    loadingCatalogs: any[];
    loadingSellers: any[];
};

export const defaultConsoleBrandingSlice: ConsoleBrandingSlice = {
    byCatalogId: {},
    bySellerId: {},
    loadedCatalogs: {},
    loadedSellers: {},
    loadingCatalogs: [],
    loadingSellers: [],
};

export default function reducer(
    state: ConsoleBrandingSlice = defaultConsoleBrandingSlice,
    action: any = {}
): ConsoleBrandingSlice {
    let existingCatalogs;
    let existingSellers;
    let loadedCatalogs;
    let loadedSellers;
    let loadingCatalogs;
    let loadingSellers;
    let time;

    switch (action.type) {
        case LOAD_CONSOLE_BRANDING_FAIL:
            return {
                ...state,
                loadingCatalogs: difference(state.loadingCatalogs, action.payload.catalogId),
                loadingSellers: difference(state.loadingSellers, action.payload.sellerId),
            };
        case LOAD_CONSOLE_BRANDING_REQUEST:
            return {
                ...state,
                loadingCatalogs: union(state.loadingCatalogs, action.payload.catalogId),
                loadingSellers: union(state.loadingSellers, action.payload.sellerId),
            };
        case LOAD_CONSOLE_BRANDING_SUCCESS:
            existingCatalogs = cloneDeep(state.byCatalogId);
            existingSellers = cloneDeep(state.bySellerId);
            loadedCatalogs = { ...state.loadedCatalogs };
            loadedSellers = { ...state.loadedSellers };
            loadingCatalogs = cloneDeep(state.loadingCatalogs);
            loadingSellers = cloneDeep(state.loadingSellers);
            time = action.meta.actionTime;

            if (action.payload.consoleBrandings) {
                action.payload.consoleBrandings.forEach((item) => {
                    let { catalogId, color = '', logoUrl = '', sellerId } = item;

                    // make sure that we're using a secure connection
                    if (logoUrl.includes('http://')) {
                        logoUrl = item.logoUrl.replace('http://', 'https://');
                    }

                    // convert to hex color style
                    if (color.includes('0x')) {
                        color = item.color.replace('0x', '#');
                    }

                    if (catalogId) {
                        existingCatalogs[catalogId] = {
                            ...item,
                            color,
                            logoUrl,
                        };
                        loadedCatalogs[catalogId] = time;
                        loadingCatalogs = difference(loadingCatalogs, [catalogId]);
                    }
                    if (sellerId) {
                        existingSellers[sellerId] = {
                            ...item,
                            color,
                            logoUrl,
                        };
                        loadedSellers[sellerId] = time;
                        loadingSellers = difference(loadingSellers, [sellerId]);
                    }
                });
            }

            return {
                ...state,
                byCatalogId: existingCatalogs,
                bySellerId: existingSellers,
                loadedCatalogs,
                loadedSellers,
                loadingCatalogs,
                loadingSellers,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const consoleBrandingSelector = createSelector(stateSelector, (state) => state.consoleBranding);

const catalogIdSelector = (state: GlobalState, catalogId: number) => catalogId;
const sellerIdSelector = (state: GlobalState, catalogId: number, sellerId: number) => sellerId;

const byCatalogIdSelector = createSelector(consoleBrandingSelector, (state) => state.byCatalogId);
const bySellerIdSelector = createSelector(consoleBrandingSelector, (state) => state.bySellerId);

const loadedCatalogsSelector = createSelector(consoleBrandingSelector, (state) => state.loadedCatalogs);
const loadedSellersSelector = createSelector(consoleBrandingSelector, (state) => state.loadedSellers);

const loadingCatalogsSelector = createSelector(consoleBrandingSelector, (state) => state.loadingCatalogs);
const loadingSellersSelector = createSelector(consoleBrandingSelector, (state) => state.loadingSellers);

const getConsoleBrandingCatalog = createSelector(
    [byCatalogIdSelector, catalogIdSelector],
    (byId, catalogId) => byId[catalogId] || { color: '', logoUrl: '' }
);

const getConsoleBrandingSeller = createSelector(
    [bySellerIdSelector, sellerIdSelector],
    (byId, sellerId) => byId[sellerId] || { color: '', logoUrl: '' }
);

export const getConsoleBranding: (arg0: any, arg1: number, arg2: number) => any = createSelector(
    [getConsoleBrandingCatalog, getConsoleBrandingSeller],
    (catalog, seller) => {
        if (catalog.color !== '' || catalog.logoUrl !== '') {
            return catalog;
        } else if (seller.color !== '' || seller.logoUrl !== '') {
            return seller;
        } else {
            return {
                color: '',
                logoUrl: '',
            };
        }
    }
);

const getLoadTimeForConsoleBrandingCatalog = createSelector(
    [loadedCatalogsSelector, catalogIdSelector],
    (loaded, id) => loaded[id] || 0
);
const getLoadTimeForConsoleBrandingSeller = createSelector(
    [loadedSellersSelector, sellerIdSelector],
    (loaded, id) => loaded[id] || 0
);

const isConsoleBrandingCatalogLoading = createSelector([loadingCatalogsSelector, catalogIdSelector], (loading, id) =>
    loading.includes(id)
);
const isConsoleBrandingSellerLoading = createSelector([loadingSellersSelector, sellerIdSelector], (loading, id) =>
    loading.includes(id)
);

const shouldFetchConsoleBrandingCatalog = (state, catalogId) => {
    if (!catalogId) {
        return false;
    }
    const loaded = getLoadTimeForConsoleBrandingCatalog(state, catalogId);
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }

    const loading = isConsoleBrandingCatalogLoading(state, catalogId);
    return !loading;
};

const shouldFetchConsoleBrandingSeller = (state, catalogId, sellerId) => {
    if (!sellerId) {
        return false;
    }
    const loaded = getLoadTimeForConsoleBrandingSeller(state, catalogId, sellerId);
    const time = Date.now();
    const diff = time - loaded;
    if (diff < REDUX_STORE_TIME) {
        return false;
    }

    const loading = isConsoleBrandingSellerLoading(state, catalogId, sellerId);
    return !loading;
};

const shouldFetchConsoleBranding = (state, catalogId, sellerId) => {
    return (
        shouldFetchConsoleBrandingCatalog(state, catalogId) ||
        shouldFetchConsoleBrandingSeller(state, catalogId, sellerId)
    );
};

/* ACTION CREATORS */
const loadConsoleBranding = (catalogId, sellerId) => async (dispatch, getState) => {
    try {
        const state = getState();
        const deployment = getDeployment(state);
        dispatch({
            payload: { catalogId, sellerId },
            type: LOAD_CONSOLE_BRANDING_REQUEST,
        });
        const response = await api.fetchConsoleBranding({
            catalogId,
            deployment,
            sellerId,
        });
        return dispatch({
            meta: { actionTime: Date.now(), catalogId, sellerId },
            payload: response.data,
            type: LOAD_CONSOLE_BRANDING_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { catalogId, sellerId },
            payload: error,
            type: LOAD_CONSOLE_BRANDING_FAIL,
        });
    }
};

export const fetchConsoleBrandingIfNeeded =
    (catalogId: number, sellerId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const needed = shouldFetchConsoleBranding(getState(), catalogId, sellerId);
        if (needed) {
            return dispatch(loadConsoleBranding(catalogId, sellerId));
        }
        return Promise.resolve();
    };
