import { createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    deleteArchiveConversationFolders,
    fetchConversationFolders,
    FetchConversationFoldersResponse,
    patchRenameConversationFolders,
    postNewConversationFolders,
} from '@/redux/modules/conversation/folders/conversationFolders.api';
import { Folder } from '@/types/Messaging';
import { getAuthToken, getBidderId, isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { shouldFetchConversationFolders } from '@/redux/modules/conversation/folders/conversationFolders.selectors';

type LoadFoldersPayload = {
    bidderId?: number;
    token?: string;
};

export const loadConversationFolders = createAsyncThunk<
    FetchConversationFoldersResponse['payload'],
    LoadFoldersPayload
>('la/ui/conversationFolders/loadConversationFolders', async ({ bidderId, token }, { getState }) => {
    const state = getState();

    const response = await fetchConversationFolders({
        authToken: token || getAuthToken(state),
        bidderId: bidderId || getBidderId(state),
        deployment: getDeployment(state),
    });
    return response.payload;
});

export const fetchConversationFoldersIfNeeded = createAsyncThunk<void, LoadFoldersPayload>(
    'la/ui/conversationFolders/fetchConversationFoldersIfNeeded',
    async (args, { dispatch, getState }) => {
        const state = getState();
        const loggedIn = isUserLoggedIn(state);
        if (shouldFetchConversationFolders(state) && loggedIn) {
            await dispatch(loadConversationFolders(args));
        }
    }
);

export const createConversationFolder = createAsyncThunk<Folder, string>(
    'la/ui/conversationFolders/createConversationFolder',
    async (folderName, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        const response = await postNewConversationFolders({
            authToken,
            bidderId,
            deployment,
            name: folderName,
        });
        return response.payload;
    }
);

type RenameFolderPayload = {
    folderId: number;
    name: string;
};

export const renameConversationFolder = createAsyncThunk<Folder, RenameFolderPayload>(
    'la/ui/conversationFolders/renameConversationFolder',
    async ({ folderId, name }, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        const response = await patchRenameConversationFolders({
            authToken,
            bidderId,
            deployment,
            folderId,
            name,
        });

        await dispatch(loadConversationFolders({ bidderId, token: authToken }));

        return response.payload;
    }
);

export const archiveConversationFolder = createAsyncThunk<string, number>(
    'la/ui/conversationFolders/archiveConversationFolder',
    async (folderId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const response = await deleteArchiveConversationFolders({
            authToken,
            bidderId,
            deployment,
            folderId,
        });
        return response.payload;
    }
);
