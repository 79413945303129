import { createSlice } from '@reduxjs/toolkit';
import { defaultConsentSlice } from '@/redux/modules/consent/consent.types';
import { submitConsent } from '@/redux/modules/consent/consent.actions';

const consentSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitConsent.pending, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitConsent.fulfilled, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitConsent.rejected, (slice, { error }) => {
            slice.error = true;
            slice.errorMessage = error.message;
            slice.submitted = false;
            slice.success = false;
        });
    },
    initialState: defaultConsentSlice,
    name: 'consent',
    reducers: {},
});

export const { reducer: consentReducer } = consentSlice;
