import { emptyCheckoutShippingQuote, ShippingQuote } from '@/types/ShippingQuote';

export type CheckoutShippingQuoteSlice = {
    loaded: { [id: number]: number };
    loading: number[];
    quoteError: number;
    shippingQuote: ShippingQuote;
};

export const defaultCheckoutShippingQuoteSlice: CheckoutShippingQuoteSlice = {
    loaded: {},
    loading: [],
    quoteError: 0,
    shippingQuote: emptyCheckoutShippingQuote,
};

export type SubmitCheckoutShippingQuoteInput = {
    amount: number;
    bidderId: number;
    invoiceId: number;
    usingLaShipping: boolean;
};
