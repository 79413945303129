import { ApiHosts, handleResponseOld, makePost } from '@/redux/api/helpers';
import { BidderProfileDataResponse } from '@/redux/modules/account/user/user.types';

/**
 * @category Item API
 * @see postConfirmPassword
 */
type PostConfirmPasswordParams = {
    deployment: string;
    password: string;
    username: string;
};

/**
 * postConfirmPassword - login process, confirms password and returns auth token for login
 * @category Item API
 * @function postConfirmPassword
 * @param {PostConfirmPasswordParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/auth/spalogin
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-login.ts
 */
export const postConfirmPassword = ({ deployment, password, username }: PostConfirmPasswordParams) =>
    new Promise<BidderProfileDataResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'auth/spalogin',
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.send({ password, username });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
