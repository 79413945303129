import { FetchItemSummariesByCatalogIdQueryType } from '@/redux/modules/item/summary/itemSummary.api';
import { PaginationFilter } from '@liveauctioneers/caterwaul-components/types/PaginationFilter';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';

export type CatalogItemsSlice = {
    byId: { [catalogId: number]: number[] };
    loaded: number;
    loading: boolean;
    pagination: PaginationFilter &
        FetchItemSummariesByCatalogIdQueryType & {
            results: number;
            totalRecords: number;
        };
};

export const defaultCatalogItemsSlice: CatalogItemsSlice = {
    byId: {},
    loaded: 0,
    loading: false,
    pagination: {
        keyword: '',
        page: 1,
        pageSize: 24,
        results: 0,
        sort: SearchSortAndDirection.LotNumberLowest,
        totalRecords: 0,
    },
};

export type FetchCatalogItemsParams = {
    catalogId: number;
    query: FetchItemSummariesByCatalogIdQueryType;
};
