import { changeUserPassword } from './changePassword.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultChangePasswordSlice } from './changePassword.types';

const changePasswordSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(changeUserPassword.pending, (slice) => {
            slice.error = false;
            slice.errorMessage = '';
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(changeUserPassword.fulfilled, (slice) => {
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(changeUserPassword.rejected, (slice, { error }) => {
            slice.error = true;
            slice.errorMessage = error.message;
            slice.submitted = false;
        });
    },
    initialState: defaultChangePasswordSlice,
    name: 'changePassword',
    reducers: {},
});

export const { reducer: changePasswordReducer } = changePasswordSlice;
