import { AcceptedTermsOfService } from '@/redux/modules/account/user/user.types';
import { ApiHosts, handleResponseOld, makePost } from '@/redux/api/helpers';

/**
 * @category User API
 * @see postBidderConsent
 */
type PostBidderConsentResponse = {
    error: boolean;
    payload: AcceptedTermsOfService;
};

/**
 * @category User API
 * @see postBidderConsent
 */
type PostBidderConsentParams = {
    authToken: string;
    consent: any;
    context: any;
    deployment: string;
};

/**
 * POST request to user api that
 * @function postBidderConsent
 * @category User API
 * @param {PostBidderConsentParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/accepttermsofservice
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/yzjvui/methods/POST
 * @see https://github.com/LIVEauctioneers/Flynn/tree/master/functions/FlynnAcceptTermsOfServiceV2
 */
export const postBidderConsent = ({ authToken, consent, context, deployment }: PostBidderConsentParams) =>
    new Promise<PostBidderConsentResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'accepttermsofservice',
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({
            acceptedTermsOfService: consent,
            context,
            signUpClient: 'web',
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
