import { acceptedMessageBuilder } from '@/utils/acceptedMessageBuilder';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import {
    LIVE_ASK_CHANGED,
    LIVE_AUCTION_PAUSED,
    LIVE_AUCTION_RESUMED,
    LIVE_BID_ACCEPTED,
    LIVE_BID_RETRACTED,
    LIVE_LOT_PASSED,
    LIVE_LOT_REOPENED,
    LIVE_LOT_SKIPPED,
    LIVE_LOT_SOLD,
    LIVE_LOT_UNSOLD,
    LIVE_MISSIVE,
    LIVE_NEXT_LOT_LOADED,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
} from './actions';
import { LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS } from '@/redux/modules/actions/registerForCatalog';

/* helpers */
const addMessage = (messages, newMessages) => {
    messages = [...messages];
    if (Array.isArray(newMessages)) {
        newMessages.forEach((message) => doAdMessage(messages, message));
    } else {
        doAdMessage(messages, newMessages);
    }

    if (messages.length > 80) {
        messages = messages.splice(messages.length - 80, 80);
    }
    return messages;
};

const doAdMessage = (messages, message) => {
    // Don't add old messages to list
    if (message.created < messages[messages.length - 1]?.created) {
        return;
    } else {
        messages.push(message);
    }
};

/* reducer */
export type ConsoleMessagesSlice = { byId: any };

export const defaultConsoleMessagesSlice: ConsoleMessagesSlice = {
    byId: {},
};

export default function reducer(
    state: ConsoleMessagesSlice = defaultConsoleMessagesSlice,
    action: any = {}
): ConsoleMessagesSlice {
    const payload = action.payload || {};
    const {
        amount,
        approved,
        ask,
        askPrice,
        bidderId,
        bidderOutbid,
        catalogId,
        created: clientReceivedTime,
        currency,
        limit,
        lotNumber,
        missiveText,
        myBid,
        paddleNumber,
        previousBidAmount,
        serverCreatedTime,
        source,
        wasApproved,
    } = payload;

    /**
     * the "created" field describes when a live message was recieved by the client and NOT when it was sent by the server
     * if there is a "server sent" time, use that to sort the messages instead of "client received"
     *
     * this difference becomes a problem when pubnub is delivering old messages without warning or reason and makes the console messages
     * confusing to the user, such as "Bid Accepted: $9000" when that message is for an item that was sold 20 mins ago
     */
    const created: number = serverCreatedTime ? Date.parse(serverCreatedTime) : clientReceivedTime;

    let existing = state.byId[catalogId] || [];

    switch (action.type) {
        case LIVE_AUCTION_PAUSED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            serverCreatedTime,
                        },
                        type: 'paused',
                    }),
                },
            };
        case LIVE_AUCTION_RESUMED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            serverCreatedTime,
                        },
                        type: 'resumed',
                    }),
                },
            };
        case LIVE_BID_ACCEPTED:
            const newMessages = acceptedMessageBuilder({
                amount,
                bidderId,
                created,
                currency,
                myBid,
                outbid: bidderOutbid,
                paddleNumber,
                previousBidAmount,
                source,
            });
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, newMessages),
                },
            };
        case LIVE_BID_RETRACTED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount: ask,
                            currency,
                            myBid,
                        },
                        type: 'retracted',
                    }),
                },
            };
        case LIVE_LOT_PASSED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { lotNumber },
                        type: 'passed',
                    }),
                },
            };
        case LIVE_LOT_REOPENED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { lotNumber },
                        type: 'reopened',
                    }),
                },
            };
        case LIVE_ASK_CHANGED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount: ask,
                            currency,
                            myBid,
                        },
                        type: 'askChanged',
                    }),
                },
            };
        case LIVE_LOT_SKIPPED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { lotNumber },
                        type: 'skipped',
                    }),
                },
            };
        case LIVE_LOT_SOLD:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount,
                            bidderId,
                            currency,
                            lotNumber,
                            myBid,
                            paddleNumber,
                        },
                        type: 'sold',
                    }),
                },
            };
        case LIVE_LOT_UNSOLD:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: { lotNumber },
                        type: 'unsold',
                    }),
                },
            };
        case LIVE_MISSIVE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        text: missiveText,
                        type: 'missive',
                    }),
                },
            };
        case LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS:
            if (!wasApproved && approved) {
                existing = addMessage(existing, {
                    created,
                    data: { limit },
                    type: 'approved',
                });
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: existing,
                },
            };
        case LOAD_LIVE_CATALOG_STATUS_SUCCESS:
            if (action.payload.status === 'paused' && existing.length === 0) {
                existing = addMessage(existing, {
                    created,
                    data: {},
                    type: 'paused',
                });
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: existing,
                },
            };
        case LIVE_NEXT_LOT_LOADED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [catalogId]: addMessage(existing, {
                        created,
                        data: {
                            amount: askPrice,
                            currency,
                            lotNumber,
                        },
                        type: 'opened',
                    }),
                },
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const consoleMessagesSelector = createSelector(stateSelector, (state) => state.consoleMessages);

const idSelector = (_: GlobalState, id: number) => id;
const smallConsoleSelector = (_: GlobalState, id: number, smallConsole: boolean) => smallConsole;

const byIdSelector = createSelector(consoleMessagesSelector, (state) => state.byId);

export const getMessages = createSelector(
    [byIdSelector, idSelector, smallConsoleSelector],
    (byId, id, smallConsole) => {
        const messages = byId[id] || [];

        if (smallConsole) {
            return messages.filter(
                (x) =>
                    x.type === 'accepted' ||
                    x.type === 'ended' ||
                    x.type === 'opened' ||
                    x.type === 'passed' ||
                    x.type === 'paused' ||
                    x.type === 'skipped' ||
                    x.type === 'sold' ||
                    x.type === 'unsold'
            );
        }
        return messages;
    }
);
