import { CONVERSATION_FOLDERS_SLICE_CACHE_TIME } from '@/redux/modules/conversation/folders/conversationFolders.types';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const conversationFoldersSlice = createSelector(stateSelector, (state) => state.conversationFolders);

const loadedSelector = createSelector(conversationFoldersSlice, (state) => state.loaded);

const loadingSelector = createSelector(conversationFoldersSlice, (state) => state.loading);

export const getConversationFolders = createSelector(
    conversationFoldersSlice,
    (state) => state.folders || { systemFolders: [], userFolders: [] }
);

export const getUnreadInboxCount = createSelector(getConversationFolders, (state) => {
    const firstTotal = state?.systemFolders?.reduce((total, folder) => total + folder.unReadConversationCount, 0) || 0;
    const secondTotal = state?.userFolders?.reduce((total, folder) => total + folder.unReadConversationCount, 0) || 0;
    return firstTotal + secondTotal;
});

export const shouldFetchConversationFolders = createSelector([loadedSelector, loadingSelector], (loaded, loading) => {
    const time = Date.now();
    const diff = time - loaded;
    if (diff < CONVERSATION_FOLDERS_SLICE_CACHE_TIME) {
        return false;
    }
    return !loading;
});
