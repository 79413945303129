import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { BidderAddress } from '@/types/BidderAddresses';
import { COMPLETE_ACCOUNT_FAIL, COMPLETE_ACCOUNT_REQUEST, COMPLETE_ACCOUNT_SUCCESS, DISMISS_MODAL } from './actions';
import { createSelector } from '@reduxjs/toolkit';
import { get2DigitCountryCode } from '@/utils/address';
import { getAuthToken, getBidderEmail, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getCompleteAccountAnalytics } from './analytics';
import { getDeployment } from './config';
import {
    postAddBidderAddress,
    postUpdateProfile,
    PostUpdateProfileResponse,
    UpdateProfileWithAddressRequest,
} from '@/redux/modules/account/updateAccount/updateAccount.api';
import { TypedActionWithPayload } from '@/types/redux';

// reducer
export type CompleteAccountSlice = {
    error: boolean;
    submitted: boolean;
    success: boolean;
};

export const defaultCompleteAccountSlice: CompleteAccountSlice = {
    error: false,
    submitted: false,
    success: false,
};

export default function reducer(
    state: CompleteAccountSlice = defaultCompleteAccountSlice,
    action: any = {}
): CompleteAccountSlice {
    switch (action.type) {
        case DISMISS_MODAL:
            return {
                ...state,
                error: false,
                submitted: false,
                success: false,
            };
        case COMPLETE_ACCOUNT_FAIL:
            return {
                ...state,
                error: true,
                submitted: false,
                success: false,
            };
        case COMPLETE_ACCOUNT_REQUEST:
            return {
                ...state,
                submitted: true,
                success: false,
            };
        case COMPLETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                submitted: false,
                success: true,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;

export const getUiCompleteAccount = createSelector(stateSelector, (state) => state.completeAccount);

/* ACTION CREATORS */
export type CompleteAccountSuccessAction = TypedActionWithPayload<
    typeof COMPLETE_ACCOUNT_SUCCESS,
    PostUpdateProfileResponse['payload'],
    ReturnType<typeof getCompleteAccountAnalytics> & { actionTime: number }
>;

export const submitCompleteAccount =
    (updatedProfile: UpdateProfileWithAddressRequest) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            // email is not set in the complete account form, it comes from the token at this point
            updatedProfile.email = getBidderEmail(state);

            dispatch({
                type: COMPLETE_ACCOUNT_REQUEST,
            });

            const bidderID = getBidderId(state);
            const address: BidderAddress = {
                address1: updatedProfile.address1,
                address2: updatedProfile.address2,
                address3: '',
                addressId: 0,
                avalaraVerified: false,
                bidderId: bidderID,
                city: updatedProfile.city,
                countryCode: get2DigitCountryCode(updatedProfile.country),
                enabled: true,
                firstName: updatedProfile.firstName,
                isDefault: true,
                lastName: updatedProfile.lastName,
                organizationName: updatedProfile.organizationName,
                phoneNumber: updatedProfile.phoneNumber ? updatedProfile.phoneNumber : updatedProfile.mobile,
                postalCode: updatedProfile.zipcode,
                shippingOptionType: '',
                state: updatedProfile.state,
                verifiedStatus: 0,
            };

            await postAddBidderAddress({
                address,
                authToken,
                bidderID,
                deployment,
            });

            const response = await postUpdateProfile({
                authToken,
                deployment,
                profile: updatedProfile,
            });

            const successAction: CompleteAccountSuccessAction = {
                error: false,
                meta: {
                    ...getCompleteAccountAnalytics(),
                    actionTime: Date.now(),
                },
                payload: response.payload,
                type: COMPLETE_ACCOUNT_SUCCESS,
            };

            dispatch(successAction);
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: COMPLETE_ACCOUNT_FAIL,
            });
        }
    };
