import { AppDispatch, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { DAYS_TO_WAIT_TO_SHOW_MODAL_AGAIN } from '@/redux/modules/autoPopModal/autoPopModal.selectors';
import { formatHyphenatedDateOnly } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import Cookies from 'universal-cookie';
const Cookie = new Cookies();

export type CookieState = { [id: string]: string };

const DEFAULT_ITEMS_TO_SHOW = 24;

/* reducer */
export const DEFAULT_STATE: CookieState = {};
export default function reducer(state: CookieState = DEFAULT_STATE, action: any = {}): CookieState {
    const newState = { ...state };
    switch (action.type) {
        case 'add_cookie':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case 'remove_cookie':
            delete newState[action.payload.name];
            return newState;
        case 'LOG_OUT_BIDDER':
            delete newState[BIDDER_AUTH_COOKIE];
            return newState;
        default:
            return state;
    }
}

const stateSelector = (state: GlobalState) => state;
const idSelector = (_: GlobalState, id: string) => id;
export const cookiesSelector = createSelector(stateSelector, (state) => state.cookies);

export const getCookie = createSelector([cookiesSelector, idSelector], (cookies, id) => cookies[id] || '');

const PREFERRED_CURRENCY_SET_COOKIE = 'preferred-currency-set';
export const getPreferredCurrencyCookie = createSelector(
    cookiesSelector,
    (cookies) => cookies[PREFERRED_CURRENCY_SET_COOKIE] || ''
);

export const setPreferredCurrencyCookie = () => (dispatch: AppDispatch) =>
    dispatch(
        setCookie({
            maxAge: 86400 * 365,
            name: PREFERRED_CURRENCY_SET_COOKIE,
            value: true,
        })
    );

const GET_MOBILE_APP_BANNER_ALT_COOKIE = 'dismissableMobileBannerAlt';
export const getMobileAppBannerAltCookie = createSelector(
    cookiesSelector,
    (cookies) => cookies[GET_MOBILE_APP_BANNER_ALT_COOKIE] || ''
);

export const setMobileAppBannerAltCookie = () => (dispatch: AppDispatch) =>
    dispatch(
        setCookie({
            maxAge: 86400 * 30,
            name: GET_MOBILE_APP_BANNER_ALT_COOKIE,
            value: 'dismissed',
        })
    );

const GET_MOBILE_APP_BANNER_COOKIE = 'dismissableMobileBanner';
export const getMobileAppBannerCookie = createSelector(
    cookiesSelector,
    (cookies) => cookies[GET_MOBILE_APP_BANNER_COOKIE] || ''
);

export const setMobileAppBannerCookie = () => (dispatch: AppDispatch) =>
    dispatch(
        setCookie({
            maxAge: 86400 * 30,
            name: GET_MOBILE_APP_BANNER_COOKIE,
            value: 'dismissed',
        })
    );

const BIDDER_AUTH_COOKIE = 'bidder-auth';
export const setAuthCookie = (expires: Date, token: string) => (dispatch: AppDispatch) =>
    dispatch(setCookie({ expires, name: BIDDER_AUTH_COOKIE, value: token }));

export const getAuthCookie = createSelector(cookiesSelector, (cookies) => cookies[BIDDER_AUTH_COOKIE] || '');

export const removeAuthCookie = () => (dispatch: AppDispatch) => dispatch(removeCookie({ name: BIDDER_AUTH_COOKIE }));

const JOIN_MODAL_LAST_SEEN_COOKIE = 'join-modal-last-seen';
export const setJoinModalLastSeenCookie = () => (dispatch: AppDispatch) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + DAYS_TO_WAIT_TO_SHOW_MODAL_AGAIN);
    return dispatch(
        setCookie({
            expires,
            name: JOIN_MODAL_LAST_SEEN_COOKIE,
            value: formatHyphenatedDateOnly(expires),
        })
    );
};

export const getJoinModalLastSeenCookie = createSelector(
    cookiesSelector,
    (cookies) => cookies[JOIN_MODAL_LAST_SEEN_COOKIE] || ''
);

const BIDDER_HAS_LOGGED_IN_COOKIE = 'bidder-has-logged-in';
export const setBidderHasLoggedInCookie = () => (dispatch: AppDispatch) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 60);
    return dispatch(setCookie({ expires, name: BIDDER_HAS_LOGGED_IN_COOKIE, value: true }));
};

export const getBidderHasLoggedInCookie = createSelector(
    cookiesSelector,
    (cookies) => Boolean(cookies[BIDDER_HAS_LOGGED_IN_COOKIE]) || false
);

const PAGINATION_PAGE_SIZE_COOKIE = 'pagination-page-size';
export const setPaginationPageSizeCookie = (value: number | 'all') => (dispatch: AppDispatch) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 60);
    return dispatch(setCookie({ expires, name: PAGINATION_PAGE_SIZE_COOKIE, value }));
};

export const getPaginationPageSizeCookie = createSelector(cookiesSelector, (cookies) =>
    cookies[PAGINATION_PAGE_SIZE_COOKIE] === 'all'
        ? 'all'
        : Number(cookies[PAGINATION_PAGE_SIZE_COOKIE]) || DEFAULT_ITEMS_TO_SHOW
);

export const INVOICE_REMINDER_COOKIE = 'invoice-reminder';
export const removeInvoiceReminderCookie = () => (dispatch: AppDispatch) =>
    dispatch(removeCookie({ name: INVOICE_REMINDER_COOKIE }));
export const setInvoiceReminderCookie = () => (dispatch: AppDispatch) => {
    const created = Date.now() / 1000; // seconds since epoch
    return dispatch(setCookie({ name: INVOICE_REMINDER_COOKIE, value: created }));
};

export type CookieProp = {
    expires?: Date;
    maxAge?: number;
    name: string;
    value?: string | boolean | number;
};

const domain = '.liveauctioneers.com';
const path = '/';
const sameSite = 'none';
const secure = true;

export const setCookie =
    ({ expires, maxAge, name, value }: CookieProp) =>
    async (dispatch: AppDispatch) => {
        Cookie.set(name, value, {
            domain,
            ...(Boolean(expires) && { expires }),
            ...(Boolean(maxAge) && { maxAge }),
            path,
            sameSite,
            secure,
        });

        dispatch({
            payload: {
                name,
                value,
            },
            type: 'add_cookie',
        });
    };

export const removeCookie =
    ({ name }: CookieProp) =>
    async (dispatch: AppDispatch) => {
        Cookie.remove(name, { domain, path });

        dispatch({
            payload: {
                name,
            },
            type: 'remove_cookie',
        });
    };
