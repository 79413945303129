import {
    archiveConversation,
    markConversationRead,
    markConversationUnread,
    moveConversation,
} from '@/redux/modules/conversation/conversations/conversation.api';
import { ConversationQueryType } from '@/redux/modules/conversation/conversations/conversation.types';

export const conversationPageActions = {
    archive: archiveConversation,
    markRead: markConversationRead,
    markUnread: markConversationUnread,
    move: moveConversation,
};

export type LoadConversationPageDataPayload = {
    action: keyof typeof conversationPageActions;
    conversationIds: number[];
    folderId?: number;
    markOnly?: boolean;
    query: ConversationQueryType;
};
