import { Attachment } from '@/types/Messaging';
import { ConversationMessage } from '@/redux/modules/conversation/conversation.types';

export type MessageAttachmentDraft = {
    id: number;
    name: string;
};

export type MessageDraft = {
    draftAttachments: MessageAttachmentDraft[];
    draftId: number;
};

export type ConversationMessagesSlice = {
    byId: { [id: number]: ConversationMessage[] };
    loaded: { [id: number]: number };
    loading: number[];
    messageDrafts: { [id: number]: MessageDraft };
};

export const defaultConversationMessagesSlice: ConversationMessagesSlice = {
    byId: {},
    loaded: {},
    loading: [],
    messageDrafts: {},
};

export type ConversationMessageResponsePayload = {
    bidderId: number;
    bidderIds: number[];
    catalogId: number;
    conversationId: number;
    error: string;
    houseId: number;
    houseIds: number[];
    lotId: number;
    messageBody: string;
    messageId: number;
    sender: {
        id: number;
        initials?: string;
        name: string;
        type: string;
    };
    subject: string;
    tsSent: number;
};

export type SendMessagePayload = {
    conversationId: number;
    draftId: number;
    messageBody: any;
};

export type FilterAttachedImagesPayload = {
    conversationId: number;
    newAttachments: any[];
};

export type FetchAttachmentsResponsePayload = {
    attachments: Attachment[];
    bidderId: number;
    catalogId: number;
    conversationId: number;
    createdDateTime: string;
    draftId: number;
    houseId: number;
    lotId: number;
    messageBody: string;
    multiDraftErrors: string | null;
    recipient: 'bidder' | 'house';
    subject: string;
};

export type AddAttachmentsPayload = {
    attachments: MessageAttachmentDraft[];
    conversationId: number;
    draftId: number;
    messageBody: string;
    sellerId?: number;
};

export type DeleteAttachmentPayload = {
    attachmentName: string;
    conversationId: number;
};
