import { CatalogDetail } from '@/types/CatalogDetail';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchCatalogDetailsByIds } from '@/redux/modules/catalog/detail/catalogDetail.api';
import {
    getCatalogDetail,
    getLoadTimeForCatalogDetail,
    isCatalogDetailLoading,
} from '@/redux/modules/catalog/detail/catalogDetail.selectors';
import { getDeployment } from '@/redux/modules/config';
import { GlobalState } from '@/redux/store';
import ms from 'ms';

const REDUX_STORE_TIME = ms('30m');

const shouldFetchCatalogDetail = (state: GlobalState, catalogId: number) => {
    if (!catalogId) {
        return false;
    }
    const catalogDetail = getCatalogDetail(state, catalogId);
    if (catalogDetail && Object.keys(catalogDetail).length > 0) {
        const loaded = getLoadTimeForCatalogDetail(state, catalogId);
        const time = Date.now();
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isCatalogDetailLoading(state, catalogId);
    return !loading;
};

const whichCatalogDetailsNeeded = (state: GlobalState, catalogIds: number[]) =>
    catalogIds.filter((catalogId) => shouldFetchCatalogDetail(state, catalogId));

type LoadCatalogDetailsResponse = {
    actionTime: number;
    data: CatalogDetail[];
};

export const loadCatalogDetails = createAsyncThunk<LoadCatalogDetailsResponse, number[]>(
    'la/domain/catalogDetail/loadCatalogDetails',
    async (catalogIds, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const response = await fetchCatalogDetailsByIds({
            catalogIds,
            deployment,
        });

        return {
            actionTime: Date.now(),
            data: response?.data?.catalogDetails || [],
        };
    }
);

export const fetchCatalogDetailsIfNeeded = createAsyncThunk<void, number[]>(
    'la/domain/catalogDetail/fetchCatalogDetailsIfNeeded',
    async (catalogIds, { dispatch, getState }) => {
        const needed = whichCatalogDetailsNeeded(getState(), catalogIds);
        if (needed.length) {
            await dispatch(loadCatalogDetails(needed));
        }
        return Promise.resolve();
    }
);
