import { AuctionStatus } from '@/types/LiveAuctionState';
import { CatalogSlice, defaultCatalogState, FetchCatalogsResponsePayload } from './catalog.types';
import { createSlice } from '@reduxjs/toolkit';
import { EmptyCatalog } from '@/types/Catalog';
import { EmptySeller } from '@/types/Seller';
import { fetchCatalogItemSummaries } from '@/redux/modules/catalog/items/catalogItems.actions';
import { FetchLiveAuctionStatusResponseData } from '@/redux/api/liveCatalogStatus';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { getAddressForCatalog } from '@/utils/address';
import {
    LIVE_AUCTION_ENDED,
    LIVE_AUCTION_ENDED_ACTION,
    LIVE_AUCTION_OPENED,
    LIVE_AUCTION_OPENED_ACTION,
    LIVE_AUCTION_STARTED,
    LIVE_AUCTION_STARTED_ACTION,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION,
    LOAD_UPCOMING_CATALOGS_SUCCESS,
} from '@/redux/modules/actions';
import { LOAD_SELLER_ENDED_CATALOGS_SUCCESS } from '@/redux/modules/actions/sellerEndedCatalogs';
import { LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS } from '@/redux/modules/actions/sellerUpcomingCatalogs';
import { loadCatalogs } from './catalog.actions';
import { loadHomeData } from '@/redux/modules/home/home.actions';
import { PaymentProviders } from '@/enums';
import { safelyEscapeText } from '@/utils/safelyRender';
import { TypedActionWithPayload } from '@/types/redux';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import union from 'lodash/union';

type OnLoadFulfilledAction = FulfilledActionFromAsyncThunk<typeof loadHomeData>;

const updateCatalogsOnLoad = (slice: CatalogSlice, { payload }: OnLoadFulfilledAction) => {
    payload?.catalogs?.forEach((catalog) => {
        let paymentProvider = PaymentProviders.Unknown;
        if (catalog?.payrixPayments) {
            paymentProvider = PaymentProviders.Payrix;
        } else if (catalog?.highRiskPayments) {
            paymentProvider = PaymentProviders.Paysafe;
        }

        const seller = payload.sellers?.find((s) => s.sellerId === catalog.sellerId) || EmptySeller;
        let address = catalog.address;
        let sellerName: string;
        if (seller.sellerId) {
            address = getAddressForCatalog(address, seller);
            sellerName = safelyEscapeText(seller.name);
        }

        slice.byId[catalog.catalogId] = {
            ...catalog,
            address,
            paymentProvider,
            sellerName,
            title: safelyEscapeText(catalog.title),
        };

        const coverLots = payload?.coverLots?.find((coverLot) => coverLot.catalogId === catalog.catalogId)?.coverLots;
        slice.byId[catalog.catalogId].coverLots = coverLots || [];

        delete slice.byId[catalog.catalogId].payrixPayments;
        slice.loaded[catalog.catalogId] = Date.now();
        slice.loading = difference(slice.loading, [catalog.catalogId]);
    });
    return slice;
};

// As we replace more areas with redux toolkit thunks and actions and whatnot,
// the usage of these functions in the extraReducers should eventually be reduced to 0.
// At that time, these functions and types should be deleted
// region Old Redux Functions
type OldSuccessTypes =
    | typeof LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS
    | typeof LOAD_SELLER_ENDED_CATALOGS_SUCCESS
    | typeof LOAD_UPCOMING_CATALOGS_SUCCESS;

export type OldSuccessAction = TypedActionWithPayload<
    OldSuccessTypes,
    FetchCatalogsResponsePayload,
    { actionTime: number }
>;

const updateCatalogsOnOldSuccessAction = (state: CatalogSlice, action: OldSuccessAction): CatalogSlice => {
    const existing = cloneDeep(state.byId);
    const loaded = cloneDeep(state.loaded);
    let loading = state.loading.slice();
    const { actionTime } = action.meta;

    const { payload } = action;
    payload?.catalogs?.forEach((catalog) => {
        let paymentProvider = PaymentProviders.Unknown;
        if (catalog?.payrixPayments) {
            paymentProvider = PaymentProviders.Payrix;
        } else if (catalog?.highRiskPayments) {
            paymentProvider = PaymentProviders.Paysafe;
        }

        const seller = payload.sellers?.find((s) => s.sellerId === catalog.sellerId) || EmptySeller;
        let address = catalog.address;
        let sellerName: string;
        if (seller.sellerId) {
            address = getAddressForCatalog(address, seller);
            sellerName = safelyEscapeText(seller.name);
        }

        existing[catalog.catalogId] = {
            ...catalog,
            address,
            paymentProvider,
            sellerName,
            title: safelyEscapeText(catalog.title),
        };

        const coverLots = action.payload?.coverLots?.find(
            (coverLot) => coverLot.catalogId === catalog.catalogId
        )?.coverLots;
        existing[catalog.catalogId].coverLots = coverLots || [];

        delete existing[catalog.catalogId].payrixPayments;
        loaded[catalog.catalogId] = actionTime;
        loading = difference(loading, [catalog.catalogId]);
    });

    return {
        ...state,
        byId: existing,
        loaded,
        loading,
    };
};

const updateCatalogsOnOldLiveAuctionEndedAction = (
    state: CatalogSlice,
    action: LIVE_AUCTION_ENDED_ACTION
): CatalogSlice => ({
    ...state,
    byId: {
        ...state.byId,
        [action.payload.catalogId]: {
            ...state.byId[action.payload.catalogId],
            catalogStatus: AuctionStatus.Done,
        },
    },
});

const updateCatalogsOnOldLoadLiveCatalogStatusSuccessAction = (
    state: CatalogSlice,
    action: LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION
): CatalogSlice => {
    const { catalogId, currentItem, status } = action.payload as FetchLiveAuctionStatusResponseData;
    const existing = cloneDeep(state.byId);
    if (catalogId) {
        existing[catalogId] = existing[catalogId] || EmptyCatalog;

        if (status === AuctionStatus.Done && existing[catalogId].catalogStatus !== AuctionStatus.Done) {
            existing[catalogId].catalogStatus = AuctionStatus.Done;
        } else if (status === 'live' && existing[catalogId].catalogStatus !== AuctionStatus.Done) {
            existing[catalogId].catalogStatus = AuctionStatus.Live;
        }

        const lotsClosed = currentItem.lotsClosed;
        if (lotsClosed !== undefined && lotsClosed !== null) {
            existing[catalogId].closedLotsCount = lotsClosed;
        }
    }
    return {
        ...state,
        byId: existing,
    };
};

const updateCatalogsOnOldLiveAuctionOpenedOrStarted = (
    state: CatalogSlice,
    action: LIVE_AUCTION_OPENED_ACTION | LIVE_AUCTION_STARTED_ACTION
): CatalogSlice => {
    const { catalogId } = action.payload;
    const existing = cloneDeep(state.byId);
    existing[catalogId] = existing[catalogId] || cloneDeep(EmptyCatalog);
    existing[catalogId].catalogStatus = AuctionStatus.Live;
    return {
        ...state,
        byId: existing,
    };
};

const timedPlusLotEndReducer = (state: CatalogSlice, catalogId: number, closedLotsCount: number): CatalogSlice => {
    if (Boolean(state.byId[catalogId])) {
        state.byId[catalogId].closedLotsCount = closedLotsCount;
    }
    return state;
};
// endregion

const catalogSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCatalogs.pending, (state, { meta }) => {
            state.loading = union(state.loading, meta.arg);
        });
        builder.addCase(loadCatalogs.fulfilled, (state, { payload }) => {
            const { actionTime, data } = payload;
            data.catalogs.forEach((catalog) => {
                let paymentProvider = PaymentProviders.Unknown;
                if (catalog?.payrixPayments) {
                    paymentProvider = PaymentProviders.Payrix;
                } else if (catalog?.highRiskPayments) {
                    paymentProvider = PaymentProviders.Paysafe;
                }
                // ensure that the catalog has the address data to avoid having to call getAddressForCatalog during render
                const seller = payload.data.sellers?.find((s) => s.sellerId === catalog.sellerId) || EmptySeller;
                let address = catalog.address;
                let sellerName: string;

                if (seller) {
                    address = getAddressForCatalog(address, seller);
                    sellerName = safelyEscapeText(seller.name);
                }

                const coverLots =
                    data.coverLots.find((coverLot) => coverLot.catalogId === catalog.catalogId)?.coverLots || [];
                state.byId[catalog.catalogId] = {
                    ...catalog,
                    address,
                    coverLots,
                    paymentProvider,
                    sellerName,
                    title: safelyEscapeText(catalog.title),
                };

                delete state.byId[catalog.catalogId].payrixPayments;

                state.loading = difference(state.loading, [catalog.catalogId]);
                state.loaded[catalog.catalogId] = actionTime;
            });
        });
        builder.addCase(loadCatalogs.rejected, (state, action) => {
            state.loading = difference(state.loading, action.meta.arg);
        });
        builder.addCase(fetchCatalogItemSummaries.fulfilled, (slice, { meta, payload }) => {
            const { catalogId } = meta.arg;
            if (Boolean(slice.byId[catalogId])) {
                slice.byId[catalogId].closedLotsCount = payload.lotsClosed;
            }
        });
        builder.addCase(loadHomeData.fulfilled, updateCatalogsOnLoad);
        builder.addCase<string, OldSuccessAction>(
            LOAD_SELLER_UPCOMING_CATALOGS_SUCCESS,
            updateCatalogsOnOldSuccessAction
        );
        builder.addCase<string, OldSuccessAction>(LOAD_SELLER_ENDED_CATALOGS_SUCCESS, updateCatalogsOnOldSuccessAction);
        builder.addCase<string, OldSuccessAction>(LOAD_UPCOMING_CATALOGS_SUCCESS, updateCatalogsOnOldSuccessAction);
        builder.addCase<string, LIVE_AUCTION_ENDED_ACTION>(
            LIVE_AUCTION_ENDED,
            updateCatalogsOnOldLiveAuctionEndedAction
        );
        builder.addCase<string, LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION>(
            LOAD_LIVE_CATALOG_STATUS_SUCCESS,
            updateCatalogsOnOldLoadLiveCatalogStatusSuccessAction
        );
        builder.addCase<string, LIVE_AUCTION_OPENED_ACTION>(
            LIVE_AUCTION_OPENED,
            updateCatalogsOnOldLiveAuctionOpenedOrStarted
        );
        builder.addCase<string, LIVE_AUCTION_STARTED_ACTION>(
            LIVE_AUCTION_STARTED,
            updateCatalogsOnOldLiveAuctionOpenedOrStarted
        );

        // region Live lot end events
        builder.addCase<typeof LIVE_LOT_PASSED, LIVE_LOT_PASSED_ACTION>(
            LIVE_LOT_PASSED,
            (slice, { payload: { catalogId, closedLotsCount } }) =>
                timedPlusLotEndReducer(slice, catalogId, closedLotsCount)
        );
        builder.addCase<typeof LIVE_LOT_SOLD, LIVE_LOT_SOLD_ACTION>(
            LIVE_LOT_SOLD,
            (slice, { payload: { catalogId, closedLotsCount } }) =>
                timedPlusLotEndReducer(slice, catalogId, closedLotsCount)
        );
        // endregion
    },
    initialState: defaultCatalogState,
    name: 'catalog',
    reducers: {},
});

export const { reducer: catalogReducer } = catalogSlice;
