import {
    AddAttachmentsPayload,
    ConversationMessageResponsePayload,
    DeleteAttachmentPayload,
    FetchAttachmentsResponsePayload,
    SendMessagePayload,
} from '@/redux/modules/conversation/messages/conversationMessages.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    deleteDraftAttachment,
    fetchConversationMessages,
    postAddAttachment,
    postSendConversationMessage,
} from '@/redux/modules/conversation/messages/conversationMessages.api';
import {
    getAttachmentId,
    getDraftAttachments,
} from '@/redux/modules/conversation/messages/conversationMessages.selectors';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';

export const loadConversationMessages = createAsyncThunk<ConversationMessageResponsePayload[], number>(
    'la/ui/conversationMessages/loadConversationMessages',
    async (conversationId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const { payload } = await fetchConversationMessages({
            authToken,
            bidderId,
            conversationId,
            deployment,
        });
        return payload;
    }
);

export const sendConversationMessage = createAsyncThunk<
    ConversationMessageResponsePayload[] | ConversationMessageResponsePayload,
    SendMessagePayload
>(
    'la/ui/conversationMessages/sendConversationMessage',
    async ({ conversationId, draftId, messageBody }, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        await dispatch(clearDraft(conversationId));
        const { payload } = await postSendConversationMessage({
            authToken,
            bidderId,
            conversationId,
            deployment,
            draftId: draftId || undefined,
            messageBody,
        });
        return payload;
    }
);

export const clearDraft = createAsyncThunk<number, number>(
    'la/ui/conversationMessages/clearDraft',
    (conversationId) => conversationId
);

export const addAttachment = createAsyncThunk<FetchAttachmentsResponsePayload, AddAttachmentsPayload>(
    'la/ui/conversationMessages/addAttachment',
    async ({ attachments, conversationId, draftId, messageBody, sellerId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const attachmentNames = getDraftAttachments(state, conversationId).map(({ name }) => name);

        // filter attachments to prevent duplicates
        const filteredAttachments = attachments.filter(({ name }) => !attachmentNames.includes(name));
        if (!filteredAttachments.length) {
            return;
        }

        const { payload } = await postAddAttachment({
            attachments: filteredAttachments,
            authToken,
            bidderId,
            conversationId,
            deployment,
            draftId,
            messageBody,
            sellerId,
        });
        return payload;
    }
);

export const deleteAttachment = createAsyncThunk<string, DeleteAttachmentPayload>(
    'la/ui/conversationMessages/deleteAttachment',
    async ({ attachmentName, conversationId }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        const attachmentId = getAttachmentId(state, {
            attachmentName,
            conversationId,
        });

        const { payload } = await deleteDraftAttachment({
            attachmentId,
            authToken,
            bidderId,
            deployment,
        });
        return payload;
    }
);
