import { createSelector } from '@reduxjs/toolkit';
import { emptyFetchConversationResponsePayload } from '@/redux/modules/conversation/conversations/conversation.types';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const conversationSlice = createSelector(stateSelector, (state) => state.conversation);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(conversationSlice, (state) => state.byId);

export const getConversationsLoading = createSelector(conversationSlice, (state) => state.loading);

const conversationsSelector = createSelector(conversationSlice, (state) => state.shownConversations);

export const getConversationPagination = createSelector(conversationSlice, (state) => state.pagination);

export const getConversations = createSelector([conversationsSelector, byIdSelector], (conversations, byId) =>
    conversations.map((convo) => byId[convo])
);

export const getConversation = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || emptyFetchConversationResponsePayload
);

export const shouldFetchConversation = createSelector(getConversationsLoading, (loading) => !loading);
