import { ApiHosts, handleResponseOld, makeDelete, makeGet, makePost } from '@/redux/api/helpers';
import { ConversationMessage } from '@/redux/modules/conversation/conversation.types';
import {
    ConversationMessageResponsePayload,
    FetchAttachmentsResponsePayload,
} from '@/redux/modules/conversation/messages/conversationMessages.types';

/**
 * @category Messaging API
 * @see postAddAttachment
 */
type AddAttachmentResponse = {
    error: boolean;
    payload: FetchAttachmentsResponsePayload;
};
/**
 * @category Messaging API
 * @see postAddAttachment
 */
type AddAttachmentParams = {
    attachments: Array<any>;
    authToken: string;
    bidderId: number;
    conversationId: number;
    deployment: string;
    draftId: number;
    messageBody: string;
    sellerId?: number;
};
/**
 * Attach a file to message for bidderId
 * @function addAttachment
 * @category Messaging API
 * @param {AddAttachmentParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/attach
 */
export const postAddAttachment = ({
    attachments = [],
    authToken,
    bidderId,
    conversationId,
    deployment,
    draftId,
    messageBody,
    sellerId,
}: AddAttachmentParams) =>
    new Promise<AddAttachmentResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidders/${bidderId}/attach`,
            authToken,
            deployment,
            path: ApiHosts.MessagingAttachments,
        });
        attachments.forEach((file) => {
            request.attach(file.name, file);
        });
        request.field('message', JSON.stringify({ conversationId, draftId, messageBody, sellerId }));
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see postStartConversationWithAttachment
 */
type StartConversationWithAttachmentResponse = {
    error: boolean;
    payload: null;
};
/**
 * @category Messaging API
 * @see postStartConversationWithAttachment
 */
type StartConversationWithAttachmentParams = {
    attachments: Array<any>;
    authToken: string;
    bidderId: number;
    deployment: string;
    messageBody: string;
    sellerId: number;
    subject: string;
};
/**
 * Start a conversation with attachment(s)  e.g. Consign an item on seller page
 * @function startConversationWithAttachment
 * @category Messaging API
 * @param {StartConversationWithAttachmentParams}
 * @see https://messaging-api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/messages
 */
export const postStartConversationWithAttachment = ({
    attachments = [],
    authToken,
    bidderId,
    deployment,
    messageBody,
    sellerId,
    subject,
}: StartConversationWithAttachmentParams) =>
    new Promise<StartConversationWithAttachmentResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidders/${bidderId}/messages`,
            authToken,
            deployment: deployment,
            path: ApiHosts.MessagingAttachments,
        });
        attachments.forEach((file) => {
            request.attach(file.name, file);
        });
        request.field('message', JSON.stringify({ houseId: sellerId, messageBody, subject }));
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see deleteDraftAttachment
 */
type DeleteDraftAttachmentResponse = {
    error: boolean;
    payload: string;
};
/**
 * @category Messaging API
 * @see deleteDraftAttachment
 */
type DeleteDraftAttachmentParams = {
    attachmentId: number;
    authToken: string;
    bidderId: number;
    deployment: string;
};
/**
 * Deletes a draft attachment by bidder and attachment ID
 * @function deleteDraftAttachment
 * @category Messaging API
 * @param {DeleteDraftAttachmentParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/attachment/${attachmentId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/epl5ig/methods/DELETE
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/BidderDeleteAttachment-messaging-api
 */
export const deleteDraftAttachment = ({ attachmentId, authToken, bidderId, deployment }: DeleteDraftAttachmentParams) =>
    new Promise<DeleteDraftAttachmentResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `bidders/${bidderId}/attachment/${attachmentId}`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see fetchConversationMessages
 */
type FetchConversationMessagesResponse = {
    error: boolean;
    payload: ConversationMessageResponsePayload[];
};

/**
 * @category Messaging API
 * @see fetchConversationMessages
 */
type FetchConversationMessagesParams = {
    authToken: string;
    bidderId: number;
    conversationId: number;
    deployment: string;
};
/**
 * Gets all messages for given conversation and bidder Ids
 * @function fetchConversationMessages
 * @category Messaging API
 * @param {FetchConversationMessages}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/messages
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/lmdsx1/methods/GET
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/GetMessages-messaging-api
 */
export const fetchConversationMessages = ({
    authToken,
    bidderId,
    conversationId,
    deployment,
}: FetchConversationMessagesParams) =>
    new Promise<FetchConversationMessagesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidders/${bidderId}/messages`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.query({ conversationId });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see postSendConversationMessage
 */
type PostSendConversationMessageResponse = {
    error: boolean;
    payload: ConversationMessageResponsePayload[];
};
/**
 * @category Messaging API
 * @see postSendConversationMessage
 */
type PostSendConversationMessageParams = {
    authToken: string;
    bidderId: number;
    conversationId: number;
    deployment: string;
    draftId?: number;
    messageBody: any;
};
/**
 * Sends a conversation message to conversation for given bidderId e.g. "Send Message link" on auctioneer page submit message
 * @function sendConversationMessage
 * @category Messaging API
 * @param {PostSendConversationMessageParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/messages
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/lmdsx1/methods/POST
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/SendMessage-messaging-api
 */
export const postSendConversationMessage = ({
    authToken,
    bidderId,
    conversationId,
    deployment,
    draftId,
    messageBody,
}: PostSendConversationMessageParams) =>
    new Promise<PostSendConversationMessageResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidders/${bidderId}/messages`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.query({ conversationId });
        request.send({
            conversationId,
            draftId,
            messageBody,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see postStartConversationMessage
 */
type StartConversationMessageResponse = {
    error: boolean;
    payload: ConversationMessage;
};

/**
 * @category Messaging API
 * @see postStartConversationMessage
 */
type StartConversationMessageParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    houseId: number;
    lotId?: number;
    messageBody: any;
    subject?: string;
};
/**
 * Creates a new conversation message for given bidderId and details
 * @function postStartConversationMessage
 * @category Messaging API
 * @param {StartConversationMessageParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/messages
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/lmdsx1/methods/POST
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/SendMessage-messaging-api
 */
export const postStartConversationMessage = ({
    authToken,
    bidderId,
    catalogId,
    deployment,
    houseId,
    lotId,
    messageBody,
    subject,
}: StartConversationMessageParams) =>
    new Promise<StartConversationMessageResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidders/${bidderId}/messages`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send({
            catalogId,
            houseId,
            lotId,
            messageBody,
            recipient: 'house',
            subject,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
