import { CustomTerm, emptyCustomTerm } from '@/types/CatalogDetail';

type CatalogDetailSplitTerms = {
    customTerms: CustomTerm[];
    paymentDetails: string;
    shippingDetails: string;
};

export const splitCustomTerms = (terms: CustomTerm[]): CatalogDetailSplitTerms => {
    const paymentDetails = terms.find((term) => term.id === 'pay') || emptyCustomTerm;
    const shippingDetails = terms.find((term) => term.id === 'ship') || emptyCustomTerm;
    const customTerms = terms.filter((term) => term.id !== 'pay' && term.id !== 'ship');

    return {
        customTerms,
        paymentDetails: paymentDetails.data || '',
        shippingDetails: shippingDetails.data || '',
    };
};
