import { Folder } from '@/types/Messaging';
import ms from 'ms';

export type ConversationFoldersSlice = {
    folders: {
        systemFolders: Folder[];
        userFolders: Folder[];
    };
    loaded: number;
    loading: boolean;
};

export const defaultConversationFoldersSlice: ConversationFoldersSlice = {
    folders: { systemFolders: [], userFolders: [] },
    loaded: 0,
    loading: false,
};

export const CONVERSATION_FOLDERS_SLICE_CACHE_TIME = ms('0.1s');
