import {
    archiveConversationFolder,
    createConversationFolder,
    loadConversationFolders,
} from '@/redux/modules/conversation/folders/conversationFolders.actions';
import { createSlice } from '@reduxjs/toolkit';
import { defaultConversationFoldersSlice } from '@/redux/modules/conversation/folders/conversationFolders.types';
import { Folder } from '@/types/Messaging';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';

const conversationFoldersSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadConversationFolders.pending, (slice) => ({
            ...slice,
            loading: true,
        }));
        builder.addCase(loadConversationFolders.fulfilled, (slice, { payload }) => ({
            ...slice,
            folders: payload,
            loaded: Date.now(),
            loading: false,
        }));
        builder.addCase(loadConversationFolders.rejected, (slice) => ({
            ...slice,
            loading: false,
        }));

        builder.addCase(createConversationFolder.fulfilled, (slice, { payload }) => {
            const updatedUserFolders: Folder[] = [...slice.folders.userFolders, payload];
            updatedUserFolders.sort(({ name: firstName }, { name: secondName }) => {
                if (firstName < secondName) {
                    return -1;
                }
                if (firstName > secondName) {
                    return 1;
                }
                return 0;
            });
            slice.folders.userFolders = updatedUserFolders;
        });

        builder.addCase(archiveConversationFolder.fulfilled, (slice, { meta }) => ({
            ...slice,
            folders: {
                ...slice.folders,
                userFolders: [...slice.folders.userFolders].filter(({ id }) => id !== meta.arg),
            },
        }));

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(
            LOG_OUT_BIDDER,
            () => defaultConversationFoldersSlice
        );
    },
    initialState: defaultConversationFoldersSlice,
    name: 'conversationFolders',
    reducers: {},
});

export const { reducer: conversationFoldersReducer } = conversationFoldersSlice;
