export type ConversationPaginationInfo = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
};

export type ConversationSlice = {
    byId: { [id: number]: FetchConversationResponsePayload };
    loaded: number;
    loading: boolean;
    pagination: ConversationPaginationInfo;
    shownConversations: number[];
};

export const defaultConversationSlice: ConversationSlice = {
    byId: {},
    loaded: 0,
    loading: false,
    pagination: {
        page: 1,
        pageSize: 48,
        totalPages: 1,
        totalRecords: 0,
    },
    shownConversations: [],
};

export type ConversationQueryType = {
    filter?: string;
    folderId: number;
    page?: number;
    pageSize?: number;
    search?: string;
    // sort: string,
};

export type FetchConversationResponsePayload = {
    catalogId: number;
    conversationId: number;
    folderId: number;
    hasAttachments: boolean;
    imageUrl: string;
    itemId: number;
    logoUrl: string;
    messageCount: number;
    read: boolean;
    sellerId: number;
    sender: string;
    subject: string;
    tsLastMessageSent: number;
    type: 'house' | 'bidder';
};

/**
 * @category Messaging API
 * @see fetchConversation
 * @see fetchConversations
 */
export type FetchConversationResponse = {
    error: boolean;
    meta?: {
        pagination: {
            pageNumber: number;
            pageSize: number;
            totalPages: number;
            totalRecordCount: number;
        };
    } | null;
    payload: FetchConversationResponsePayload[];
};

export const emptyFetchConversationResponsePayload: FetchConversationResponsePayload = {
    catalogId: 0,
    conversationId: 0,
    folderId: 0,
    hasAttachments: false,
    imageUrl: '',
    itemId: 0,
    logoUrl: '',
    messageCount: 0,
    read: false,
    sellerId: 0,
    sender: '',
    subject: '',
    tsLastMessageSent: 0,
    type: 'bidder',
};
