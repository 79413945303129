import { AuctionStatus } from '@/types/LiveAuctionState';
import { createSelector } from '@reduxjs/toolkit';
import { EmptyCatalog } from '@/types/Catalog';
import { EmptyCoverLot } from '@/types/CoverLot';
import { getCoverLots } from '@/utils/urls';
import { getLongAddressFormat, getShortAddressFormat } from '@liveauctioneers/caterwaul-components/lib/utils/address';
import { GlobalState } from '@/redux/store';
import { JASPER52_ID } from '@/utils/houseIds.constants';
import { safelyEscapeText } from '@/utils/safelyRender';
import { shouldShowLive } from '@/utils/live';
import clamp from 'lodash/clamp';
import ms from 'ms';

export const CATALOG_REDUX_STORE_TIME = ms('30m');

const stateSelector = (state: GlobalState) => state;
const catalogSlice = createSelector(stateSelector, (state) => state.catalog);

const idSelector = (_: GlobalState, id: number) => id;
const idsSelector = (_: GlobalState, ids: number[]) => ids;
const secondIdSelector = (state: GlobalState, notused, id: number) => id;
const secondOptionalIdSelector = (state: GlobalState, notused, id?: number) => id;
const byIdSelector = createSelector(catalogSlice, (state) => state.byId);

const loadedSelector = createSelector(catalogSlice, (state) => state.loaded);

const loadingSelector = createSelector(catalogSlice, (state) => state.loading);

export const getIsCatalogLoaded = createSelector([loadedSelector, idSelector], (loaded, id) =>
    loaded.hasOwnProperty(id)
);

export const getCatalog = createSelector([byIdSelector, idSelector], (byId, id) => byId[id] || EmptyCatalog);
export const getCatalogsByIds = createSelector([byIdSelector, idsSelector], (byId, ids) =>
    ids.map((catalogId) => byId[catalogId]).filter((catalog) => Boolean(catalog?.catalogId))
);

export const getCatalogIsLAPayments = createSelector(getCatalog, ({ liveAuctioneersPayments }) =>
    Boolean(liveAuctioneersPayments)
);
export const getCatalogAchPayments = createSelector(getCatalog, ({ achPayments }) => Boolean(achPayments));
export const getCatalogBillingCheckout = createSelector(getCatalog, ({ billingCheckout }) => Boolean(billingCheckout));
export const getCatalogHasVideo = createSelector(getCatalog, ({ hasVideo }) => Boolean(hasVideo));
export const getCatalogPublishDateTs = createSelector(getCatalog, ({ publishDateTs }) => publishDateTs || 0);

export const getCatalogCurrency = createSelector(getCatalog, ({ currency }) => currency || 'usd');
export const getCatalogPaymentOptions = createSelector(getCatalog, ({ paymentOptions }) => paymentOptions);
export const getCatalogBuyersPremium = createSelector(
    getCatalog,
    ({ buyersPremium }) => buyersPremium || EmptyCatalog.buyersPremium
);
export const getCatalogIsSEOExcluded = createSelector(getCatalog, ({ isSEOExcluded }) => Boolean(isSEOExcluded));
export const getCatalogIsTimed = createSelector(getCatalog, ({ isTimed }) => Boolean(isTimed));
export const getCatalogAbsenteeBidders = createSelector(getCatalog, ({ absenteeBidders }) => absenteeBidders || 0);
export const getCatalogPaymentProvider = createSelector(getCatalog, ({ paymentProvider }) => paymentProvider);
export const getCatalogHighRiskPayments = createSelector(getCatalog, ({ highRiskPayments }) => highRiskPayments);
export const getCatalogRequiredACH = createSelector(getCatalog, ({ requiredAch }) => requiredAch);
export const getCatalogTitle = createSelector(getCatalog, ({ title }) => title || '');
export const getCatalogDescription = createSelector(getCatalog, ({ description }) => description || '');
export const getCatalogCleanDescription = createSelector(getCatalog, ({ description }) =>
    safelyEscapeText(description || '')
);
export const getCatalogSellerId = createSelector(getCatalog, ({ sellerId }) => sellerId);
export const getCatalogSellerIsJasper52 = createSelector(getCatalog, ({ sellerId }) => sellerId === JASPER52_ID);
export const getCatalogSellerName = createSelector(getCatalog, ({ sellerName }) => sellerName || '');
export const getCatalogPreview = createSelector(getCatalog, ({ preview }) => preview || '');
export const getCatalogStatus = createSelector(getCatalog, ({ catalogStatus }) => catalogStatus);
export const getCatalogIsDone = createSelector(
    getCatalogStatus,
    (catalogStatus) => catalogStatus === AuctionStatus.Done
);
export const getCatalogStartTime = createSelector(getCatalog, ({ saleStartTs }) => saleStartTs);
export const getCatalogEndTime = createSelector(getCatalog, ({ saleEndTs }) => saleEndTs);
export const getCatalogAddress = createSelector(getCatalog, ({ address }) => address);
export const getCatalogCity = createSelector(getCatalogAddress, (address) => address?.city);
export const getCatalogCountryFull = createSelector(getCatalogAddress, (address) => address?.fullCountryName);
export const getCatalogCountry = createSelector(getCatalogAddress, (address) => address?.country);
export const getCatalogState = createSelector(getCatalogAddress, (address) => address?.state);
export const getCatalogLotsListed = createSelector(getCatalog, ({ lotsListed }) => lotsListed || 0);
export const getCatalogIsCatalogOnly = createSelector(getCatalog, ({ isCatalogOnly }) => Boolean(isCatalogOnly));
export const getCatalogIsTimedPlus = createSelector(getCatalog, ({ isTimedPlus }) => Boolean(isTimedPlus));

export const getCatalogIdsByIdsNotDone = createSelector([byIdSelector, idsSelector], (byId, ids) =>
    ids
        .map((catalogId) => byId[catalogId])
        .filter((catalog) => Boolean(catalog?.catalogId) && catalog?.catalogStatus !== AuctionStatus.Done)
        .map(({ catalogId }) => catalogId)
);
export const getCatalogIdsByIdsLive = createSelector([byIdSelector, idsSelector], (byId, ids) =>
    ids
        .map((catalogId) => byId[catalogId])
        .filter((catalog) => Boolean(catalog?.catalogId) && catalog?.catalogStatus === 'live')
        .map((catalog) => catalog.catalogId)
);

export const getCatalogShortAddress = createSelector(
    [getCatalogCity, getCatalogCountry, getCatalogState],
    (city, country, state) => getShortAddressFormat(city, country, state)
);

export const getCatalogLongAddress = createSelector(
    [getCatalogCity, getCatalogCountryFull, getCatalogState],
    (city, country, state) => getLongAddressFormat(city, country, state)
);

export const getCatalogIsPreview = createSelector(
    [getCatalogPublishDateTs, getCatalogStatus],
    (publishDateTs, catalogStatus) => publishDateTs === 0 && catalogStatus !== AuctionStatus.Done
);

export const getCatalogIsLivePayments = createSelector(
    getCatalog,
    ({ liveAuctioneersPayments }) => liveAuctioneersPayments
);

export const getCatalogTimedPlusEstimatedCloseTime = createSelector(
    getCatalog,
    ({ saleEndEstimatedTs }) => saleEndEstimatedTs
);
export const getCatalogIsTimedPlusClosing = createSelector(
    getCatalog,
    ({ catalogStatus, isTimedPlus, saleEndEstimatedTs }) =>
        isTimedPlus &&
        catalogStatus !== AuctionStatus.Done &&
        Boolean(saleEndEstimatedTs) &&
        Date.now() / 1000 >= saleEndEstimatedTs
);
export const getCatalogIsTimedPlusBiddingOpen = createSelector(
    getCatalog,
    ({ isTimedPlus, saleStartTs }) => isTimedPlus && Boolean(saleStartTs) && Date.now() / 1000 > saleStartTs
);
export const getCatalogTimedPlusBiddingEndsFromTs = createSelector(
    getCatalog,
    ({ saleEndEstimatedTs }) => saleEndEstimatedTs
);
export const getTimedPlusCatalogClosedLotCount = createSelector(getCatalog, ({ closedLotsCount }) => closedLotsCount);

export const getCatalogIsAutopay = createSelector(getCatalog, ({ isAutopay }) => Boolean(isAutopay));
export const getCatalogIsInstantAutopay = createSelector(getCatalog, ({ isInstantAutopay }) =>
    Boolean(isInstantAutopay)
);
export const getCatalogCoverLots = createSelector(
    getCatalog,
    ({ coverLots }) => coverLots || [EmptyCoverLot, EmptyCoverLot, EmptyCoverLot]
);

export const getCatalogCoverLotsTransformed = createSelector(
    [getCatalogCoverLots, secondOptionalIdSelector],
    (coverLots, height) => getCoverLots(coverLots || [EmptyCoverLot, EmptyCoverLot, EmptyCoverLot], height || 0)
);

export const getCatalogPercentComplete = createSelector([getCatalog, secondIdSelector], (catalog, index) => {
    if (!Number.isNaN(index) && !Number.isNaN(catalog.lotsListed) && catalog.lotsListed) {
        return clamp(((index + 1) / catalog.lotsListed) * 100, 2.5, 100);
    }
    return 0;
});

export const getShouldShowLive = createSelector([byIdSelector, idSelector], (byId, id) => {
    if (byId[id]) {
        const { catalogStatus, isCatalogOnly, saleStartTs } = byId[id];
        return shouldShowLive(catalogStatus, isCatalogOnly, saleStartTs);
    }
    return false;
});

export const getAllCatalogs = createSelector([byIdSelector], (byId) => byId);
export const getAllCatalogIds = createSelector([byIdSelector], (byId) => Object.keys(byId).map(Number));

export const getCatalogIdsForSeller = createSelector([byIdSelector, idSelector], (byId, id) =>
    Object.keys(byId)
        .map((key) => byId[Number(key)])
        .filter((catalog) => catalog?.sellerId === id && catalog?.catalogStatus)
        .map((catalog) => catalog.catalogId)
);

export const whichCatalogsNeeded = createSelector(
    [byIdSelector, loadedSelector, loadingSelector, idsSelector],
    (ids, loaded, loading, catalogIds) => {
        const needed: number[] = [];
        catalogIds.forEach((catalogId: number) => {
            if (catalogId) {
                const time = Date.now();
                const diff = time - loaded[catalogId];
                if (diff < CATALOG_REDUX_STORE_TIME) {
                    return false;
                }
                if (!loading.includes(catalogId)) {
                    needed.push(catalogId);
                }
            }
        });
        return needed;
    }
);
