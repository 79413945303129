import { ApiHosts, handleResponseOld, makeGet, makePost } from '@/redux/api/helpers';
import { FetchCatalogsResponsePayload } from './catalog.types';
import type { BuyNow } from '@/types/BuyNow';
import type { Catalog } from '@/types/Catalog';
import type { CatalogRegistration } from '@/types/CatalogRegistration';
import type { CoverLot } from '@/types/CoverLot';
import type { Item } from '@/types/item/Item';
import type { Seller } from '@/types/Seller';

/**
 * @category Item API
 * @see fetchCatalogsByIds
 */
export type FetchCatalogsByIdsResponse = {
    data: FetchCatalogsResponsePayload;
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see fetchCatalogsByIds
 */
type FetchCatalogsByIdsParams = {
    catalogIds: number[];
    deployment: string;
};

/**
 * Fetch catalog data for given catalogIds via Post to Item API service
 * @function fetchCatalogsByIds
 * @category Item API
 * @param {FetchCatalogsByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/catalogs
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/post-spa-catalogs.ts
 */
export const fetchCatalogsByIds = ({ catalogIds, deployment }: FetchCatalogsByIdsParams) =>
    new Promise<FetchCatalogsByIdsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/catalogs',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                ids: catalogIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see fetchCuratorsForCatalog
 */
type FetchCuratorsForCatalogResponse = {
    data: [
        {
            expertise: string;
            id: number;
            imageFileName: string;
            name: string;
        },
    ];
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see fetchCuratorsForCatalog
 */
type FetchCuratorsForCatalogParams = {
    catalogId: number;
    deployment: string;
};

/**
 * Fetch curators for given catalogId via Get to Item API service
 * @function fetchCuratorsForCatalog
 * @category Item API
 * @param {FetchCuratorsForCatalogParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/catalog/${catalogId}/curators
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/get-catalog-curators.ts
 */
export const fetchCuratorsForCatalog = ({ catalogId, deployment }: FetchCuratorsForCatalogParams) =>
    new Promise<FetchCuratorsForCatalogResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `catalog/${catalogId}/curators`,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see fetchRegisteredCatalogs
 */
type FetchRegisteredCatalogsResponse = {
    data: CatalogRegistration[];
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see fetchRegisteredCatalogs
 */
type FetchRegisteredCatalogsParams = {
    authToken: string;
    deployment: string;
};

/**
 * Fetch catalog registration data for authed user via Get to Item API service
 * @function fetchRegisteredCatalogs
 * @category Item API
 * @param {FetchRegisteredCatalogsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/catalog/registration
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/bidder/routes/get-catalog-registration.ts
 */
export const fetchRegisteredCatalogs = ({ authToken, deployment }: FetchRegisteredCatalogsParams) =>
    new Promise<FetchRegisteredCatalogsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'catalog/registration',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Item API
 * @see fetchUpcomingCatalogs
 */
type FetchUpcomingCatalogsParams = {
    authToken: string;
    deployment: string;
    forceUpdate: boolean;
    pageSize: number;
    today: boolean;
};

/**
 * @category Item API
 * @see fetchUpcomingCatalogs
 */
type FetchUpcomingCatalogsResponse = {
    data: {
        catalogs: Catalog[];
        sellers: Seller[];
        smallCoverLots: CoverLot[];
    };
    message: string;
    success: boolean;
};

/**
 * @see fetchUpcomingCatalogs
 */
type FetchUpcomingCatalogsQueryParams = {
    bustLocalCache?: number;
    offset?: number;
    pageSize?: number;
    today?: boolean;
};

/**
 * Fetch all upcoming catalogs via Get to Item API service
 * @function fetchUpcomingCatalogs
 * @category Item API
 * @param {FetchUpcomingCatalogsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/upcomingcatalogs
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/get-upcoming-catalogs.ts
 */
export const fetchUpcomingCatalogs = ({
    authToken,
    deployment,
    forceUpdate,
    pageSize,
    today,
}: FetchUpcomingCatalogsParams) =>
    new Promise<FetchUpcomingCatalogsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/upcomingcatalogs',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        const query: FetchUpcomingCatalogsQueryParams = {};
        if (pageSize) {
            query.pageSize = pageSize;
        }
        if (today) {
            query.today = true;
        }
        query.offset = new Date().getTimezoneOffset();
        if (forceUpdate) {
            query.bustLocalCache = Date.now();
        }
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Redstripe API
 * @see getHasValidCardCatalog
 */
type GetHasValidCardCatalogResponse = {
    error: boolean;
    payload: boolean;
};

/**
 * @see getHasValidCardCatalog
 */
type GetHasValidCardCatalogParams = {
    authToken: string;
    bidderId: number;
    cardId: number;
    deployment: string;
    houseId: number;
    providerId: number;
};

/**
 * @see getHasValidCardCatalog
 */
type GetHasValidCardCatalogQueryParams = {
    houseId: number;
    providerId: number;
};

/**
 * Determine if bidder has valid card for autopay auction
 * @function getHasValidCardCatalog
 * @category Item API
 * @param {GetHasValidCardCatalogParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/upcomingcatalogs
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/get-upcoming-catalogs.ts
 */
export const getHasValidCardCatalog = ({
    authToken,
    bidderId,
    cardId,
    deployment,
    houseId,
    providerId,
}: GetHasValidCardCatalogParams) =>
    new Promise<GetHasValidCardCatalogResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `cards/${bidderId}/ensurevalid/${cardId}`,
            authToken,
            deployment,
            path: ApiHosts.Redstripe,
        });
        const query: GetHasValidCardCatalogQueryParams = {
            houseId,
            providerId,
        };
        request.query(query);
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
/**
 * @category Item API
 * @see postCatalogItems
 */
export type PostCatalogItemsResponse = {
    data: {
        buyNows: BuyNow[];
        catalogItems: {
            catalogId: number;
            totalCount: number;
        }[];
        items: Item[];
    };
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see postCatalogItems
 */
type PostCatalogItemsParams = {
    authToken: string;
    catalogId: number;
    count?: number | string;
    cursor: number;
    deployment: string;
    itemId?: number;
    page?: number;
};

/**
 * @see postCatalogItems
 */
type PostCatalogItemsData = {
    cursor?: number;
    itemId?: number;
    page?: number;
    pageSize?: number | string;
    wrap?: boolean;
};

/**
 * Sends index and page information and returns those particular items from a given catalogId via Post to Item API service
 * @function postCatalogItems
 * @category Item API
 * @param {PostCatalogItemsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/catalog/items
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/post-catalog-items.ts
 */
export const postCatalogItems = ({
    authToken,
    catalogId,
    count,
    cursor,
    deployment,
    itemId,
    page,
}: PostCatalogItemsParams) =>
    new Promise<PostCatalogItemsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'spa/small/catalog/items',
            authToken,
            deployment,
            path: ApiHosts.ItemApi,
        });
        const data: PostCatalogItemsData = {};

        if (cursor) {
            data.cursor = cursor;
            data.wrap = true;
        }

        if (page) {
            data.page = page;
        }

        if (count) {
            data.pageSize = count;
        }

        if (itemId) {
            data.itemId = itemId;
        }

        // @ts-ignore
        if (global.__SERVER__ && !cursor && cursor !== 0 && typeof page === 'number' && typeof count === 'number') {
            data.cursor = (page - 1) * count;
        }
        request.type('form');
        request.send({ catalogId, ...data });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Approval API
 * @see postRegisterForCatalog
 */
type PostRegisterForCatalogResponse = {
    error: boolean;
    payload: {
        approvalStatus: number;
        bidLimit: number;
        bidderId: number;
        catalogId: number;
    };
};

/**
 * @category Approval API
 * @see postRegisterForCatalog
 */
type PostRegisterForCatalogParams = {
    authToken: string;
    bidderId: number;
    catalogId: number;
    deployment: string;
    itemId: number;
    minimumBidLimit: boolean;
};

/**
 * Registers given bidderId for given catalogId via Post to Approval API
 * @function postRegisterForCatalog
 * @category Approval API
 * @param {PostRegisterForCatalogParams}
 * @see https://approval-DEPLOYMENT.liveauctioneers.com/approval/catalog/${catalogId}/bidder/${bidderId}/register
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/ou2a17vn9h/resources/m5df41/methods/POST
 * @see https://github.com/LIVEauctioneers/approval/tree/master/functions/RegisterBidder
 */
export const postRegisterForCatalog = ({
    authToken,
    bidderId,
    catalogId,
    deployment,
    itemId,
    minimumBidLimit,
}: PostRegisterForCatalogParams) =>
    new Promise<PostRegisterForCatalogResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `catalog/${catalogId}/bidder/${bidderId}/register`,
            authToken,
            deployment,
            path: ApiHosts.Approval,
        });
        request.send({
            acceptTerms: true,
            lotId: itemId,
            minimumBidLimit,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
