import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { FetchCatalogItemsParams } from '@/redux/modules/catalog/items/catalogItems.types';
import { FetchItemsSummariesResponse } from '@/types/item/ItemSummary';
import { fetchItemSummariesByCatalogId } from '@/redux/modules/item/summary/itemSummary.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';

export const fetchCatalogItemSummaries = createAsyncThunk<
    FetchItemsSummariesResponse['payload'],
    FetchCatalogItemsParams
>('la/ui/catalogItems/fetchCatalogItemSummaries', async ({ catalogId, query }, { getState }) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const deployment = getDeployment(state);

    const { payload } = await fetchItemSummariesByCatalogId({
        authToken,
        catalogId,
        deployment,
        query,
    });

    return payload;
});
