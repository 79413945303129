import { createSlice } from '@reduxjs/toolkit';
import { defaultCategorySlice } from '@/redux/modules/category/category.types';
import { fetchAllCategoriesLandingPageData, loadCategories } from '@/redux/modules/category/category.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

const categorySlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCategories.pending, (state, { meta }) => {
            state.loading = union(state.loading, meta.arg);
        });
        builder.addCase(loadCategories.fulfilled, (state, { payload }) => {
            const actionTime = Date.now();
            const { payload: categories } = payload;
            categories.forEach((category) => {
                state.byId[category.categoryId] = category;
                state.loaded[category.categoryId] = actionTime;
                state.loading = difference(state.loading, [category.categoryId]);
            });
        });
        builder.addCase(loadCategories.rejected, (state, { meta }) => {
            state.loading = difference(state.loading, meta.arg);
        });

        builder.addCase(fetchAllCategoriesLandingPageData.fulfilled, (state, { payload }) => {
            state.loaded = payload.payload;
        });
    },
    initialState: defaultCategorySlice,
    name: 'category',
    reducers: {},
});

export const { reducer: categoryReducer } = categorySlice;
