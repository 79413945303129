import { ApiHosts, handleResponseOld, makeGet, makePost } from '@/redux/api/helpers';
import { Category, ReduxCategory } from '@/types/categories/categories';
import { CategoryNamesAndIds } from '@/pages/DiscoverCategories/constants/constant';
import { makeAllCategoriesQueryString } from '@/utils/queryParams';

/**
 * @category Category API
 * @see fetchCategoriesByIds
 */
type FetchCategoriesByIdsParams = {
    authToken: string;
    categoryIds: number[];
    deployment: string;
};

/**
 * @category Category API
 * @see fetchCategoriesByIds
 */
export type FetchCategoriesByIdsResponse = {
    error: boolean;
    payload: ReduxCategory[];
};

/**
 * Fetches Category descriptions by given categoryIds
 * @function fetchCategoriesByIds
 * @category Category API
 * @param {FetchCategoriesByIdsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/getcategorydescription
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/qdhsknmtx7/resources/pdxwjs/methods/POST
 * @see https://github.com/LIVEauctioneers/category-api/tree/master/functions/GetCategoryDescription
 */
export const fetchCategoriesByIds = ({ authToken, categoryIds, deployment }: FetchCategoriesByIdsParams) =>
    new Promise<FetchCategoriesByIdsResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'getcategorydescription',
            authToken,
            deployment,
            path: ApiHosts.Category,
        });
        request.send({ categoryIds });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Category API
 * @see fetchAllCategories
 */
interface FetchAllCategoriesParams {
    authToken: string;
    deployment: string;
}

/**
 * @category Category API
 * @see fetchAllCategories
 */
export type FetchAllCategoriesResponse = {
    error: boolean;
    payload: { [categoryId: number]: Category[] };
};
/**
 * Fetches all Categories based on rating for the landing page of /c/.
 * @function fetchAllCategories
 * @category Category API
 * @param {FetchAllCategoriesParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/category-api/discovercategories
 */
export const fetchAllCategories = ({ authToken, deployment }: FetchAllCategoriesParams) => {
    /*
        These are the current ids for the Top Categories.
        they are subject to change in the future but there is currently
        no place in the backend keeping track of that so it's being done here
    */
    const ids = Object.values(CategoryNamesAndIds);
    let query = makeAllCategoriesQueryString(ids);

    return new Promise<FetchAllCategoriesResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `discovercategories${query}`,
            authToken,
            deployment,
            path: ApiHosts.Category,
        });

        request.end((err, response) => {
            handleResponseOld({ err, reject, resolve, response });
        });
    });
};
