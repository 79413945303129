import * as api from './catalogAd.api';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { FetchCatalogAdIfNeededParams, KeyValues, LoadCatalogAdResponse } from './catalogAd.types';
import { generateAdKey } from './catalogAd.helpers';
import { getDeployment } from '@/redux/modules/config';
import { shouldFetchCatalogAd } from './catalogAd.selectors';

export const loadCatalogAd = createAsyncThunk<LoadCatalogAdResponse, KeyValues>(
    'la/domain/catalogAd/loadCatalogAd',
    async ({ categoryIds, keywords, pageName }, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const { data } = await api.fetchCatalogAd({
            categoryIds,
            deployment,
            keywords,
            pageName,
        });
        const now = Date.now();
        const key = generateAdKey({ categoryIds, keywords, pageName });
        return {
            actionTime: now,
            data,
            key,
        };
    }
);

export const fetchCatalogAdIfNeeded = createAsyncThunk<void, FetchCatalogAdIfNeededParams>(
    'la/domain/catalogAd/fetchCatalogAdIfNeeded',
    async ({ categoryIds, force, keywords, pageName }, { dispatch, getState }) => {
        const state = getState();
        const key = generateAdKey({ categoryIds, keywords, pageName });
        if (force || shouldFetchCatalogAd(state, key)) {
            await dispatch(loadCatalogAd({ categoryIds, keywords, pageName }));
        }
    }
);
