import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchRelatedSearchTiles } from '@/redux/api/categoryRelatedSearches';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';

/* Action Types */
export const LOAD_CATEGORY_RELATED_SEARCHES_FAIL = 'la/domain/items/LOAD_CATEGORY_RELATED_SEARCHES_FAIL';
export const LOAD_CATEGORY_RELATED_SEARCHES_SUCCESS = 'la/domain/items/LOAD_CATEGORY_RELATED_SEARCHES_SUCCESS';

interface DefaultState {
    categoryRelatedSearches: {
        displayName: string;
        imageUrl: string;
        relatedCategoryId: number;
        url: string;
    }[];
}

/* reducer */
export type CategoryRelatedSearchesSlice = {
    categoryRelatedSearches: any[];
};

export const defaultCategoryRelatedSearchesSlice: DefaultState = {
    categoryRelatedSearches: [],
};

export default function reducer(
    state: CategoryRelatedSearchesSlice = defaultCategoryRelatedSearchesSlice,
    action: any = {}
): CategoryRelatedSearchesSlice {
    switch (action.type) {
        case LOAD_CATEGORY_RELATED_SEARCHES_FAIL:
            return {
                ...state,
                categoryRelatedSearches: [],
            };
        case LOAD_CATEGORY_RELATED_SEARCHES_SUCCESS:
            return {
                ...state,
                categoryRelatedSearches: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const categoryRelatedSearchesSelector = createSelector(stateSelector, (state) => state.categoryRelatedSearches);

export const getCategoryRelatedSearches = createSelector(
    categoryRelatedSearchesSelector,
    (state) => state.categoryRelatedSearches || []
);

/* ACTION CREATORS */
export const fetchCategoryRelatedSearches =
    (itemIds: number[], categoryId?: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        try {
            const response = await fetchRelatedSearchTiles({
                authToken,
                categoryId,
                deployment,
                itemIds,
            });

            dispatch({
                payload: response.payload,
                type: LOAD_CATEGORY_RELATED_SEARCHES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { categoryId },
                payload: error,
                type: LOAD_CATEGORY_RELATED_SEARCHES_FAIL,
            });
        }
    };
