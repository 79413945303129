import {
    ConversationQueryType,
    FetchConversationResponse,
} from '@/redux/modules/conversation/conversations/conversation.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchConversation, fetchConversations } from '@/redux/modules/conversation/conversations/conversation.api';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { shouldFetchConversation } from '@/redux/modules/conversation/conversations/conversation.selectors';

export const loadConversations = createAsyncThunk<FetchConversationResponse, ConversationQueryType>(
    'la/ui/conversation/loadConversations',
    async (query, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        return await fetchConversations({
            authToken,
            bidderId,
            deployment,
            query,
        });
    }
);

export const loadConversation = createAsyncThunk<FetchConversationResponse, number>(
    'la/ui/conversation/loadConversation',
    async (conversationId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        return await fetchConversation({
            authToken,
            bidderId,
            conversationId,
            deployment,
        });
    }
);

export const fetchConversationIfNeeded = createAsyncThunk<void, number>(
    'la/ui/conversation/fetchConversationsIfNeeded',
    async (conversationId, { dispatch, getState }) => {
        if (shouldFetchConversation(getState())) {
            await dispatch(loadConversation(conversationId));
        }
    }
);
