import { BidIncrementCatalogData } from '@/types/BidIncrement';
import { Catalog } from '@/types/Catalog';
import { CoverLot } from '@/types/CoverLot';
import { Seller } from '@/types/Seller';

export type FetchCatalogsResponseCoverLots = {
    catalogId: number;
    coverLots: CoverLot[];
};

export type FetchCatalogsResponsePayload = {
    bidIncrements: BidIncrementCatalogData[];
    catalogs: Catalog[];
    closedLotIds?: number[];
    coverLots: FetchCatalogsResponseCoverLots[];
    sellers: Seller[];
};

export type FetchCatalogsIfNeededPayload = {
    catalogIds: number[];
};

export type LoadCatalogsResponse = {
    actionTime: number;
    data: FetchCatalogsResponsePayload;
};

export type CatalogSlice = {
    // dictionary, key is catalog id, value is the catalog item
    byId: { [id: number]: Catalog };
    // dictionary, key is catalog id, value is the timestamp the catalog was loaded
    loaded: { [id: number]: number };
    // array of catalog ids that are in the middle of being loaded
    loading: number[];
};

export const defaultCatalogState: CatalogSlice = {
    byId: {},
    loaded: {},
    loading: [],
};
