export type AdUnit = {
    adUnitId: number;
    catalogId: number;
    catalogTitle: string;
    categoryId?: number;
    consignment: boolean;
    destination: string;
    imageUrl1: string;
    imageUrl2: string;
    imageUrl3: string;
    logoId?: string;
    saleStartTs: number;
    sellerId: number;
    sellerName: string;
};

export type CategoryAdState = {
    byId: { [id: string]: AdUnit };
    loaded: { [id: string]: number };
    loading: string[];
};

export const defaultCatalogAdState: CategoryAdState = {
    byId: {},
    loaded: {},
    loading: [],
};

export type KeyValues = {
    categoryIds?: string;
    keywords?: string;
    pageName?: string;
};

export const emptyAdUnit: AdUnit = {
    adUnitId: 0,
    catalogId: 0,
    catalogTitle: '',
    categoryId: 0,
    consignment: false,
    destination: '',
    imageUrl1: '',
    imageUrl2: '',
    imageUrl3: '',
    logoId: '',
    saleStartTs: 0,
    sellerId: 0,
    sellerName: '',
};

export type FetchCatalogAdIfNeededParams = {
    categoryIds?: string;
    force?: boolean;
    keywords?: string;
    pageName?: string;
};

export type FetchCatalogAdResponsePayload = {
    ads: AdUnit[];
};

export type LoadCatalogAdResponse = {
    actionTime: number;
    data: FetchCatalogAdResponsePayload;
    key: string;
};

export const LeaderBoardAdTypes = {
    'auctions-directory': 'Leaderboard Ad - Auction House Directory',
    'auctions-near-me': 'Leaderboard Ad - Auctions Near Me',
    'favorites-page': 'Leaderboard Ad - Favorites',
    messages: 'Leaderboard Ad - Messages',
    'my-bids': 'Leaderboard Ad - My Bids',
    'won-items': 'Leaderboard Ad - Won Items',
};

export const CatalogAdTypes = {
    'category-banner': 'Category Leaderboard Ad',
};
