import { ApiHosts, handleResponseWithNon200Errors, makeGet } from '@/redux/api/helpers';
import { CatalogAnnouncement } from '@/types/CatalogAnnouncement';
import { CatalogAnnouncementByCatalogIdResponsePayload } from '@/redux/modules/catalog/announcement/catalogAnnouncement.types';

/**
 * @category Announcement API
 * @see fetchCatalogAnnouncementsByAnnouncementId
 */
export type FetchCatalogAnnouncementsByAnnouncementIdResponse = {
    error: boolean;
    payload: CatalogAnnouncement;
};

/**
 * @category Announcement API
 * @see fetchCatalogAnnouncementsByAnnouncementId
 */
type FetchCatalogAnnouncementsByCatalogIdParams = {
    catalogId: number;
    deployment: string;
    houseId: number;
};

/**
 * GET request to Announcement API that returns catalog announcements by announcementId
 * @function fetchCatalogAnnouncementsByAnnouncementId
 * @category Announcement API
 * @param {FetchCatalogAnnouncementsByCatalogIdParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/announcement-api/announcements/${announcementId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/ncfqsb9ei3/resources/knz6vy/methods/GET
 * @see https://github.com/LIVEauctioneers/announcements-api/tree/master/functions/announcements-api-GetAnnouncementBody
 */
export const fetchCatalogAnnouncementsByAnnouncementId = ({
    announcementId,
    deployment,
}: fetchCatalogAnnouncementsByAnnouncementIdParams) =>
    new Promise<FetchCatalogAnnouncementsByAnnouncementIdResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `announcements/${announcementId}`,
            deployment,
            path: ApiHosts.Announcements,
        });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });

/**
 * @category Announcement API
 * @see fetchCatalogAnnouncementsByCatalogId
 */
export type FetchCatalogAnnouncementsByCatalogIdResponse = {
    error: boolean;
    payload: CatalogAnnouncementByCatalogIdResponsePayload;
};

/**
 * @category Announcement API
 * @see fetchCatalogAnnouncementsByCatalogId
 */
type fetchCatalogAnnouncementsByAnnouncementIdParams = {
    announcementId: number;
    deployment: string;
};

/**
 * GET request to Announcement API that retrieves announcements by give catalog and house Ids
 * @function fetchCatalogAnnouncementsByCatalogId
 * @category Announcement API
 * @param {FetchCatalogAnnouncementsByCatalogIdParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/announcement-api/announcements/${announcementId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/ncfqsb9ei3/resources/vqe9fj/methods/GET
 * @see https://github.com/LIVEauctioneers/announcements-api/tree/master/functions/announcements-api-GetHouseAnnouncements
 */
export const fetchCatalogAnnouncementsByCatalogId = ({
    catalogId,
    deployment,
    houseId,
}: FetchCatalogAnnouncementsByCatalogIdParams) =>
    new Promise<FetchCatalogAnnouncementsByCatalogIdResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'announcements',
            deployment,
            path: ApiHosts.Announcements,
        });
        request.query({ catalogId, houseId });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
