import { categories, generateAdKey } from './catalogAd.helpers';
import { createSlice } from '@reduxjs/toolkit';
import { defaultCatalogAdState } from './catalogAd.types';
import { loadCatalogAd } from './catalogAd.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

const catalogAdSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCatalogAd.pending, (state, { meta }) => {
            const { categoryIds, keywords, pageName } = meta.arg;
            const key = generateAdKey({ categoryIds, keywords, pageName });
            state.loading = union(state.loading, [key]);
        });

        builder.addCase(loadCatalogAd.fulfilled, (state, { payload }) => {
            state.loaded[payload.key] = payload.actionTime;
            state.loading = difference(state.loading, [payload.key]);
            if (payload.data.ads.length && payload.key.includes('navigation')) {
                payload.data.ads.forEach((ad) => {
                    if (categories[ad.categoryId]) {
                        const key = generateAdKey({
                            categoryIds: `${ad.categoryId}`,
                            pageName: 'navigation',
                        });
                        state.byId[key] = ad;
                    }
                });
            } else if (payload.data.ads.length) {
                state.byId[payload.key] = payload.data.ads[0];
            }
        });

        builder.addCase(loadCatalogAd.rejected, (state, { meta }) => {
            const { categoryIds, keywords, pageName } = meta.arg;
            const key = generateAdKey({ categoryIds, keywords, pageName });
            state.loading = difference(state.loading, [key]);
        });
    },
    initialState: defaultCatalogAdState,
    name: 'catalogAd',
    reducers: {},
});

export const { reducer: catalogAdReducer } = catalogAdSlice;
