import { createSelector } from '@reduxjs/toolkit';
import { emptyCatalogRegistrationState } from './catalogRegistration.types';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const catalogRegistrationSlice = createSelector(stateSelector, (state) => state.catalogRegistration);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(catalogRegistrationSlice, (state) => state.byId);

export const getCatalogRegistration = createSelector([byIdSelector, idSelector], (byId, id) => {
    return (
        byId[id] || {
            ...emptyCatalogRegistrationState,
            catalogId: id,
        }
    );
});

export const getIsRegistered = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.registered)
);

export const getTotalMinimumBidLimitApprovedCount = createSelector(
    catalogRegistrationSlice,
    (state) => state.totalMinimumBidLimitApprovedCount ?? 0
);

export const getRegistrationApproved = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.approved)
);

export const getRegistrationPending = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.pending)
);

export const getRegistrationSuspended = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.suspended)
);

export const getRegistrationBlocked = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.blockedByAuctioneer)
);

export const getRegistrationApprovedWithLimit = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.limited)
);

export const getRegistrationDeclined = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.declined)
);

export const getRegistrationBidLimit = createSelector(
    getCatalogRegistration,
    (catalogRegistration) => catalogRegistration?.bidLimit || 0
);

export const getAllCurrentUserCatalogRegistrationIds = createSelector(byIdSelector, (byId) =>
    Object.keys(byId).map<number>((catalogId) => Number(catalogId))
);

export const getRegistrationNotify = createSelector(getCatalogRegistration, (catalogRegistration) =>
    Boolean(catalogRegistration?.notify)
);
