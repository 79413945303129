import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { BidderProfileDataResponse } from '@/redux/modules/account/user/user.types';
import { getBidderEmail } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { postConfirmPassword } from '@/redux/modules/confirmPassword/confirmPassword.api';

export const submitConfirmPassword = createAsyncThunk<
    BidderProfileDataResponse['data'],
    string,
    AsyncThunkConfigWithRejectValue
>('la/domain/confirmPassword/submitConfirmPassword', async (password, { getState, rejectWithValue }) => {
    try {
        const state = getState();
        const userEmail = getBidderEmail(state);
        const deployment = getDeployment(state);
        const { data } = await postConfirmPassword({
            deployment,
            password,
            username: userEmail,
        });
        return data;
    } catch (error) {
        if (error.message === 'Failed to get the login.') {
            return rejectWithValue(new Error('Invalid Password'));
        } else {
            return rejectWithValue(error);
        }
    }
});
