export type ConsentSlice = {
    error: boolean;
    errorMessage: string;
    submitted: boolean;
    success: boolean;
};

export const defaultConsentSlice: ConsentSlice = {
    error: false,
    errorMessage: '',
    submitted: false,
    success: false,
};

export type SubmitConsentPendingPayload = {
    consent: boolean;
    context: string;
};
