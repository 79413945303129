export type ChangePasswordSlice = {
    error: boolean;
    errorMessage: string;
    submitted: boolean;
    success: boolean;
};

export const defaultChangePasswordSlice: ChangePasswordSlice = {
    error: false,
    errorMessage: '',
    submitted: false,
    success: false,
};

export type ChangePasswordPayload = {
    newPassword: string;
    oldPassword: string;
};
