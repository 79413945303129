import { CatalogAnnouncement } from '@/types/CatalogAnnouncement';
import {
    CatalogAnnouncementByCatalogIdResponsePayload,
    FetchCatalogAnnouncementParams,
} from '@/redux/modules/catalog/announcement/catalogAnnouncement.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import {
    fetchCatalogAnnouncementsByAnnouncementId,
    fetchCatalogAnnouncementsByCatalogId,
} from '@/redux/modules/catalog/announcement/catalogAnnouncement.api';
import {
    getCatalogAnnouncement,
    shouldFetchCatalogAnnouncement,
    shouldFetchFullCatalogAnnouncement,
} from '@/redux/modules/catalog/announcement/catalogAnnouncement.selectors';
import { getDeployment } from '@/redux/modules/config';

export const fetchCatalogAnnouncement = createAsyncThunk<
    CatalogAnnouncementByCatalogIdResponsePayload,
    FetchCatalogAnnouncementParams
>('la/ui/catalogAnnouncement/fetchCatalogAnnouncement', async ({ catalogId, houseId }, { getState }) => {
    const state = getState();
    const deployment = getDeployment(state);
    const { payload } = await fetchCatalogAnnouncementsByCatalogId({
        catalogId,
        deployment,
        houseId,
    });
    return payload;
});

export const fetchCatalogAnnouncementIfNeeded = createAsyncThunk<void, FetchCatalogAnnouncementParams>(
    'la/ui/catalogAnnouncement/fetchCatalogAnnouncementIfNeeded',
    async ({ catalogId, houseId }, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchCatalogAnnouncement(state, catalogId)) {
            await dispatch(fetchCatalogAnnouncement({ catalogId, houseId }));
        }
    }
);

export const fetchFullCatalogAnnouncement = createAsyncThunk<CatalogAnnouncement, number>(
    'la/ui/catalogAnnouncement/fetchFullCatalogAnnouncement',
    async (catalogId, { getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const { id } = getCatalogAnnouncement(state, catalogId);
        const { payload } = await fetchCatalogAnnouncementsByAnnouncementId({
            announcementId: id,
            deployment,
        });
        return payload;
    }
);

export const fetchFullCatalogAnnouncementIfNeeded = createAsyncThunk<void, number>(
    'la/ui/catalogAnnouncement/fetchFullCatalogAnnouncementIfNeeded',
    async (catalogId, { dispatch, getState }) => {
        const state = getState();
        if (shouldFetchFullCatalogAnnouncement(state, catalogId)) {
            await dispatch(fetchFullCatalogAnnouncement(catalogId));
        }
    }
);
