import {
    conversationPageActions,
    LoadConversationPageDataPayload,
} from '@/redux/modules/conversation/page/conversationPage.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchConversationFoldersIfNeeded } from '@/redux/modules/conversation/folders/conversationFolders.actions';
import { FetchConversationResponse } from '@/redux/modules/conversation/conversations/conversation.types';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { loadConversations } from '@/redux/modules/conversation/conversations/conversation.actions';

export const loadConversationPageData = createAsyncThunk<FetchConversationResponse, LoadConversationPageDataPayload>(
    'la/ui/conversationPage/loadConversationPageData',
    async ({ action, conversationIds, folderId, markOnly = false, query }, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        const response = await conversationPageActions[action]({
            authToken,
            bidderId,
            conversations: conversationIds,
            deployment,
            folderId,
        });

        await dispatch(fetchConversationFoldersIfNeeded({}));

        if (markOnly) {
            return;
        }
        await dispatch(loadConversations(query));

        return response;
    }
);
