import { ApiHosts, handleResponseWithNon200Errors, makePost } from '@/redux/api/helpers';

/**
 * @category User API
 * @see postChangePassword
 */
type PostChangePasswordParams = {
    authToken: string;
    deployment: string;
    newPassword: string;
    oldPassword: string;
};

type PostChangePasswordResponse = {
    error: boolean;
    payload: boolean;
};

/**
 * postChangePassword - updates user password
 * @category User API
 * @function postChangePassword
 * @param {PostChangePasswordParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/user/updatepassword
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/g3qvanjl6a/resources/p4t7pq/methods/POST
 */
export const postChangePassword = ({ authToken, deployment, newPassword, oldPassword }: PostChangePasswordParams) =>
    new Promise<PostChangePasswordResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: 'updatepassword',
            authToken,
            deployment,
            path: ApiHosts.FlynnUser,
        });
        request.send({ newPassword, oldPassword });
        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                request,
                resolve,
                response,
            })
        );
    });
