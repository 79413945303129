import {
    ConversationSlice,
    defaultConversationSlice,
} from '@/redux/modules/conversation/conversations/conversation.types';
import { createSlice } from '@reduxjs/toolkit';
import { loadConversation, loadConversations } from '@/redux/modules/conversation/conversations/conversation.actions';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';

const loadConversationReducer = (slice: ConversationSlice): ConversationSlice => ({
    ...slice,
    loading: true,
});

const rejectedLoadConversationReducer = (slice: ConversationSlice): ConversationSlice => ({
    ...slice,
    loading: false,
});

const conversationSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadConversations.pending, loadConversationReducer);
        builder.addCase(loadConversations.fulfilled, (slice, { payload }) => {
            const shownConversations: ConversationSlice['shownConversations'] = [];
            payload?.payload?.forEach((conversation) => {
                slice.byId[conversation.conversationId] = { ...conversation };
                shownConversations.push(conversation.conversationId);
            });
            slice.shownConversations = shownConversations;

            if (Boolean(payload?.meta?.pagination)) {
                const { pagination } = payload.meta;
                slice.pagination = {
                    page: pagination.pageNumber,
                    pageSize: pagination.pageSize,
                    totalPages: pagination.totalPages,
                    totalRecords: pagination.totalRecordCount,
                };
            }

            slice.loaded = Date.now();
            slice.loading = false;
        });
        builder.addCase(loadConversations.rejected, rejectedLoadConversationReducer);

        builder.addCase(loadConversation.pending, loadConversationReducer);
        builder.addCase(loadConversation.fulfilled, (slice, { payload }) => {
            const shownConversations: ConversationSlice['shownConversations'] = [];
            payload?.payload?.forEach((conversation) => {
                slice.byId[conversation.conversationId] = { ...conversation };
                shownConversations.push(conversation.conversationId);
            });
            slice.shownConversations = shownConversations;

            slice.loaded = Date.now();
            slice.loading = false;
        });
        builder.addCase(loadConversation.rejected, rejectedLoadConversationReducer);

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(LOG_OUT_BIDDER, () => ({
            ...defaultConversationSlice,
        }));
    },
    initialState: defaultConversationSlice,
    name: 'conversationSlice',
    reducers: {},
});

export const { reducer: conversationReducer } = conversationSlice;
