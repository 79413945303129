import { ApprovalStatus } from '@/types/Approval';
import { createCatalogRegistration, createCatalogRegistrationLegacy } from './catalogRegistration.utils';
import { createSlice } from '@reduxjs/toolkit';
import { defaultCatalogRegistrationSlice } from './catalogRegistration.types';
import { fetchBidderData } from '@/redux/modules/account/user/user.actions';
import {
    loadBidderCatalogApprovals,
    loadCatalogRegistrationsForUserById,
    loadLiveCatalogRegistrationById,
    submitAutoApproval,
    toggleCatalogNotifications,
} from './catalogRegistration.actions';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import { REGISTER_FOR_CATALOG_SUCCESS, RegisterForCatalogSuccessAction } from '../../actions';
import { submitLogin, submitLoginWithToken } from '@/redux/modules/account/login/login.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';

const catalogRegistrationSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCatalogRegistrationsForUserById.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, meta.arg.catalogIds);
        });
        builder.addCase(loadCatalogRegistrationsForUserById.fulfilled, (slice, { meta, payload }) => {
            // for each returned registration from api, append new registration object to store using `createCatalogRegistration`
            payload.forEach((registration) => {
                slice.byId[registration.catalogId] = createCatalogRegistration(
                    registration.catalogId,
                    registration.approvalStatus,
                    registration.bidLimit,
                    registration.notify
                );
            });

            // drop loaded catalogIds from loading array
            slice.loading = difference(slice.loading, meta.arg.catalogIds);
        });
        builder.addCase(loadBidderCatalogApprovals.fulfilled, (slice, { payload }) => {
            slice.totalMinimumBidLimitApprovedCount = payload.totalMinimumBidLimitApprovedCount;
        });
        builder.addCase(loadCatalogRegistrationsForUserById.rejected, (slice, { meta }) => {
            slice.loading = difference(slice.loading, meta.arg.catalogIds);
        });

        /**
         * dispatched by a catalogRegistration thunk, but also relied upon by the following:
         * @see src\redux\modules\console.ts
         * @see src\redux\modules\consoleMessages.ts
         * @see src\redux\modules\registerForCatalog.ts
         */
        builder.addCase(loadLiveCatalogRegistrationById.fulfilled, (slice, { payload }) => {
            slice.byId[payload.catalogId] = createCatalogRegistration(
                payload.catalogId,
                payload.approvalStatus,
                payload.bidLimit
            );
        });

        /**
         * @see src\redux\modules\user\user.actions.ts
         * @deprecated loading bidding history from here will be deprecated, leaving for now
         */
        builder.addCase(fetchBidderData.fulfilled, (slice, { payload }) => {
            payload.catalogRegistration.forEach((registration) => {
                slice.byId[registration.catalogId] = createCatalogRegistrationLegacy(registration);
            });
        });

        /**
         * @see src\redux\modules\registerForCatalog.ts
         */
        builder.addCase<typeof REGISTER_FOR_CATALOG_SUCCESS, RegisterForCatalogSuccessAction>(
            REGISTER_FOR_CATALOG_SUCCESS,
            (slice, { payload }) => {
                slice.byId[payload.catalogId] = createCatalogRegistration(
                    payload.catalogId,
                    payload.approvalStatus,
                    payload.bidLimit
                );
            }
        );

        /**
         * The login api will soon be deprecating the bidding history and will be returned by a new api
         * @deprecated
         */
        builder.addCase(submitLogin.fulfilled, (slice, { payload }) => {
            // append bidding catalog registrations from login
            payload.catalogRegistration.forEach((registration) => {
                slice.byId[registration.catalogId] = createCatalogRegistrationLegacy(registration);
            });
        });

        /**
         * The login api will soon be deprecating the bidding history and will be returned by a new api
         * @deprecated
         */
        builder.addCase(submitLoginWithToken.fulfilled, (slice, { payload }) => {
            // append bidding catalog registrations from login
            payload.catalogRegistration.forEach((registration) => {
                slice.byId[registration.catalogId] = createCatalogRegistrationLegacy(registration);
            });
        });

        /**
         * @see src\redux\modules\catalogRegistration\catalogRegistration.actions.ts
         */
        builder.addCase(submitAutoApproval.fulfilled, (slice, { payload }) => {
            // only update redux if the auto approval returns an ApprovalStatus of approved or limited
            // the other statuses are not accurate statuses on this auto approval endpoint
            if (
                payload.approvalStatus === ApprovalStatus.Approved ||
                payload.approvalStatus === ApprovalStatus.Limited
            ) {
                slice.byId[payload.catalogId] = createCatalogRegistration(
                    payload.catalogId,
                    payload.approvalStatus,
                    payload.bidLimit
                );
            }
        });
        builder.addCase(submitAutoApproval.rejected, (slice, { meta }) => {
            slice.loading = [meta.arg.catalogId];
        });

        /**
         * @see src\redux\modules\catalog\registration\catalogRegistration.actions.ts
         */
        builder.addCase(toggleCatalogNotifications.fulfilled, (slice, { payload }) => {
            slice.byId[payload.catalogId] = payload.catalogRegistration;
        });

        // Logout, clear state
        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(
            LOG_OUT_BIDDER,
            () => defaultCatalogRegistrationSlice
        );
    },
    initialState: defaultCatalogRegistrationSlice,
    name: 'catalogRegistration',
    reducers: {},
});

export const { reducer: catalogRegistrationReducer } = catalogRegistrationSlice;
