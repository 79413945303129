import { CatalogAnnouncement } from '@/types/CatalogAnnouncement';
import ms from 'ms';

export const CATALOG_ANNOUNCEMENT_SLICE_CACHE_TIME = ms('10m');

export type CatalogAnnouncementByCatalogIdResponsePayload = Omit<CatalogAnnouncement, 'body'> & {
    catalogId: number;
    houseId: number;
    lastModifiedTs: number;
};

export type CatalogAnnouncementSlice = {
    activeAnnouncement: number;
    byId: {
        [catalogId: number]: CatalogAnnouncement | CatalogAnnouncementByCatalogIdResponsePayload;
    };
    loaded: { [catalogId: number]: number };
    loading: number[];
};

export const defaultCatalogAnnouncementSlice: CatalogAnnouncementSlice = {
    activeAnnouncement: 0,
    byId: {},
    loaded: {},
    loading: [],
};

export type FetchCatalogAnnouncementParams = {
    catalogId: number;
    houseId: number;
};
