import {
    CatalogAnnouncementSlice,
    defaultCatalogAnnouncementSlice,
} from '@/redux/modules/catalog/announcement/catalogAnnouncement.types';
import { createSlice } from '@reduxjs/toolkit';
import {
    DISMISS_MODAL,
    DISMISS_MODAL_ACTION,
    OPEN_CATALOG_ANNOUNCEMENT_MODAL,
    OpenCatalogAnnouncementModalAction,
} from '../../actions';
import {
    fetchCatalogAnnouncement,
    fetchFullCatalogAnnouncement,
} from '@/redux/modules/catalog/announcement/catalogAnnouncement.actions';
import { FulfilledActionFromAsyncThunk } from '@/redux/createAsyncThunk';
import { safelyEscapeText } from '@/utils/safelyRender';
import difference from 'lodash/difference';
import union from 'lodash/union';

const pendingAnnouncementReducer = (slice: CatalogAnnouncementSlice, catalogId: number): CatalogAnnouncementSlice => ({
    ...slice,
    loading: union(slice.loading, [catalogId]),
});

const fulfilledAnnouncementReducer = (
    slice: CatalogAnnouncementSlice,
    catalogId: number,
    announcement:
        | FulfilledActionFromAsyncThunk<typeof fetchCatalogAnnouncement>['payload']
        | FulfilledActionFromAsyncThunk<typeof fetchFullCatalogAnnouncement>['payload']
): CatalogAnnouncementSlice => ({
    ...slice,
    byId: {
        ...slice.byId,
        [catalogId]: {
            ...announcement,
            title: safelyEscapeText(announcement.title),
        },
    },
    loaded: {
        ...slice.loaded,
        [catalogId]: Date.now(),
    },
    loading: difference(slice.loading, [catalogId]),
});

const rejectedAnnouncementReducer = (slice: CatalogAnnouncementSlice, catalogId: number): CatalogAnnouncementSlice => ({
    ...slice,
    loading: difference(slice.loading, [catalogId]),
});

const catalogAnnouncementSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(fetchCatalogAnnouncement.pending, (slice, { meta }) =>
            pendingAnnouncementReducer(slice, meta.arg.catalogId)
        );
        builder.addCase(fetchCatalogAnnouncement.fulfilled, (slice, { meta, payload }) =>
            fulfilledAnnouncementReducer(slice, meta.arg.catalogId, payload)
        );
        builder.addCase(fetchCatalogAnnouncement.rejected, (slice, { meta }) =>
            rejectedAnnouncementReducer(slice, meta.arg.catalogId)
        );

        builder.addCase(fetchFullCatalogAnnouncement.pending, (slice, { meta }) =>
            pendingAnnouncementReducer(slice, meta.arg)
        );
        builder.addCase(fetchFullCatalogAnnouncement.fulfilled, (slice, { meta, payload }) =>
            fulfilledAnnouncementReducer(slice, meta.arg, payload)
        );
        builder.addCase(fetchFullCatalogAnnouncement.rejected, (slice, { meta }) =>
            rejectedAnnouncementReducer(slice, meta.arg)
        );

        builder.addCase<typeof OPEN_CATALOG_ANNOUNCEMENT_MODAL, OpenCatalogAnnouncementModalAction>(
            OPEN_CATALOG_ANNOUNCEMENT_MODAL,
            (slice, { payload }) => ({
                ...slice,
                activeAnnouncement: payload.catalogId,
            })
        );
        builder.addCase<typeof DISMISS_MODAL, DISMISS_MODAL_ACTION>(DISMISS_MODAL, (slice) => ({
            ...slice,
            activeAnnouncement: 0,
        }));
    },
    initialState: defaultCatalogAnnouncementSlice,
    name: 'catalogAnnouncement',
    reducers: {},
});

export const { reducer: catalogAnnouncementReducer } = catalogAnnouncementSlice;
