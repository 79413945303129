import { ReduxCategory } from '@/types/categories/categories';
import ms from 'ms';

export const CATEGORY_REDUX_STORE_TIME = ms('30m');

export type CategorySlice = {
    byId: { [id: number]: ReduxCategory };
    // TODO: Currently, we're loading the array of categories onto the loaded value.
    //  This is different from all our other slices, where loaded is the time the category was loaded, and byId is the actual value.
    //  To avoid rewriting the entire repo around the category slice, I'm leaving this as type any[], and this should be fixed in a later op story.
    loaded: { [id: number]: unknown };
    loading: number[];
};

export const defaultCategorySlice: CategorySlice = {
    byId: {},
    loaded: {},
    loading: [],
};
