import { ApprovalStatus } from '@/types/Approval';
import { CatalogRegistration } from './catalogRegistration.types';
import { CatalogRegistrationLegacyServerResponse } from '@/types/CatalogRegistration';

export const createCatalogRegistration = (
    catalogId: number,
    approvalStatus: ApprovalStatus,
    bidLimit = 0,
    notify = false
): CatalogRegistration => ({
    approved: approvalStatus === ApprovalStatus.Approved,
    bidLimit: bidLimit,
    blockedByAuctioneer: approvalStatus === ApprovalStatus.BlockedByAuctioneer,
    catalogId: catalogId,
    declined: approvalStatus === ApprovalStatus.Declined,
    limited: approvalStatus === ApprovalStatus.Limited,
    notify: notify,
    pending: approvalStatus === ApprovalStatus.Pending,
    registered: approvalStatus !== ApprovalStatus.None,
    suspended: approvalStatus === ApprovalStatus.Suspended,
});

export const createCatalogRegistrationLegacy = ({
    approved,
    bidLimit,
    blockedByAuctioneer,
    catalogId,
    declined,
    limited,
    pending,
    suspended,
}: CatalogRegistrationLegacyServerResponse): CatalogRegistration => ({
    approved,
    bidLimit,
    blockedByAuctioneer,
    catalogId,
    declined,
    limited,
    notify: false,
    pending,
    registered: !blockedByAuctioneer && !declined && !suspended,
    suspended,
});
