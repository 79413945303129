import { CATEGORY_REDUX_STORE_TIME } from '@/redux/modules/category/category.types';
import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '@/redux/store';
import { ReduxCategory } from '@/types/categories/categories';

const stateSelector = (state: GlobalState) => state;
const categorySlice = createSelector(stateSelector, (state) => state.category);

const idSelector = (_: GlobalState, id: number | number[]) => id;

export const getCategoryEntries = createSelector(categorySlice, (state) => state.byId);

export const getCategoryLoaded = createSelector(categorySlice, (state) => state.loaded);

const loadingSelector = createSelector(categorySlice, (state) => state.loading);

export const getCategory = createSelector(
    [getCategoryEntries, idSelector],
    (byId, id: number) => byId[id] || ({} as ReduxCategory)
);

export const whichCategoriesNeeded = createSelector(
    [getCategoryLoaded, loadingSelector, idSelector],
    (loaded, loading, categoryIds: number[]) => {
        const needed = [];
        categoryIds.forEach((categoryId: number) => {
            if (categoryId) {
                const time = Date.now();
                const diff = time - (loaded[categoryId] as number);
                if (diff < CATEGORY_REDUX_STORE_TIME) {
                    return false;
                }
                if (!loading.includes(categoryId)) {
                    needed.push(categoryId);
                }
            }
        });
        return needed;
    }
);
