import { createSlice } from '@reduxjs/toolkit';
import { defaultConfirmPasswordSlice } from '@/redux/modules/confirmPassword/confirmPassword.types';
import { submitConfirmPassword } from '@/redux/modules/confirmPassword/confirmPassword.actions';

const confirmPasswordSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(submitConfirmPassword.pending, (slice) => {
            slice.error = false;
            slice.errorMessage = null;
            slice.submitted = true;
            slice.success = false;
        });
        builder.addCase(submitConfirmPassword.fulfilled, (slice) => {
            slice.submitted = false;
            slice.success = true;
        });
        builder.addCase(submitConfirmPassword.rejected, (slice, { payload }) => {
            slice.error = true;
            slice.errorMessage = payload.message;
            slice.submitted = false;
        });
    },
    initialState: defaultConfirmPasswordSlice,
    name: 'confirmPassword',
    reducers: {},
});

export const { reducer: confirmPasswordReducer } = confirmPasswordSlice;
