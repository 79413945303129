import { AsyncThunkConfigWithRejectValue, createAsyncThunk } from '@/redux/createAsyncThunk';
import { CheckoutInvoice, Invoice } from '@/types/Invoice';
import { EventTypes } from 'redux-segment';
import {
    FetchInvoicePayload,
    ReopenInvoicePayload,
    SubmitShippingMethodPayload,
    TrackCheckoutProgress,
} from '@/redux/modules/checkout/checkout.types';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import {
    getCatalogBillingCheckout,
    getCatalogPaymentProvider,
    getCatalogSellerId,
} from '../catalog/catalogs/catalog.selectors';
import { getDeployment } from '@/redux/modules/config';
import { getInvoice, postReopenPaidInvoice, postShippingMethod } from './checkout.api';
import {
    getInvoiceById,
    invoiceInitialLoadSelector,
    shouldFetchInvoice,
} from '@/redux/modules/checkout/checkout.selectors';

export const fetchInvoice = createAsyncThunk<CheckoutInvoice, number, AsyncThunkConfigWithRejectValue>(
    'la/domain/checkout/fetchInvoice',
    async (catalogId, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const bidderId = getBidderId(state);
            const billingCheckout = getCatalogBillingCheckout(state, catalogId);

            if (!Boolean(bidderId) || bidderId < 0) {
                return null;
            }

            const { data } = await getInvoice({
                authToken,
                bidderId,
                billingCheckout,
                catalogId,
                deployment,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchInvoiceIfNeeded = createAsyncThunk<void, FetchInvoicePayload>(
    'la/domain/checkout/fetchInvoiceIfNeeded',
    async ({ catalogId, force = false }, { dispatch, getState }) => {
        const state = getState();
        if (force || shouldFetchInvoice(state, catalogId)) {
            await dispatch(fetchInvoice(catalogId));
        }
    }
);

export const reopenPaidInvoice = createAsyncThunk<Partial<Invoice>, ReopenInvoicePayload>(
    'la/domain/checkout/reopenPaidInvoice',
    async ({ catalogId, invoiceId, shippingAddressId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);

            if (!Boolean(bidderId) || bidderId < 0) {
                return null;
            }

            const { payload } = await postReopenPaidInvoice({
                authToken,
                bidderId,
                deployment,
                invoiceId,
                shippingAddressId,
            });

            await dispatch(fetchInvoice(catalogId));

            return payload;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const submitShippingMethod = createAsyncThunk<void, SubmitShippingMethodPayload>(
    'la/domain/checkout/submitShippingMethod',
    async ({ invoiceId, shippingMethod }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);
        await postShippingMethod({
            authToken,
            bidderId,
            deployment,
            invoiceId,
            shippingMethod,
        });
    }
);

export const trackCheckoutProgress = createAsyncThunk<void, TrackCheckoutProgress>(
    'la/domain/checkout/trackProgress',
    async (
        {
            achMethodAvailable,
            addedNewPayment,
            addressValidated,
            cardMethodAvailable,
            catalogId,
            event,
            hasSavedCard,
            paymentFail,
            paymentFailureReason,
            paymentMethod,
            shippingMethod,
            step,
            zipMethodAvailable,
        },
        { dispatch, getState }
    ) => {
        const state = getState();
        const { invoice, items } = getInvoiceById(state, catalogId);
        const loaded = invoiceInitialLoadSelector(state);
        const bidderId = getBidderId(state);
        const houseId = getCatalogSellerId(state, catalogId);
        const paymentProvider = getCatalogPaymentProvider(state, catalogId);
        await dispatch({
            meta: {
                analytics: {
                    eventPayload: {
                        event,
                        properties: {
                            achMethodAvailable,
                            addedNewPayment,
                            addressValidated,
                            bidderId: bidderId,
                            cardMethodAvailable,
                            catalogId,
                            checkoutId: `${invoice.id}-${loaded}`, //unique for the entire spa lifetime, until the redux refreshes
                            currency: invoice.currency,
                            hasSavedCard,
                            houseId: houseId,
                            invoiceId: invoice.id,
                            marketplaceCode: 'LA',
                            order_id: invoice.id,
                            paymentFail,
                            paymentFailureReason,
                            paymentMethod,
                            paymentProvider: paymentProvider,
                            products: items.map((item) => ({
                                brand: item.sellerName,
                                name: item.title,
                                price: item.salePrice,
                                product_id: item.itemId,
                                url: `/item/${item.itemId}`,
                                // category -- ???
                            })),
                            shipping: invoice.shipping,
                            shippingMethod,
                            step,
                            subtotal: invoice.itemTotal + invoice.buyersPremium,
                            tax: invoice.salesTax,
                            total: invoice.invoiceTotal,
                            zipMethodAvailable,
                        },
                    },
                    eventType: EventTypes.track,
                },
            },
            type: event,
        });
    }
);
