import { createSelector, Reducer } from '@reduxjs/toolkit';
import { GlobalState } from '../store';
import { handleActions } from 'redux-actions';
import { HYDRATE_CLIENT_STATE } from './actions';

export type ClientSlice = {
    hydrated: boolean;
};

export const defaultClientSlice: ClientSlice = {
    hydrated: false,
};

export const clientReducer: Reducer<ClientSlice> = handleActions(
    {
        [HYDRATE_CLIENT_STATE]: (state: ClientSlice) => ({
            ...state,
            hydrated: true,
        }),
    },
    defaultClientSlice
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const clientSlice = createSelector(stateSelector, (state) => state.client);

export const getClientHydrated = createSelector(clientSlice, ({ hydrated }) => hydrated);

type HydrateClientState = {
    dateLastShown: string;
    disableModalForTesting: boolean;
    hasLoggedInBefore: boolean;
    pageSize: number | 'all';
};

/* ACTION CREATORS */
export const hydrateClientState = (params: HydrateClientState) => ({
    payload: { ...params },
    type: HYDRATE_CLIENT_STATE,
});
