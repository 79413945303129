import {
    ADD_LA_PAYMENT_CARD_FAIL,
    ADD_LA_PAYMENT_CARD_REQUEST,
    ADD_LA_PAYMENT_CARD_SUCCESS,
    ADD_ZIP_PAYMENT_CARD_FAIL,
    ADD_ZIP_PAYMENT_CARD_REQUEST,
    ADD_ZIP_PAYMENT_CARD_SUCCESS,
    CARD_REAUTH_SUCCESS,
    CLEAR_CARD_ERRORS,
    DISMISS_MODAL,
    LOAD_LA_PAYMENT_CARD_FAIL,
    LOAD_LA_PAYMENT_CARD_REQUEST,
    LOAD_LA_PAYMENT_CARD_SUCCESS,
    UPDATE_LA_PAYMENT_CARD_FAIL,
    UPDATE_LA_PAYMENT_CARD_REQUEST,
    UPDATE_LA_PAYMENT_CARD_SUCCESS,
} from './actions';
import { Address } from '@liveauctioneers/caterwaul-components/types/Address';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { CreditCard } from '@liveauctioneers/caterwaul-components/types/CreditCard';
import { fetchBidderDataIfNeeded } from '@/redux/modules/account/user/user.actions';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { getLAPaymentCards, postAddLACard, postAddZipCard, postUpdateLACard } from '@/redux/api/payment';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import { PaymentProviders } from '@/enums';
import ms from 'ms';

const REDUX_STORE_TIME = ms('20m');
const LOADING_TIME = ms('20s');

// reducer
export type CreditCardSlice = {
    addSubmitted: boolean;
    addSuccess: boolean;
    allowedToAddCards: boolean;
    error: string;
    laPaymentCards: CreditCard[];
    loaded: number;
    loading: number;
    updateSubmitted: boolean;
    updateSuccess: boolean;
    zipCard: CreditCard | null;
};

export const defaultCreditCardSlice: CreditCardSlice = {
    addSubmitted: false,
    addSuccess: false,
    allowedToAddCards: true,
    error: '',
    laPaymentCards: [],
    loaded: 0,
    loading: 0,
    updateSubmitted: false,
    updateSuccess: false,
    zipCard: null,
};

export default function reducer(state = defaultCreditCardSlice, action: any = {}): CreditCardSlice {
    switch (action.type) {
        case CLEAR_CARD_ERRORS:
            return {
                ...state,
                error: '',
            };
        case ADD_LA_PAYMENT_CARD_REQUEST:
            return {
                ...state,
                addSubmitted: true,
                addSuccess: false,
                error: '',
                loading: 0,
            };
        case ADD_LA_PAYMENT_CARD_SUCCESS:
            return {
                ...state,
                addSubmitted: false,
                addSuccess: true,
                loaded: 0,
            };
        case ADD_ZIP_PAYMENT_CARD_REQUEST:
            return {
                ...state,
                addSubmitted: true,
                addSuccess: false,
                error: '',
            };
        case ADD_ZIP_PAYMENT_CARD_SUCCESS:
            return {
                ...state,
                addSubmitted: false,
                addSuccess: true,
                zipCard: action.payload,
            };
        case LOAD_LA_PAYMENT_CARD_REQUEST:
            return {
                ...state,
                loaded: 0,
                loading: action.meta.actionTime,
            };
        case LOAD_LA_PAYMENT_CARD_SUCCESS:
            return {
                ...state,
                allowedToAddCards: action?.payload?.allowedToAddCards,
                error: '',
                laPaymentCards: action?.payload?.cards,
                loaded: action.meta.actionTime,
                loading: 0,
            };
        case ADD_LA_PAYMENT_CARD_FAIL:
        case ADD_ZIP_PAYMENT_CARD_FAIL:
        case LOAD_LA_PAYMENT_CARD_FAIL:
        case UPDATE_LA_PAYMENT_CARD_FAIL:
            return {
                ...state,
                addSubmitted: false,
                addSuccess: false,
                error: action.payload,
                loading: 0,
                updateSubmitted: false,
            };
        case UPDATE_LA_PAYMENT_CARD_REQUEST:
            return {
                ...state,
                error: '',
                updateSubmitted: true,
                updateSuccess: false,
            };
        case UPDATE_LA_PAYMENT_CARD_SUCCESS:
            return {
                ...state,
                error: '',
                loaded: 0,
                updateSubmitted: false,
                updateSuccess: false,
            };
        case DISMISS_MODAL:
            return {
                ...state,
                addSubmitted: false,
                addSuccess: false,
                error: '',
                loading: 0,
            };
        case LOG_OUT_BIDDER:
            return defaultCreditCardSlice;
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const creditCardSelector = createSelector(stateSelector, (state) => state.creditCard);

const passthroughSelector = (_: GlobalState, id: number) => id;

export const getAllowedToAddCards = createSelector([creditCardSelector], (state) => state.allowedToAddCards);
export const getLAPaymentCardsLoaded = createSelector([creditCardSelector], (state) => state.loaded);
export const getLAPaymentCardsLoading = createSelector([creditCardSelector], (state) => state.loading);
export const getLAPaymentCardsAddSubmitted = createSelector([creditCardSelector], (state) => state.addSubmitted);
export const getLAPaymentCardsAddSuccess = createSelector([creditCardSelector], (state) => state.addSuccess);
export const getLAPaymentCardsUpdateSubmitted = createSelector([creditCardSelector], (state) => state.updateSubmitted);
export const getLAPaymentCardsUpdateSuccess = createSelector([creditCardSelector], (state) => state.updateSuccess);
export const getCreditCardError = createSelector([creditCardSelector], (state) => state.error);
export const getZipCard = createSelector([creditCardSelector], (state) => state.zipCard);

export const creditCardsAnyLoadingSelector = createSelector(
    [getLAPaymentCardsLoading, getLAPaymentCardsAddSubmitted, getLAPaymentCardsUpdateSubmitted],
    (loading, addSubmitted, updateSubmitted) => Boolean(loading) || addSubmitted || updateSubmitted
);

/**
 * returns all cards on file
 * @function laPaymentCardsSelector
 * @category Credit Card Service
 * @param {GlobalState}, number
 */
export const laPaymentCardsSelector = createSelector([creditCardSelector], (state) => state.laPaymentCards);

export const laPaymentCardsForProviderSelector = createSelector(
    [laPaymentCardsSelector, passthroughSelector],
    (allLaPaymentCards, providerId) => allLaPaymentCards?.filter((cof) => cof.providerId === providerId)
);

/**
 * returns first matching card on file that is a defaultCard and matches providerId
 * @function laPaymentDefaultCardSelector
 * @category Credit Card Service
 * @param {GlobalState}, number
 */
export const laPaymentDefaultCardSelector = createSelector(
    [laPaymentCardsSelector, passthroughSelector],
    (laPaymentCards, providerId): CreditCard => {
        const defaultCard = laPaymentCards?.find((card) => card.defaultCard && card.providerId === providerId);

        return Boolean(defaultCard)
            ? defaultCard
            : {
                  address: {
                      address1: '',
                      city: '',
                      countryCode: '',
                      postalCode: '',
                  },
                  brand: 'other',
                  customerId: '',
                  id: 0,
                  last4: '',
                  month: 0,
                  name: '',
                  providerId: 0,
                  token: '',
                  year: 0,
              };
    }
);

export const laPaymentDefaultCardId = createSelector(laPaymentDefaultCardSelector, ({ id }) => id || 0);

//TODO: Should eventually check based on the authed card status.
export const getHasValidCardOnFile = createSelector([laPaymentDefaultCardSelector], (laPaymentCard) =>
    Boolean(laPaymentCard?.token)
);

export const getShouldCollectBillingAddress = (state: any, paymentProvider: number) => {
    const creditCard = laPaymentDefaultCardSelector(state, paymentProvider);
    return Boolean(creditCard) && !Boolean(creditCard?.address.address1);
};

/* ACTION CREATORS */
export type SubmitAddCreditCardParams = {
    address: Address;
    cardToken: string;
    cardholderName: { first: string; last: string };
    houseId: number;
    providerId: PaymentProviders;
};
export const submitAddCreditCard =
    ({
        address,
        cardholderName,
        cardToken,
        houseId,
        providerId = PaymentProviders.Payrix,
    }: SubmitAddCreditCardParams) =>
    async (dispatch: AppDispatch) => {
        return await dispatch(
            addLACreditCard({
                address,
                cardholderName,
                cardToken,
                houseId,
                providerId,
            })
        );
    };

type AddLACreditCardParams = {
    address: Address;
    cardToken: string;
    cardholderName: { first: string; last: string };
    houseId: number;
    providerId: number;
};
//add is for creating credit cards for all providers.
const addLACreditCard =
    ({ address, cardholderName, cardToken, houseId, providerId }: AddLACreditCardParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);
            dispatch({
                payload: bidderId,
                type: ADD_LA_PAYMENT_CARD_REQUEST,
            });
            const response = await postAddLACard({
                authToken,
                bidderId,
                billingAddress: address,
                cardholderName: `${cardholderName.first} ${cardholderName.last}`,
                cardNonce: cardToken,
                deployment,
                houseId,
                providerId,
            });

            // add card id to list of auth success ids
            dispatch({
                payload: response.payload.id,
                type: CARD_REAUTH_SUCCESS,
            });

            // Update user profile
            await dispatch(fetchBidderDataIfNeeded({ authToken, force: true }));

            // dispatch success after so we show the throbber the whole time it is loading new data.
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: ADD_LA_PAYMENT_CARD_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: ADD_LA_PAYMENT_CARD_FAIL,
            });
            //reject the promise specifically because if it resolves the error doesn't appear in the registration modal.
            return Promise.reject();
        }
    };

export type AddZipCreditCardParams = {
    billingAddress: Address;
    brand: string;
    firstName: string;
    houseId: number;
    lastName: string;
    month: number;
    number: string;
    providerId: number;
    securityCode: string;
    year: string;
};
//add is for creating new zip cards.
export const addZipCard =
    ({
        billingAddress,
        brand,
        firstName,
        houseId,
        lastName,
        month,
        number,
        providerId,
        securityCode,
        year,
    }: AddZipCreditCardParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);
            dispatch({
                payload: bidderId,
                type: ADD_ZIP_PAYMENT_CARD_REQUEST,
            });
            const response = await postAddZipCard({
                authToken,
                bidderId,
                billingAddress,
                brand,
                deployment,
                firstName,
                houseId,
                lastName,
                month,
                number,
                providerId,
                securityCode,
                year,
            });

            dispatch({
                meta: { actionTime: Date.now() },
                payload: response.payload,
                type: ADD_ZIP_PAYMENT_CARD_SUCCESS,
            });

            return Promise.resolve(response.payload);
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: ADD_ZIP_PAYMENT_CARD_FAIL,
            });
        }
    };

type UpdateLACreditCardParams = {
    billingAddress?: Address;
    cardId: number;
    defaultCard: boolean;
    enabled: boolean;
};
//Update is for updating the billing address of an existing card. The api can be used for disabling cards too, but the ui has no hookup for that.
export const updateLACreditCard =
    ({ billingAddress, cardId, defaultCard, enabled }: UpdateLACreditCardParams) =>
    async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const bidderId = getBidderId(state);
            const deployment = getDeployment(state);
            dispatch({
                payload: bidderId,
                type: UPDATE_LA_PAYMENT_CARD_REQUEST,
            });

            // Send the request
            const response = await postUpdateLACard({
                authToken,
                bidderId,
                billingAddress,
                cardId,
                defaultCard,
                deployment,
                enabled,
            });
            await dispatch(fetchLAPaymentCard());
            dispatch({
                payload: response.payload,
                type: UPDATE_LA_PAYMENT_CARD_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                payload: error,
                type: UPDATE_LA_PAYMENT_CARD_FAIL,
            });
        }
    };

export const fetchLAPaymentCard =
    (userId?: number, token?: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        try {
            const authToken = getAuthToken(state);
            const storedBidderId = getBidderId(state);
            const deployment = getDeployment(state);

            // if bidderId is less than 1 the bidder data hasnt loaded yet and we can return.
            if (storedBidderId < 1 && !Boolean(userId)) {
                return Promise.resolve();
            }

            dispatch({
                meta: { actionTime: Date.now() },
                type: LOAD_LA_PAYMENT_CARD_REQUEST,
            });
            const response = await getLAPaymentCards({
                authToken: token || authToken,
                bidderId: userId || storedBidderId,
                deployment,
            });
            return dispatch({
                meta: { actionTime: Date.now() },
                payload: response?.payload,
                type: LOAD_LA_PAYMENT_CARD_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { actionTime: Date.now() },
                payload: error,
                type: LOAD_LA_PAYMENT_CARD_FAIL,
            });
        }
    };

const shouldFetchLAPaymentCards = createSelector(
    getLAPaymentCardsLoaded,
    getLAPaymentCardsLoading,
    (loaded: number, loading: number): boolean => {
        const time = Date.now();
        if (loading > 0) {
            //BUGS-3914 && BUGS-4213 - sometimes loading was stuck as true in the redux state. Changing it to a number and setting a time to retry so we never get stuck without cards loading.
            const diff = time - loaded;
            return diff > LOADING_TIME;
        }
        const diff = time - loaded;
        return !Boolean(loaded) || diff > REDUX_STORE_TIME;
    }
);

export const fetchLAPaymentCardIfNeeded =
    (bidderId?: number, token?: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        if (shouldFetchLAPaymentCards(state)) {
            await dispatch(fetchLAPaymentCard(bidderId, token));
        }
        return Promise.resolve();
    };
