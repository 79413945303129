import { AdUnit } from './catalogAd.types';
import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';

/**
 * @category Item API
 * @see fetchCatalogAd
 */
type FetchCatalogAdResponse = {
    data: {
        ads: AdUnit[];
    };
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see fetchCatalogAd
 */
type FetchCatalogAdParams = {
    categoryIds: any;
    deployment: string;
    keywords: any;
    pageName: any;
};

/**
 * POST request to item-api service that sends categoryIds, keywords and pageName and returns advertisement data
 * @function fetchCatalogAd
 * @category Item API
 * @param {FetchCatalogAdParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/ads/page
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/react-site/routes/get-spa-ad-units.ts
 */
export const fetchCatalogAd = ({ categoryIds, deployment, keywords, pageName }: FetchCatalogAdParams) =>
    new Promise<FetchCatalogAdResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/ads/page',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                categoryIds,
                keywords,
                pageName,
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
