import { CheckoutInvoice } from '@/types/Invoice';
import { CheckoutProcessStep } from '@/pages/Checkout/components/Steps/types/CheckoutProcessStep.enum';
import { PaymentMethods } from '@/enums';
import { ShippingMethods } from '@/enums/shippingMethod';
import ms from 'ms';

export const CHECKOUT_REDUX_STORE_TIME = ms('30m');

export type CheckoutSlice = {
    addedPaymentMethodOnCheckout: boolean;
    byId: { [catalogId: number]: CheckoutInvoice };
    // we don't have any guarantee of what type error is
    error: { [catalogId: number]: unknown };
    //Used as an reference number. The invoice gets re-loaded as we go through checkout, so its loaded time can change. This only resets if the state clears(ie, refresh)
    initialLoad: number;
    loaded: { [catalogId: number]: number };
    loading: number[];
};

export const defaultCheckoutSlice: CheckoutSlice = {
    addedPaymentMethodOnCheckout: false,
    byId: {},
    error: {},
    initialLoad: 0,
    loaded: {},
    loading: [],
};

export type FetchInvoicePayload = {
    catalogId: number;
    force?: boolean;
};

export type ReopenInvoicePayload = {
    catalogId: number;
    invoiceId: number;
    shippingAddressId: number;
};

export type SubmitShippingMethodPayload = {
    invoiceId: number;
    shippingMethod: ShippingMethods;
};

export enum CheckoutProgressEvent {
    ConfirmationLoaded = 'Checkout Confirmation Loaded',
    Loaded = 'Checkout Started',
    PaymentComplete = 'Order Completed',
    StepComplete = 'Checkout Step Completed',
    StepStarted = 'Checkout Step Viewed', //when loading a previously paid checkout, this is the only event that should be fired.
}

/* eslint-disable typescript-sort-keys/interface */
export type TrackCheckoutProgress = {
    catalogId: number;
    event: CheckoutProgressEvent;
    step?: CheckoutProcessStep;

    addressValidated?: boolean; //shipping address step

    shippingMethod?: ShippingMethods; //shipping method step

    achMethodAvailable?: boolean; //payment step
    addedNewPayment?: boolean; //payment step
    cardMethodAvailable?: boolean; //payment step
    hasSavedCard?: boolean; //payment step
    paymentMethod?: PaymentMethods; //payment step
    zipMethodAvailable?: boolean; //payment step

    paymentFail?: boolean; //payment submit
    paymentFailureReason?: string; //payment submit
};
/* eslint-enable typescript-sort-keys/interface */
