import { createSlice } from '@reduxjs/toolkit';
import { defaultCatalogDetailSlice } from './catalogDetail.types';
import { loadCatalogDetails } from '@/redux/modules/catalog/detail/catalogDetail.actions';
import { splitCustomTerms } from '@/redux/modules/catalog/detail/catalogDetail.utils';
import difference from 'lodash/difference';
import union from 'lodash/union';

const catalogDetailSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadCatalogDetails.pending, (state, { meta }) => {
            state.loading = union(state.loading, meta.arg);
        });
        builder.addCase(loadCatalogDetails.fulfilled, (state, { payload }) => {
            const { actionTime, data } = payload;
            data.forEach((catalogDetail) => {
                state.byId[catalogDetail.catalogId] = {
                    bidderArrangedShipping: catalogDetail.bidderArrangedShipping,
                    catalogId: catalogDetail.catalogId,
                    containsBuyNowItems: catalogDetail.containsBuyNowItems,
                    curators: catalogDetail.curators,
                    externalUrl: catalogDetail.externalUrl,
                    freeLocalPickup: catalogDetail.freeLocalPickup,
                    hasWeapons: catalogDetail.hasWeapons,
                    inHouseShipping: catalogDetail.inHouseShipping,
                    streamId: catalogDetail.streamId || 0,
                    terms: catalogDetail.terms,
                    ...splitCustomTerms(catalogDetail.customTerms),
                };
                state.loaded[catalogDetail.catalogId] = actionTime;
                state.loading = difference(state.loading, [catalogDetail.catalogId]);
            });
        });
        builder.addCase(loadCatalogDetails.rejected, (state, { meta }) => {
            state.loading = difference(state.loading, meta.arg);
        });
    },
    initialState: defaultCatalogDetailSlice,
    name: 'catalogDetail',
    reducers: {},
});

export const { reducer: catalogDetailReducer } = catalogDetailSlice;
