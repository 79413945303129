import { createSelector } from '@reduxjs/toolkit';
import { emptyCatalogDetail } from '@/types/CatalogDetail';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const catalogDetailSlice = createSelector(stateSelector, (state) => state.catalogDetail);

const idSelector = (_: GlobalState, id: number) => id;

const byIdSelector = createSelector(catalogDetailSlice, (state) => state.byId);

const loadedSelector = createSelector(catalogDetailSlice, (state) => state.loaded);

const loadingSelector = createSelector(catalogDetailSlice, (state) => state.loading);

export const getCatalogDetail = createSelector(
    [byIdSelector, idSelector],
    (byId, id) => byId[id] || emptyCatalogDetail
);

export const getLoadTimeForCatalogDetail = createSelector(
    [loadedSelector, idSelector],
    (loaded, id) => loaded[id] || 0
);

export const isCatalogDetailLoading = createSelector([loadingSelector, idSelector], (loading, id) =>
    loading.includes(id)
);

export const getIsCatalogDetailLoaded = createSelector([loadedSelector, idSelector], (loaded, id) =>
    loaded.hasOwnProperty(id)
);

export const getCatalogDetailFreeLocalPickup = createSelector(
    getCatalogDetail,
    ({ freeLocalPickup }) => freeLocalPickup
);

export const getCatalogDetailContainsBuyNowItems = createSelector(getCatalogDetail, ({ containsBuyNowItems }) =>
    Boolean(containsBuyNowItems)
);

export const getCatalogDetailCurators = createSelector(getCatalogDetail, ({ curators }) => curators || []);

export const getCatalogDetailInHouseShipping = createSelector(getCatalogDetail, ({ inHouseShipping }) =>
    Boolean(inHouseShipping)
);

export const getCatalogDetailHasBidderShippingAvailable = createSelector(
    getCatalogDetail,
    ({ bidderArrangedShipping }) => Boolean(bidderArrangedShipping)
);

export const getCatalogDetailPaymentDetails = createSelector(
    getCatalogDetail,
    ({ paymentDetails }) => paymentDetails || ''
);

export const getCatalogDetailShippingDetails = createSelector(
    getCatalogDetail,
    ({ shippingDetails }) => shippingDetails || ''
);

export const getCatalogDetailExternalUrl = createSelector(getCatalogDetail, ({ externalUrl }) => externalUrl || '');

export const getCatalogDetailTerms = createSelector(getCatalogDetail, ({ terms }) => terms || '');
export const getCatalogDetailCustomTerms = createSelector(getCatalogDetail, ({ customTerms }) => customTerms || []);
