import { ApiHosts, handleResponseOld, makeGet } from '@/redux/api/helpers';
import { CatalogDetail } from '@/types/CatalogDetail';

/**
 * @category Item API
 * @see fetchCatalogDetailsByIds
 */
export type FetchCatalogDetailsByIdsResponse = {
    data: {
        catalogDetails: CatalogDetail[];
    };
    message: string;
    success: boolean;
};

/**
 * @category Item API
 * @see fetchCatalogDetailsByIds
 */
type FetchCatalogDetailsByIdsParams = {
    catalogIds: number[];
    deployment: string;
};

/**
 * POST request to item-api service sents a list of catalogIds and returns catalog details
 * @function fetchCatalogDetailsByIds
 * @category Item API
 * @param {FetchCatalogDetailsByIdsParams}
 * @see https://item-api-DEPLOYMENT.liveauctioneers.com/spa/small/catalog-detail
 * @see https://github.com/LIVEauctioneers/item-api/blob/master/src/inventory/routes/post-catalog-detail.ts
 */
export const fetchCatalogDetailsByIds = ({ catalogIds, deployment }: FetchCatalogDetailsByIdsParams) =>
    new Promise<FetchCatalogDetailsByIdsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: 'spa/small/catalog-detail',
            deployment,
            path: ApiHosts.ItemApi,
            queryParams: {
                ids: catalogIds.join(','),
            },
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
