import * as api from '@/redux/api/certCapture';
import { ActionWithPayload } from '@/types/redux';
import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import {
    DELETE_CERTIFICATE_FAIL,
    DELETE_CERTIFICATE_REQUEST,
    DELETE_CERTIFICATE_SUCCESS,
    GET_CERT_CAPTURE_TOKEN_FAIL,
    GET_CERT_CAPTURE_TOKEN_REQUEST,
    GET_CERT_CAPTURE_TOKEN_SUCCESS,
    GET_CERTIFICATES_FAIL,
    GET_CERTIFICATES_REQUEST,
    GET_CERTIFICATES_SUCCESS,
} from './actions';
import { getAuthToken, getBidderId } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from './config';
import { handleActions } from 'redux-actions';
import { LOG_OUT_BIDDER } from '@/redux/modules/account/logout/logout.actions';
import ms from 'ms';

const REDUX_STORE_TIME = ms('30m');

export type CertCaptureSlice = {
    certCaptureToken?: string;
    certTokenError: boolean;
    certificates: any[];
    error: boolean;
    loaded: number;
    loading: boolean;
};

export const defaultCertCaptureSlice: CertCaptureSlice = {
    certCaptureToken: '',
    certificates: [],
    certTokenError: false,
    error: false,
    loaded: 0,
    loading: false,
};

export const reducer: Reducer<CertCaptureSlice> = handleActions(
    {
        [DELETE_CERTIFICATE_FAIL]: (state: CertCaptureSlice, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [DELETE_CERTIFICATE_REQUEST]: (state: CertCaptureSlice) => ({
            ...state,
            loading: true,
        }),
        [DELETE_CERTIFICATE_SUCCESS]: (state: CertCaptureSlice) => ({
            ...state,
            loading: false,
        }),
        [GET_CERT_CAPTURE_TOKEN_FAIL]: (state: CertCaptureSlice, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            certTokenError: action.error,
            loading: false,
        }),
        [GET_CERT_CAPTURE_TOKEN_REQUEST]: (state: CertCaptureSlice) => ({
            ...state,
            loading: true,
        }),
        [GET_CERT_CAPTURE_TOKEN_SUCCESS]: (state: CertCaptureSlice, action: ActionWithPayload<any>) => ({
            ...state,
            certCaptureToken: action.payload,
            certTokenError: false,
            loading: false,
        }),
        [GET_CERTIFICATES_FAIL]: (state: CertCaptureSlice, action: ActionWithPayload<{ error: string }>) => ({
            ...state,
            error: action.error,
            loading: false,
        }),
        [GET_CERTIFICATES_REQUEST]: (state: CertCaptureSlice) => ({
            ...state,
            loading: true,
        }),
        [GET_CERTIFICATES_SUCCESS]: (state: CertCaptureSlice, action: ActionWithPayload<any, any>) => ({
            ...state,
            certificates: action.payload,
            loaded: action.meta.actionTime,
            loading: false,
        }),
        [LOG_OUT_BIDDER]: () => ({
            ...defaultCertCaptureSlice,
        }),
    },
    defaultCertCaptureSlice
);

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const certCaptureSlice = createSelector(stateSelector, (state) => state.certCapture);

export const getCertCaptureToken = createSelector(certCaptureSlice, (state) => state.certCaptureToken);

export const getCertificates = createSelector(certCaptureSlice, (state) => state.certificates || []);

export const getHasPendingOrCompleteCertificate = createSelector(certCaptureSlice, (state) => {
    return Boolean(
        state?.certificates?.length &&
            state.certificates.find((cert) => (cert.status === 'COMPLETE' || cert.status === 'PENDING') && cert.valid)
    );
});

export const getCertificatesHaveLoaded = createSelector(certCaptureSlice, (state) => state.loaded || state.error);

const isLoading = createSelector(certCaptureSlice, (state) => state.loading);

export const getCertificateError = createSelector(certCaptureSlice, (state) => state.certTokenError);

const shouldFetchCertificates = (state: GlobalState, forceUpdate) => {
    const certs = getCertificates(state);
    if (forceUpdate) {
        return true;
    }
    // @ts-expect-error needs better types
    if (certs === '') {
        return false;
    }

    if (certs.length) {
        const loaded = getCertificatesHaveLoaded(state);
        const time = Date.now();
        // @ts-expect-error needs better types
        const diff = time - loaded;
        if (diff < REDUX_STORE_TIME) {
            return false;
        }
    }
    const loading = isLoading(state);
    return !loading;
};

/* ACTION CREATORS */
export const fetchCertCaptureToken = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        dispatch({
            type: GET_CERT_CAPTURE_TOKEN_REQUEST,
        });
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        const response = await api.getCertCaptureToken({
            authToken,
            bidderId,
            deployment,
        });

        return dispatch({
            payload: response.payload,
            type: GET_CERT_CAPTURE_TOKEN_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error.message || error,
            type: GET_CERT_CAPTURE_TOKEN_FAIL,
        });
    }
};

export const fetchCertificatesIfNeeded =
    (forceUpdate?: boolean) => async (dispatch: AppDispatch, getState: AppGetState) => {
        if (shouldFetchCertificates(getState(), forceUpdate)) {
            return dispatch(fetchCertificates());
        }
        return Promise.resolve();
    };

const fetchCertificates = () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        dispatch({
            type: GET_CERTIFICATES_REQUEST,
        });
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        const response = await api.getCertificates({
            authToken,
            bidderId,
            deployment,
        });

        return dispatch({
            meta: { actionTime: Date.now() },
            payload: response.payload,
            type: GET_CERTIFICATES_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error.message || error,
            type: GET_CERTIFICATES_FAIL,
        });
    }
};

export const fetchCertificateDownload =
    (certificateId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
        try {
            const state = getState();
            const authToken = getAuthToken(state);
            const deployment = getDeployment(state);
            const bidderId = getBidderId(state);

            const response = await api.getCertificateDownload({
                authToken,
                bidderId,
                certificateId,
                deployment,
            });

            //converts base64 response to blob
            const binaryString = window.atob(response.payload);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; ++i) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            var blob: any = new Blob([bytes], { type: 'application/pdf' });
            blob.body = bytes; //needs to be added to body for DownloadButton to work

            return blob;
        } catch (error) {
            throw error;
        }
    };

export const deleteCertificate = (certificateId: number) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
        dispatch({
            type: DELETE_CERTIFICATE_REQUEST,
        });
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const bidderId = getBidderId(state);

        const response = await api.deleteCertificate({
            authToken,
            bidderId,
            certificateId,
            deployment,
        });
        dispatch(fetchCertificates());

        return dispatch({
            payload: response.payload,
            type: DELETE_CERTIFICATE_SUCCESS,
        });
    } catch (error) {
        return dispatch({
            error: true,
            payload: error.message || error,
            type: DELETE_CERTIFICATE_FAIL,
        });
    }
};
