import { ApiHosts, handleResponseWithNon200Errors, makeGet, makePost } from '@/redux/api/helpers';
import {
    GetBidderCatalogApprovalsParams,
    GetBidderCatalogApprovalsResponse,
    GetCatalogRegistrationsForUserByIdsParams,
    GetCatalogRegistrationsForUserByIdsResponse,
    PostAutoApprovalParams,
    PostAutoApprovalResponse,
    PostSetCatalogNotifyParams,
    PostSetCatalogNotifyResponse,
} from './catalogRegistration.types';

/**
 * GET request to approval service which returns a list of approval statuses for the logged-in user given a list of catalog ids
 * @function getCatalogRegistrationsForUserByIds
 * @category Approval API
 * @param {GetCatalogRegistrationsForUserByIdsParams}
 * @see https://approval-DEPLOYMENT.liveauctioneers.com/approval/catalog/statuses
 */
export const getCatalogRegistrationsForUserByIds = ({
    authToken,
    bidderId,
    catalogIds,
    deployment,
}: GetCatalogRegistrationsForUserByIdsParams) =>
    new Promise<GetCatalogRegistrationsForUserByIdsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/${bidderId}/catalog/statuses`,
            authToken,
            deployment,
            path: ApiHosts.Approval,
        });

        request.query({
            catalogIds: catalogIds.join(','),
        });

        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

/**
 * GET request to approval service which returns a list of approval statuses for the logged-in user
 * @function getBidderCatalogApprovals
 * @category Approval API
 * @param {GetBidderCatalogApprovalsParams}
 * @see https://approval-DEPLOYMENT.liveauctioneers.com/approval/catalog/statuses
 */
export const getBidderCatalogApprovals = ({ authToken, bidderId, deployment }: GetBidderCatalogApprovalsParams) =>
    new Promise<GetBidderCatalogApprovalsResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidder/${bidderId}`,
            authToken,
            deployment,
            path: ApiHosts.Approval,
        });

        request.end((err, response) =>
            handleResponseWithNon200Errors({
                err,
                reject,
                resolve,
                response,
            })
        );
    });

/**
 * Registers given bidderId for given catalogId via Post to Approval API
 * @function postAutoApproval
 * @category Approval API
 * @param {PostAutoApprovalParams}
 * @see https://approval-DEPLOYMENT.liveauctioneers.com/approval/catalog/${catalogId}/bidder/${bidderId}/auto-approve
 */
export const postAutoApproval = ({
    authToken,
    bidderId,
    catalogId,
    deployment,
    itemId,
    minimumBidLimit,
}: PostAutoApprovalParams) =>
    new Promise<PostAutoApprovalResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `catalog/${catalogId}/bidder/${bidderId}/auto-approve`,
            authToken,
            deployment,
            path: ApiHosts.Approval,
        });
        request.send({
            acceptTerms: true,
            lotId: itemId,
            minimumBidLimit,
        });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });

/**
 * Toggles wether or not a bidder will recieve notifications when auto approved to bid on a lot or catalog
 * @function postRegisterForCatalog
 * @category Catalog Management API
 * @param {PostRegisterForCatalogParams}
 * @see https://catalog-management-DEPLOYMENT.liveauctioneers.com/catalogs/${catalogId}/notify/${bidderId}
 */
export const postSetCatalogNotify = ({
    authToken,
    bidderId,
    catalogId,
    deployment,
    notify,
}: PostSetCatalogNotifyParams) => {
    return new Promise<PostSetCatalogNotifyResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `catalog/${catalogId}/bidder/${bidderId}/notify`,
            authToken,
            deployment,
            path: ApiHosts.Approval,
        });
        request.send({ notify });
        request.end((err, response) => handleResponseWithNon200Errors({ err, reject, resolve, response }));
    });
};
