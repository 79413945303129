import { ApiHosts, handleResponseOld, makeDelete, makeGet, makePatch, makePost } from '@/redux/api/helpers';
import { Folder } from '@/types/Messaging';

/**
 * @category Messaging API
 * @see patchRenameConversationFolders
 * @see postNewConversationFolders
 */
export type ConversationFoldersResponse = {
    error: boolean;
    payload: Folder;
};

/**
 * @category Messaging API
 * @see deleteArchiveConversationFolders
 */
export type DeleteArchiveConversationFoldersResponse = {
    error: boolean;
    payload: string;
};

/**
 * @category Messaging API
 * @see deleteArchiveConversationFolders
 */
type DeleteArchiveConversationFoldersParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    folderId: number;
};
/**
 * Deletes conversation folders by bidder and folder IDs
 * @function deleteArchiveConversationFolders
 * @category Messaging API
 * @param {DeleteArchiveConversationFoldersParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/folders?folderId=${folderId}
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/g4lopr/methods/DELETE
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/DeleteFolder-messaging-api
 */
export const deleteArchiveConversationFolders = ({
    authToken,
    bidderId,
    deployment,
    folderId,
}: DeleteArchiveConversationFoldersParams) =>
    new Promise<DeleteArchiveConversationFoldersResponse>((resolve, reject) => {
        const request = makeDelete({
            apiPath: `bidders/${bidderId}/folders?folderId=${folderId}`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see fetchConversationFolders
 */
type FetchConversationFoldersParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
};

/**
 * @category Messaging API
 * @see fetchConversationFolders
 */
export type FetchConversationFoldersResponse = {
    error: boolean;
    payload: {
        systemFolders: Folder[];
        userFolders: Folder[];
    };
};

/**
 * Gets Conversation folders for bidderId
 * @function fetchConversationFolders
 * @category Messaging API
 * @param {FetchConversationFoldersParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/folders
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/g4lopr/methods/GET
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/GetFolders-messaging-api
 */
export const fetchConversationFolders = ({ authToken, bidderId, deployment }: FetchConversationFoldersParams) =>
    new Promise<FetchConversationFoldersResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidders/${bidderId}/folders`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.query({ userFolderLimit: 200 });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see patchRenameConversationFolders
 */
type PatchRenameConversationFoldersParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    folderId: number;
    name: string;
};
/**
 * Renames a conversation folder for by bidder and folder Ids
 * @function patchRenameConversationFolders
 * @category Messaging API
 * @param {PatchRenameConversationFoldersParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/folders
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/PATCH
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/PatchConversations-messaging-api
 */
export const patchRenameConversationFolders = ({
    authToken,
    bidderId,
    deployment,
    folderId,
    name,
}: PatchRenameConversationFoldersParams) =>
    new Promise<ConversationFoldersResponse>((resolve, reject) => {
        const request = makePatch({
            apiPath: `bidders/${bidderId}/folders`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send({ id: folderId, name });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });

/**
 * @category Messaging API
 * @see postNewConversationFolders
 */
export type PostNewConversationFoldersParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    name: string;
};
/**
 * Creates a new Conversation Folder for a bidderId
 * @function postNewConversationFolders
 * @category Messaging API
 * @param {PostNewConversationFoldersParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/folders
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/g4lopr/methods/POST
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/CreateFolder-messaging-api
 */
export const postNewConversationFolders = ({
    authToken,
    bidderId,
    deployment,
    name,
}: PostNewConversationFoldersParams) =>
    new Promise<ConversationFoldersResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidders/${bidderId}/folders`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send({ name });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
