export type ConfirmPasswordSlice = {
    error: boolean;
    errorMessage: string | null | undefined;
    submitted: boolean;
    success: boolean;
};

export const defaultConfirmPasswordSlice = {
    error: false,
    errorMessage: null,
    submitted: false,
    success: false,
};
