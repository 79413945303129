import { ApiHosts, handleResponseOld, handleResponseWithNon200Errors, makeGet, makePost } from '../../api/helpers';
import { CheckoutInvoice, Invoice } from '@/types/Invoice';
import { ShippingMethods } from '@/enums/shippingMethod';

type TransformLegacyParams = {
    invoice: any;
    results: any;
    shippingAddress: any;
};
const transformLegacy = ({ invoice = {}, results = {}, shippingAddress = {} }: TransformLegacyParams) => {
    //invoice can be null, so this check is needed
    if (!Boolean(invoice)) {
        invoice = {};
    }

    const {
        atgpay,
        balance_due: balanceDue,
        balance_due_subtotal: balanceDueSubtotal,
        buyers_premium_total: buyersPremium,
        currency,
        high_risk: highRisk,
        house_shipping_quote: houseShippingQuote,
        id,
        invoice_total: invoiceTotal,
        is_sent: sent,
        item_total: itemTotal,
        la_payment: laPayment,
        other_charges: otherCharges,
        payments,
        payments_total: paymentsTotal,
        processing_fee: processingFee,
        sales_tax,
        shipping_charge,
        shipping_method,
    } = invoice;

    const {
        address_id: addressId,
        address_line1: address1,
        address_line2: address2,
        bidder_id: bidderId,
        city,
        country: countryCode,
        phone_number: phoneNumber,
        postal_code: postalCode,
        shipping_option_type: shippingMethod,
        state,
    } = shippingAddress || {};

    const items = results?.lots?.map((item) => {
        return {
            catalogId: item.catID,
            imageVersion: item.imageVersion,
            itemId: item.lotID,
            salePrice: item.sale_price,
            sellerId: item.houseID,
            sellerName: item.name,
            title: item.title,
        };
    });

    const shippingQuote = Number(houseShippingQuote || shipping_charge || 0);

    const data: CheckoutInvoice = {
        invoice: {
            atgpay,
            balanceDue,
            balanceDueSubtotal,
            buyersPremium,
            currency: currency?.cur_code,
            highRisk,
            houseShippingQuote: shippingQuote,
            id,
            invoiceTotal,
            itemTotal,
            laPayment,
            otherCharges,
            payments,
            paymentsTotal,
            processingFee,
            salesTax: Number(sales_tax),
            sent: Boolean(sent),
            shipping:
                Boolean(shipping_method) && shipping_method.toLowerCase() === ShippingMethods.HouseShipping
                    ? Number(shipping_charge)
                    : 0,
            shippingMethod: Boolean(shipping_method) ? shipping_method.toLowerCase() : ShippingMethods.BidderShipping,
        },
        invoiceAddress: {
            address1,
            address2,
            addressId,
            bidderId,
            city,
            countryCode,
            firstName: '',
            lastName: '',
            organizationName: '',
            phoneNumber,
            postalCode,
            state,
        },
        items,
        sent: Boolean(sent),
        shippingMethod,
    };

    return {
        data,
        message: id ? 'Successfully retrieved the invoice.' : 'No Invoice for catalog',
        success: Boolean(id),
    };
};

type TransformNewParams = {
    payload: {
        invoice: any;
        results: any;
        shippingAddress: any;
    };
};
const transformNew = ({ payload }: TransformNewParams) => {
    return transformLegacy(payload);
};

type TransformReopened = {
    payload: any;
};

const transformReopened = ({ payload }: TransformReopened) => {
    if (!Boolean(payload)) {
        payload = {};
    }

    const {
        amount_paid: paymentsTotal,
        balance_due: balanceDue,
        balance_due_subtotal: balanceDueSubtotal,
        buyers_premium_total: buyersPremium,
        currency,
        high_risk: highRisk,
        id,
        invoice_total: invoiceTotal,
        is_reopened: isReopened,
        item_total: itemTotal,
        la_payment: laPayment,
        other_charges: otherCharges,
        sales_tax: salesTax,
        shipping_charge,
        shipping_method,
    } = payload;

    const transformedInvoice: Partial<Invoice> = {
        balanceDue,
        balanceDueSubtotal,
        buyersPremium,
        currency,
        highRisk,
        id,
        invoiceTotal,
        itemTotal,
        laPayment,
        otherCharges,
        paymentsTotal,
        reopened: isReopened,
        salesTax,
        shipping:
            Boolean(shipping_method) && shipping_method.toLowerCase() === ShippingMethods.HouseShipping
                ? Number(shipping_charge)
                : 0,
        shippingMethod: Boolean(shipping_method) ? shipping_method.toLowerCase() : ShippingMethods.BidderShipping,
    };

    return {
        message: id ? 'Successfully reopened the invoice.' : 'No Invoice to reopen for catalog',
        payload: transformedInvoice,
        success: Boolean(id),
    };
};

/**
 * @category Mainhost API
 * @see fetchInvoice
 */
type FetchInvoiceParams = {
    authToken: string;
    bidderId: number;
    billingCheckout: boolean;
    catalogId: number;
    deployment: string;
};

type FetchInvoiceResponse = {
    data: CheckoutInvoice;
    error: boolean;
};

type PostReopenPaidInvoiceResponse = {
    error: boolean;
    payload: Partial<Invoice>;
};

/**
 * Gets invoice info via Get to Mainhost service's payment API
 * @function fetchInvoice
 * @category Mainhost API
 * @param {FetchInvoiceParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/api/bidders/${bidderId}/wonitems/catalogs/${catalogId}
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/httpdocs/api/bidders.php#L198
 */
export const getInvoice = ({ authToken, bidderId, billingCheckout, catalogId, deployment }: FetchInvoiceParams) =>
    new Promise<FetchInvoiceResponse>((resolve, reject) => {
        if (billingCheckout) {
            const request = makeGet({
                apiPath: `billing/bidder/legacy/invoice/bidders/${bidderId}/wonitems/catalogs/${catalogId}`,
                authToken,
                deployment,
                path: ApiHosts.Billing,
                queryParams: { client_version: '5.0', page: 1, pagesize: 1250 },
            });
            request.end((err, response) =>
                handleResponseWithNon200Errors({
                    err,
                    reject,
                    request,
                    resolve,
                    response,
                    transform: transformNew,
                })
            );
        } else {
            const request = makeGet({
                apiPath: `api/bidders/${bidderId}/wonitems/catalogs/${catalogId}`,
                authToken,
                deployment,
                path: ApiHosts.Mainhost,
                queryParams: { client_version: '5.0', page: 1, pagesize: 1250 },
            });
            request.end((err, response) =>
                handleResponseOld({
                    err,
                    reject,
                    resolve,
                    response,
                    transform: transformLegacy,
                })
            );
        }
    });

/**
 * @category Billing API
 * @see postReopenPaidInvoice
 */
type PostReopenPaidInvoiceParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    invoiceId: number;
    shippingAddressId: number;
};

/**
 * reopens a paid invoice  via Post to Billing API
 * @function postReopenPaidInvoice
 * @category Billing API
 * @param {PostReopenPaidInvoiceParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/billing/bidder/invoice/${invoiceId}/reopen?bidderId=${bidderId}&shippingAddressId=${shippingAddressId}
 * @see https://github.com/LIVEauctioneers/billing/blob/master/handlers/BidderInvoiceHandler.go/HandleInvoiceReopen
 */
export const postReopenPaidInvoice = ({
    authToken,
    bidderId,
    deployment,
    invoiceId,
    shippingAddressId,
}: PostReopenPaidInvoiceParams) => {
    return new Promise<PostReopenPaidInvoiceResponse>((resolve, reject) => {
        const request = makePost({
            apiPath: `bidder/invoice/${invoiceId}/reopen?bidderId=${bidderId}&shippingAddressId=${shippingAddressId}`,
            authToken,
            deployment,
            path: ApiHosts.Billing,
        });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformReopened,
            })
        );
    });
};

/**
 * @category Mainhost API
 * @see postShippingMethod
 */
type PostShippingMethodParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    invoiceId: number;
    shippingMethod: ShippingMethods;
};
/**
 * Gets invoice info via Get to Mainhost service's payment API
 * @function postShippingMethod
 * @category Mainhost API
 * @param {PostShippingMethodParams}
 * @see https://mainhost-DEPLOYMENT.liveauctioneers.com/api/bidders/${bidderId}/checkout/${invoiceId}/shippingMethod
 * @see https://github.com/LIVEauctioneers/mainhost-prod/blob/master/httpdocs/api/bidders.php#L198
 */
export const postShippingMethod = ({
    authToken,
    bidderId,
    deployment,
    invoiceId,
    shippingMethod,
}: PostShippingMethodParams) =>
    new Promise<any>((resolve, reject) => {
        const request = makePost({
            apiPath: `api/bidders/${bidderId}/checkout/${invoiceId}/shippingMethod`,
            authToken,
            deployment,
            path: ApiHosts.Mainhost,
        });
        request.send({
            shippingMethod,
        });
        request.end((err, response) => handleResponseOld({ err, reject, resolve, response }));
    });
