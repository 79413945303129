import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { CombinationCategory } from '@/types/CombinationCategory';
import { createSelector } from '@reduxjs/toolkit';
import {
    fetchCombinationCategoryByConcatedIDs,
    fetchCombinationRelatedSearchesByConcatedIDs,
    fetchCombinationSuggestedCategoriesByConcatedIDs,
} from '@/redux/api/combinationCategory';
import { getDeployment } from './config';
import { RelatedSearch } from '@/types/RelatedSearch';
import { SuggestedCategory } from '@/types/SuggestedCategory';

/* Action Types */
export const LOAD_COMBINATION_CATEGORY_FAIL = 'la/domain/category/LOAD_COMBINATION_CATEGORY_FAIL';
export const LOAD_COMBINATION_CATEGORY_SUCCESS = 'la/domain/category/LOAD_COMBINATION_CATEGORY_SUCCESS';
export const LOAD_COMBINATION_RELATED_SEARCHES_FAIL = 'la/domain/category/LOAD_COMBINATION_RELATED_SEARCHES_FAIL';
export const LOAD_COMBINATION_RELATED_SEARCHES_SUCCESS = 'la/domain/category/LOAD_COMBINATION_RELATED_SEARCHES_SUCCESS';
export const LOAD_COMBINATION_SUGGESTED_CATEGORIES_FAIL =
    'la/domain/category/LOAD_COMBINATION_SUGGESTED_CATEGORIES_FAIL';
export const LOAD_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS =
    'la/domain/category/LOAD_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS';

export type CombinationCategorySlice = {
    combinationCategory: CombinationCategory | null;
    combinationRelatedSearches: RelatedSearch[];
    combinationSuggestedCategories: SuggestedCategory[];
};

/* reducer */
export const defaultCombinationCategorySlice: CombinationCategorySlice = {
    combinationCategory: null,
    combinationRelatedSearches: [],
    combinationSuggestedCategories: [],
};

export default function reducer(state = defaultCombinationCategorySlice, action: any = {}): CombinationCategorySlice {
    switch (action.type) {
        case LOAD_COMBINATION_CATEGORY_FAIL:
            return {
                ...state,
                combinationCategory: null,
            };
        case LOAD_COMBINATION_CATEGORY_SUCCESS:
            return {
                ...state,
                combinationCategory: action.payload,
            };
        case LOAD_COMBINATION_SUGGESTED_CATEGORIES_FAIL:
            return {
                ...state,
                combinationSuggestedCategories: [],
            };
        case LOAD_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS:
            return {
                ...state,
                combinationSuggestedCategories: action.payload,
            };
        case LOAD_COMBINATION_RELATED_SEARCHES_FAIL:
            return {
                ...state,
                combinationRelatedSearches: [],
            };
        case LOAD_COMBINATION_RELATED_SEARCHES_SUCCESS:
            return {
                ...state,
                combinationRelatedSearches: action.payload,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const combinationCategorySlice = createSelector(stateSelector, (state) => state.combinationCategory);

export const getCombinationCategory = createSelector(
    combinationCategorySlice,
    ({ combinationCategory }) => combinationCategory
);
export const getCombinationSuggestedCategories = createSelector(
    combinationCategorySlice,
    (state) => state.combinationSuggestedCategories || []
);
export const getCombinationRelatedSearches = createSelector(
    combinationCategorySlice,
    (state) => state.combinationRelatedSearches || []
);

/* ACTION CREATORS */
export const fetchCombinationCategory =
    (concatedIDs: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const deployment = getDeployment(state);
        try {
            const response = await fetchCombinationCategoryByConcatedIDs({
                concatedIDs,
                deployment,
            });
            dispatch({
                payload: response.payload,
                type: LOAD_COMBINATION_CATEGORY_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { concatedIDs },
                payload: error,
                type: LOAD_COMBINATION_CATEGORY_FAIL,
            });
        }
    };

export const fetchCombinationSuggestedCategories =
    (concatedIDs: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const deployment = getDeployment(state);
        try {
            const response = await fetchCombinationSuggestedCategoriesByConcatedIDs({
                concatedIDs,
                deployment,
            });
            dispatch({
                payload: response.payload,
                type: LOAD_COMBINATION_SUGGESTED_CATEGORIES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { concatedIDs },
                payload: error,
                type: LOAD_COMBINATION_SUGGESTED_CATEGORIES_FAIL,
            });
        }
    };

export const fetchCombinationRelatedSearches =
    (concatedIDs: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
        const state = getState();
        const deployment = getDeployment(state);
        try {
            const response = await fetchCombinationRelatedSearchesByConcatedIDs({ concatedIDs, deployment });
            dispatch({
                payload: response.payload,
                type: LOAD_COMBINATION_RELATED_SEARCHES_SUCCESS,
            });
        } catch (error) {
            dispatch({
                error: true,
                meta: { concatedIDs },
                payload: error,
                type: LOAD_COMBINATION_RELATED_SEARCHES_FAIL,
            });
        }
    };
