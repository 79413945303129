import { createSelector } from '@reduxjs/toolkit';
import { getUserData } from '@/redux/modules/account/user/user.selectors';
import { GlobalState } from '@/redux/store';

const stateSelector = (state: GlobalState) => state;
const conversationMessagesSlice = createSelector(stateSelector, (state) => state.conversationMessages);

const idSelector = (_: GlobalState, id: number) => id;
const messageInfoSelector = (_: GlobalState, info: { attachmentName: string; conversationId: number }) => info;

const byIdSelector = createSelector(conversationMessagesSlice, (state) => state.byId);

const draftsSelector = createSelector(conversationMessagesSlice, (state) => state.messageDrafts);

const getMessages = createSelector([byIdSelector, idSelector], (byId, id: number) => byId[id] || []);

export const getConversationMessages: (state: GlobalState, selector: number) => any[] = (
    state: GlobalState,
    conversationId: number
) => {
    const userData = getUserData(state);
    return getMessages(state, conversationId).map((message) => {
        if (message?.sender?.type === 'bidder' && message?.sender?.id === userData.bidderId) {
            return {
                ...message,
                sender: {
                    ...message.sender,
                    initials: `${userData.firstName[0]}${userData.lastName[0]}`,
                    name: `${userData.firstName} ${userData.lastName}`,
                },
            };
        }
        return message;
    });
};

export const getDraftId = createSelector(
    [draftsSelector, idSelector],
    (messageDrafts, conversationId: number) => messageDrafts[conversationId]?.draftId
);

export const getDraftAttachments = createSelector(
    [draftsSelector, idSelector],
    (messageDrafts, conversationId: number) => messageDrafts[conversationId]?.draftAttachments ?? []
);

export const getAttachmentId = createSelector(
    [draftsSelector, messageInfoSelector],
    (messageDrafts, { attachmentName, conversationId }) => {
        const attachments = messageDrafts[conversationId]?.draftAttachments;
        const attachment = attachments?.find((att) => att.name === attachmentName);
        return attachment?.name ? attachment.id : 0;
    }
);
