import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchCatalogsByIds } from './catalog.api';
import { FetchCatalogsIfNeededPayload, FetchCatalogsResponsePayload, LoadCatalogsResponse } from './catalog.types';
import { getDeployment } from '@/redux/modules/config';
import { TypedActionWithPayload } from '@/types/redux';
import { whichCatalogsNeeded } from './catalog.selectors';

export const loadCatalogs = createAsyncThunk<LoadCatalogsResponse, number[]>(
    'la/domain/catalog/loadCatalogs',
    async (catalogIds, { dispatch, getState }) => {
        const state = getState();
        const deployment = getDeployment(state);
        const { data } = await fetchCatalogsByIds({
            catalogIds,
            deployment,
        });
        const now = Date.now();
        // TODO: once all the reducers that subscribe to LOAD_CATALOGS_SUCCESS are updated to use redux-toolkit patterns, this success action should be removed
        const successAction: LoadCatalogsSuccessAction = {
            error: false,
            meta: {
                actionTime: now,
                catalogIds,
            },
            payload: data,
            type: LOAD_CATALOGS_SUCCESS,
        };
        await dispatch(successAction);
        return {
            actionTime: now,
            data,
        };
    }
);

export const fetchCatalogsIfNeeded = createAsyncThunk<Promise<unknown>, FetchCatalogsIfNeededPayload>(
    'la/domain/catalog/fetchCatalogsIfNeeded',
    async ({ catalogIds }, { dispatch, getState }) => {
        const state = getState();
        const needed = whichCatalogsNeeded(state, catalogIds);
        if (needed.length) {
            return dispatch(loadCatalogs(needed));
        }
        return Promise.resolve();
    }
);

// TODO: Remove uses of this action string, and instead use loadCatalogs.fulfilled in the newly create
/**
 * @deprecated If using redux-toolkit's createSlice, use loadCatalogs.fulfilled
 * @see loadCatalogs
 */
export const LOAD_CATALOGS_SUCCESS = 'la/domain/catalogs/LOAD_SUCCESS';
/**
 * @deprecated If using redux-toolkit's createSlice, use loadCatalogs.fulfilled
 * @see loadCatalogs
 */
export type LoadCatalogsSuccessAction = TypedActionWithPayload<
    typeof LOAD_CATALOGS_SUCCESS,
    FetchCatalogsResponsePayload,
    {
        actionTime: number;
        catalogIds: number[];
    }
>;
