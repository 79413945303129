import { ApiHosts, handleResponseOld, makeGet, makePatch } from '@/redux/api/helpers';
import { FetchConversationResponse } from '@/redux/modules/conversation/conversations/conversation.types';
import { getImageUrl } from '@/utils/urls';

const transformConversations = ({ error, meta, payload }) => {
    const transformedPayload = Array.isArray(payload)
        ? payload.map((conv: any) => {
              const { id, logoUrl, name } = conv.participants.find((p) => p.type === 'house');
              let imageUrl =
                  getImageUrl({
                      catalogId: conv.catalogId,
                      imageNumber: 1,
                      itemId: conv.lotId,
                      sellerId: id,
                      width: 100,
                  }) || '';

              return {
                  catalogId: conv.catalogId,
                  conversationId: conv.conversationId,
                  folderId: conv.folderId,
                  hasAttachments: conv.hasAttachments,
                  imageUrl,
                  itemId: conv.lotId,
                  logoUrl,
                  messageCount: conv.messageCount,
                  read: conv.read,
                  sellerId: id,
                  sender: name,
                  subject: conv.subject,
                  tsLastMessageSent: conv.tsLastMessageSent,
                  type: 'house',
              };
          })
        : payload;

    return {
        error,
        meta,
        payload: transformedPayload,
    };
};

/**
 * @category Messaging API
 * @see fetchConversation
 */
type FetchConversationParams = {
    authToken: string;
    bidderId: number;
    conversationId: number;
    deployment: string;
};
/**
 * Gets conversation information by given bidder and conversation IDs
 * @function fetchConversation
 * @category Messaging API
 * @param {FetchConversationParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/GET
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/GetConversations-messaging-api
 */
export const fetchConversation = ({ authToken, bidderId, conversationId, deployment }: FetchConversationParams) =>
    new Promise<FetchConversationResponse>((resolve, reject) => {
        const request = makeGet({
            apiPath: `bidders/${bidderId}/conversations`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.query({ conversationId });
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformConversations,
            })
        );
    });

/**
 * @category Messaging API
 * @see fetchConversations
 */
type FetchConversationsParams = {
    authToken: string;
    bidderId: number;
    deployment: string;
    query?: any;
};
/**
 * Gets set of conversations from bidderId and optional search string
 * @function fetchConversations
 * @category Messaging API
 * @param {FetchConversationsParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/GET
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/GetConversations-messaging-api
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations/search
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/b901wc/methods/GET
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/SearchConversations-messaging-api
 */
export const fetchConversations = ({ authToken, bidderId, deployment, query = {} }: FetchConversationsParams) =>
    new Promise<FetchConversationResponse>((resolve, reject) => {
        const requestQuery = { ...query };

        const options = {
            apiPath: `bidders/${bidderId}/conversations`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        };
        if (query.search) {
            options.apiPath = `bidders/${bidderId}/conversations/search`;
            requestQuery.q = requestQuery.search;
            delete requestQuery.search;
        }

        const request = makeGet(options);
        request.query(requestQuery);

        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformConversations,
            })
        );
    });

/**
 * @category Messaging API
 * @see archiveConversation
 * @see markConversationRead
 * @see markConversationUnread
 * @see moveConversation
 */
type ConversationActionsParams = {
    authToken: string;
    bidderId: number;
    conversations: number[];
    deployment: string;
    folderId?: number;
};
/**
 * Archives a given conversation by bidderId using Messaging API Patch request
 * @function archiveConversation
 * @category Messaging API
 * @param {ConversationActionParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/PATCH
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/PatchConversations-messaging-api
 */
export const archiveConversation = ({ authToken, bidderId, conversations, deployment }: ConversationActionsParams) =>
    new Promise<FetchConversationResponse>((resolve, reject) => {
        const request = makePatch({
            apiPath: `bidders/${bidderId}/conversations`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send(
            conversations.map((id) => ({
                id: Number(id),
                op: 'replace',
                path: '/folder/id',
                value: -1,
            }))
        );
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformConversations,
            })
        );
    });

/**
 * Marks a given conversation as Read by bidderId using Messaging API Patch request
 * @function markConversationRead
 * @category Messaging API
 * @param {ConversationActionParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/PATCH
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/PatchConversations-messaging-api
 */
export const markConversationRead = ({ authToken, bidderId, conversations, deployment }: ConversationActionsParams) =>
    new Promise<FetchConversationResponse>((resolve, reject) => {
        const request = makePatch({
            apiPath: `bidders/${bidderId}/conversations`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send(
            conversations.map((id) => ({
                id: Number(id),
                op: 'replace',
                path: '/read',
                value: true,
            }))
        );
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformConversations,
            })
        );
    });

/**
 * Marks a given conversation as Unread by bidderId using Messaging API Patch request
 * @function markConversationUnread
 * @category Messaging API
 * @param {ConversationActionParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/PATCH
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/PatchConversations-messaging-api
 */
export const markConversationUnread = ({ authToken, bidderId, conversations, deployment }: ConversationActionsParams) =>
    new Promise<FetchConversationResponse>((resolve, reject) => {
        const request = makePatch({
            apiPath: `bidders/${bidderId}/conversations`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send(
            conversations.map((id) => ({
                id: Number(id),
                op: 'replace',
                path: '/read',
                value: false,
            }))
        );
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformConversations,
            })
        );
    });

/**
 * Moves a given conversation to a new folder location by bidderId using Messaging API Patch request
 * @function moveConversation
 * @category Messaging API
 * @param {ConversationActionParams}
 * @see https://api-DEPLOYMENT.liveauctioneers.com/messaging/bidders/${bidderId}/conversations
 * @see https://console.aws.amazon.com/apigateway/home?region=us-east-1#/apis/77oixlnps8/resources/p2qa2j/methods/PATCH
 * @see https://github.com/LIVEauctioneers/messaging-api/tree/master/functions/PatchConversations-messaging-api
 */
export const moveConversation = ({
    authToken,
    bidderId,
    conversations,
    deployment,
    folderId = 0,
}: ConversationActionsParams) =>
    new Promise<FetchConversationResponse>((resolve, reject) => {
        const request = makePatch({
            apiPath: `bidders/${bidderId}/conversations`,
            authToken,
            deployment,
            path: ApiHosts.Messaging,
        });
        request.send(
            conversations.map((id) => ({
                id: Number(id),
                op: 'replace',
                path: '/folder/id',
                value: folderId,
            }))
        );
        request.end((err, response) =>
            handleResponseOld({
                err,
                reject,
                resolve,
                response,
                transform: transformConversations,
            })
        );
    });
