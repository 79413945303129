import { createSelector } from '@reduxjs/toolkit';
import { Deployment } from '@liveauctioneers/hammer-ui-payments/types';
import { Environment } from '@kameleoon/javascript-sdk';
import { GlobalState } from '@/redux/store';
import { LOAD_LIVE_CATALOGS_CONFIG_SUCCESS, LOAD_LIVE_CATALOGS_CONFIG_SUCCESS_ACTION } from './actions';
import { PubSubConfig, PubSubProvider } from '@/types/PubSubProvider';
import { useLocation } from 'react-router-dom';

export interface ConfigSlice {
    algoliaApiKey: string;
    algoliaAppId: string;
    baseUrl: string;
    buildNumber: string;
    classicBaseUrl: string;
    curatorImgUrl: string;
    deployment: Deployment | 'test' | '';
    globalNotificationClientType: string;
    googleApiKey: string;
    googlePageSpeedInsightsApiKey: string;
    kameleoonEnvironment: Environment;
    kameleoonSiteCode: string;
    messagingProvider: PubSubProvider | null;
    messagingProviderKeyConfigs: {
        ably: PubSubConfig;
        pubnub: PubSubConfig;
    };
    partnersBaseUrl: string;
    pubNubNotificationsSubscribeKey: string;
    pubNubSubscribeKey: string;
    pushSubscriptionPublicKey: string;
    sailthruCustomerId: string;
    segmentWriteKey: string;
    useSignifyd: boolean;
    wwwBaseUrl: string;
    zipMerchantKey: string;
}

/* reducer */
export const defaultConfigSlice: ConfigSlice = {
    algoliaApiKey: '',
    algoliaAppId: '',
    baseUrl: '',
    buildNumber: '',
    classicBaseUrl: '',
    curatorImgUrl: '',
    deployment: '',
    globalNotificationClientType: '',
    googleApiKey: '',
    googlePageSpeedInsightsApiKey: '',
    // defaulting this to development to avoid unintentionally logging to prod
    kameleoonEnvironment: Environment.Development,
    kameleoonSiteCode: '',
    messagingProvider: null,
    messagingProviderKeyConfigs: {
        ably: {
            publishKey: '',
            subscribeKey: '',
        },
        pubnub: {
            publishKey: '',
            subscribeKey: '',
        },
    },
    partnersBaseUrl: '',
    pubNubNotificationsSubscribeKey: '',
    pubNubSubscribeKey: '',
    pushSubscriptionPublicKey: '',
    sailthruCustomerId: '',
    segmentWriteKey: '',
    useSignifyd: false,
    wwwBaseUrl: '',
    zipMerchantKey: '',
};

type Action = LOAD_LIVE_CATALOGS_CONFIG_SUCCESS_ACTION;

export default function reducer(state: ConfigSlice = defaultConfigSlice, action: Action): ConfigSlice {
    switch (action?.type) {
        case LOAD_LIVE_CATALOGS_CONFIG_SUCCESS: {
            return {
                ...state,
                messagingProvider: action.payload.provider,
                messagingProviderKeyConfigs: {
                    ...state.messagingProviderKeyConfigs,
                    pubnub: action.payload.pubnub,
                },
            };
        }
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const configSlice = createSelector(stateSelector, (state) => state.config);

export const getAlgoliaApiKey = createSelector(configSlice, (state) => state.algoliaApiKey);
export const getAlgoliaAppId = createSelector(configSlice, (state) => state.algoliaAppId);
export const getBaseUrl = createSelector(configSlice, (state) => state.baseUrl);
export const getBuildNumber = createSelector(configSlice, (state) => state.buildNumber);
export const getClassicBaseUrl = createSelector(configSlice, (state) => state.classicBaseUrl);
export const getCuratorBaseUrl = createSelector(configSlice, (state) => state.curatorImgUrl);
export const getDeployment = createSelector(configSlice, (state) => state.deployment);

export const getUrlFromPathname = createSelector(
    [configSlice, (_: GlobalState, pathname: ReturnType<typeof useLocation>['pathname']) => pathname],
    ({ deployment }, pathname) => {
        let deploymentDomain: string;
        switch (deployment) {
            case Deployment.Barako:
                deploymentDomain = 'bidder-react-barako.liveauctioneers.com';
                break;
            case Deployment.Stage:
                deploymentDomain = 'bidder-react-stage.liveauctioneers.com';
                break;
            case Deployment.PreProd:
                deploymentDomain = 'bidder-react-preprod.liveauctioneers.com';
                break;
            case Deployment.Prod:
            default:
                deploymentDomain = 'www.liveauctioneers.com';
                break;
        }
        return `${deploymentDomain}${pathname}`;
    }
);

export const getGlobalNotificationClientType = createSelector(
    configSlice,
    (state) => state.globalNotificationClientType
);
export const getGoogleApiKey = createSelector(configSlice, (state) => state.googleApiKey);
export const getGooglePageSpeedInsightsApiKey = createSelector(
    configSlice,
    (state) => state.googlePageSpeedInsightsApiKey
);
export const getPartnersBaseUrl = createSelector(configSlice, (state: ConfigSlice) => state.partnersBaseUrl);
export const getPubnubNotificationsSubscribeKey = createSelector(
    configSlice,
    (state) => state.pubNubNotificationsSubscribeKey
);
export const getPubnubSubscribeKey = createSelector(configSlice, (state) => state.pubNubSubscribeKey);
export const getPushSubscriptionPublicKey = createSelector(configSlice, (state) => state.pushSubscriptionPublicKey);
export const getZipMerchantKey = createSelector(configSlice, (state) => state.zipMerchantKey);
export const getSailthruCustomerId = createSelector(configSlice, (state) => state.sailthruCustomerId);
export const getSegmentWriteKey = createSelector(configSlice, (state) => state.segmentWriteKey);
export const getUseSignifyd = createSelector(configSlice, (state) => state.useSignifyd);
export const getWWWBaseUrl = createSelector(configSlice, (state) => state.wwwBaseUrl);

export const getMessagingProviderKeyConfigs = createSelector(
    configSlice,
    (state: ConfigSlice) => state.messagingProviderKeyConfigs
);

export const getPubSubMessagingProvider = createSelector(configSlice, (state: ConfigSlice) => state.messagingProvider);

/**
 * Returns the publishKey and subscribeKey for the current messagingProvider
 */
export const getPubSubMessagingProviderKeys = createSelector(
    [getMessagingProviderKeyConfigs, getPubSubMessagingProvider],
    (messagingProviderKeyConfigs, messagingProvider): PubSubConfig => {
        return (
            messagingProviderKeyConfigs[messagingProvider as PubSubProvider] || {
                publishKey: '',
                subscribeKey: '',
            }
        );
    }
);
