export const generateAdKey = ({
    categoryIds,
    keywords,
    pageName,
}: {
    categoryIds?: string;
    keywords?: string;
    pageName?: string;
}) => {
    const keyArray = [pageName, categoryIds, keywords].filter((key) => Boolean(key));
    return keyArray.join(':');
};

// category decoder ring
/* eslint-disable sort-keys/sort-keys-fix */
export const categories = {
    1: 'art',
    2: 'collectibles',
    3: 'fashion',
    4: 'furniture',
    5: 'home',
    6: 'jewelry',
    24: 'coins',
    158: 'asian',
};
/* eslint-enable */
