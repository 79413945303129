import {
    addAttachment,
    clearDraft,
    deleteAttachment,
    loadConversationMessages,
    sendConversationMessage,
} from '@/redux/modules/conversation/messages/conversationMessages.actions';
import { createSlice } from '@reduxjs/toolkit';
import {
    defaultConversationMessagesSlice,
    MessageAttachmentDraft,
} from '@/redux/modules/conversation/messages/conversationMessages.types';
import { LOG_OUT_BIDDER, LogOutBidderSuccessAction } from '@/redux/modules/account/logout/logout.actions';
import difference from 'lodash/difference';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';

const conversationMessagesSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(loadConversationMessages.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, [meta.arg]);
        });
        builder.addCase(loadConversationMessages.fulfilled, (slice, { meta, payload }) => {
            if (Array.isArray(payload)) {
                slice.byId[meta.arg] = payload;
                slice.loaded[meta.arg] = Date.now();
            }
            slice.loading = difference(slice.loading, [meta.arg]);
        });
        builder.addCase(loadConversationMessages.rejected, (slice, { meta }) => {
            slice.loading = difference(slice.loading, [meta.arg]);
        });

        builder.addCase(sendConversationMessage.pending, (slice, { meta }) => {
            slice.loading = union(slice.loading, [meta.arg.conversationId]);
        });
        builder.addCase(sendConversationMessage.fulfilled, (slice, { meta, payload }) => {
            const { conversationId } = meta.arg;
            const originalArray = slice.byId[conversationId] ?? [];
            if (Array.isArray(payload)) {
                slice.byId[conversationId] = uniqBy([...originalArray, ...payload], 'messageId');
            } else {
                slice.byId[conversationId] = uniqBy([...originalArray, payload], 'messageId');
            }
            slice.loaded[conversationId] = Date.now();
            slice.loading = difference(slice.loading, [conversationId]);
        });
        builder.addCase(sendConversationMessage.rejected, (slice, { meta }) => {
            slice.loading = difference(slice.loading, [meta.arg.conversationId]);
        });

        builder.addCase(addAttachment.fulfilled, (slice, { payload }) => {
            if (payload) {
                const draftAttachments: MessageAttachmentDraft[] = payload.attachments.map((attachment) => ({
                    id: attachment.attachmentId,
                    name: attachment.originalFilename,
                }));
                slice.messageDrafts[payload.conversationId] = {
                    draftAttachments,
                    draftId: payload.draftId,
                };
            }
        });

        builder.addCase(deleteAttachment.fulfilled, (slice, { meta }) => {
            const { attachmentName, conversationId } = meta.arg;
            slice.messageDrafts[conversationId].draftAttachments = slice.messageDrafts[
                conversationId
            ].draftAttachments.filter(({ name }) => attachmentName !== name);
        });

        builder.addCase(clearDraft.fulfilled, (slice, { payload }) => {
            slice.messageDrafts[payload] = {
                draftAttachments: [],
                draftId: 0,
            };
        });

        builder.addCase<typeof LOG_OUT_BIDDER, LogOutBidderSuccessAction>(
            LOG_OUT_BIDDER,
            () => defaultConversationMessagesSlice
        );
    },
    initialState: defaultConversationMessagesSlice,
    name: 'conversationMessages',
    reducers: {},
});

export const { reducer: conversationMessagesReducer } = conversationMessagesSlice;
