import { ChangePasswordPayload } from '@/redux/modules/changePassword/changePassword.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { postChangePassword } from '@/redux/modules/changePassword/changePassword.api';

export const changeUserPassword = createAsyncThunk<void, ChangePasswordPayload>(
    'la/ui/changePassword/changeUserPassword',
    async ({ newPassword, oldPassword }, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        await postChangePassword({
            authToken,
            deployment,
            newPassword,
            oldPassword,
        });
    }
);
