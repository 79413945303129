import { AppDispatch, AppGetState, GlobalState } from '@/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { fetchCatalogIdsBySearchTerm } from '@/redux/api/categoryCatalogCards';
import { getDeployment } from './config';

/* Action Types */
export const LOAD_CATEGORY_CATALOG_IDS_FAIL = 'la/domain/items/LOAD_CATEGORY_CATALOG_IDS_FAIL';
export const LOAD_CATEGORY_CATALOG_IDS_SUCCESS = 'la/domain/items/LOAD_CATEGORY_CATALOG_IDS_SUCCESS';

export interface CategoryCatalogSlice {
    categoryCatalogIds: number[];
    title: string;
}

/* reducer */
export const defaultCategoryCatalogCardsSlice: CategoryCatalogSlice = {
    categoryCatalogIds: [],
    title: '',
};

export default function reducer(state = defaultCategoryCatalogCardsSlice, action: any = {}): CategoryCatalogSlice {
    switch (action.type) {
        case LOAD_CATEGORY_CATALOG_IDS_FAIL:
            return {
                ...state,
                categoryCatalogIds: [],
                title: '',
            };
        case LOAD_CATEGORY_CATALOG_IDS_SUCCESS:
            return {
                ...state,
                categoryCatalogIds: action.payload.ids,
                title: action.payload.title,
            };
        default:
            return state;
    }
}

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
export const categoryCatalogCardsSelector = createSelector(stateSelector, (state) => state.categoryCatalogCards);

export const getCatalogIdsForCategory = createSelector(
    categoryCatalogCardsSelector,
    (state) => state.categoryCatalogIds || []
);
export const getCategoryTitle = createSelector(categoryCatalogCardsSelector, (state) => state.title || []);

/* ACTION CREATORS */
export const fetchCatalogIds = (searchTerm: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const deployment = getDeployment(state);
    try {
        const response = await fetchCatalogIdsBySearchTerm({
            deployment,
            searchTerm,
        });
        let categoryTitle = searchTerm.split(' ');
        const uppercaseTitle = categoryTitle
            .map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
        dispatch({
            payload: {
                ids: response.payload,
                title: uppercaseTitle,
            },
            type: LOAD_CATEGORY_CATALOG_IDS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            error: true,
            meta: { searchTerm },
            payload: error,
            type: LOAD_CATEGORY_CATALOG_IDS_FAIL,
        });
    }
};
