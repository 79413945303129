import { CHECKOUT_REDUX_STORE_TIME } from '@/redux/modules/checkout/checkout.types';
import { createSelector } from '@reduxjs/toolkit';
import { getEmptyCheckoutInvoice } from '@/types/Invoice';
import { GlobalState } from '@/redux/store';
import isEmpty from 'lodash/isEmpty';

const stateSelector = (state: GlobalState) => state;
const checkoutSlice = createSelector(stateSelector, (state) => state.checkout);

const idSelector = (_: GlobalState, id: number) => id;
const hasLiveShippingSelector = (_: GlobalState, id: number, hasLiveShipping?: boolean) => hasLiveShipping;

const byIdSelector = createSelector(checkoutSlice, (state) => state.byId);

const loadedSelector = createSelector(checkoutSlice, (state) => state.loaded);

const loadingSelector = createSelector(checkoutSlice, (state) => state.loading);

const errorSelector = createSelector(checkoutSlice, (state) => state.error);
export const allCheckoutErrorsSelector = createSelector(checkoutSlice, (state) => state.error);

export const getInvoiceById = createSelector(
    [byIdSelector, idSelector, hasLiveShippingSelector],
    (byId, id, hasLiveShipping) => {
        return byId[id] || getEmptyCheckoutInvoice(hasLiveShipping);
    }
);

export const getBalanceDueSubtotal = createSelector([getInvoiceById], ({ invoice }) => invoice.balanceDueSubtotal);

export const getInvoiceShippingMethod = createSelector([getInvoiceById], ({ shippingMethod }) => shippingMethod);

export const getInvoiceSent = createSelector(
    [getInvoiceById],
    (checkoutInvoice) => checkoutInvoice.invoice.sent || false
);

export const getInvoiceLoadTime = createSelector([loadedSelector, idSelector], (loaded, id) => loaded[id] || 0);
export const invoiceInitialLoadSelector = createSelector(checkoutSlice, (state) => state.initialLoad);

export const getIsInvoiceLoaded = createSelector([getInvoiceLoadTime], (loadTime) => loadTime > 0);

export const getIsInvoiceReopened = createSelector([getInvoiceById], ({ invoice }) => invoice.reopened ?? false);

const isInvoiceLoading = createSelector([loadingSelector, idSelector], (loading, id) => loading.includes(id));

export const addedPaymentMethodOnCheckoutSelector = createSelector(
    checkoutSlice,
    (state) => state.addedPaymentMethodOnCheckout
);

export const getInvoiceErrors = createSelector([errorSelector, idSelector], (errors, catalogId) => {
    const error = errors[catalogId];
    if (!error) {
        return undefined;
    }
    if ((error as any).status === 500) {
        return 'Error loading invoice, please refresh and try again';
    }
    return error.toString();
});

export const invoiceHasNonLAPPayments = createSelector([getInvoiceById], (invoice) => {
    return invoice?.invoice?.payments?.filter((payment) => payment.lap !== 'LAP').length > 0;
});

export const shouldFetchInvoice = createSelector(
    [idSelector, getInvoiceById, getInvoiceLoadTime, isInvoiceLoading],
    (catalogId, invoice, loaded, loading) => {
        if (!catalogId) {
            return false;
        }
        if (!isEmpty(invoice) && invoice.invoice.id > 0) {
            const time = Date.now();
            const diff = time - loaded;
            if (diff < CHECKOUT_REDUX_STORE_TIME) {
                return false;
            }
        }
        return !loading;
    }
);
