import { AuctionStatus } from '@/types/LiveAuctionState';
import { combineActions, handleActions } from 'redux-actions';
import { createSelector, Reducer } from '@reduxjs/toolkit';
import { getMobileBrowserOS, getUiBrowser, isUiTablet } from './browser';
import { GlobalState } from '@/redux/store';
import {
    LIVE_ASK_CHANGED,
    LIVE_ASK_CHANGED_ACTION,
    LIVE_AUCTION_ENDED,
    LIVE_AUCTION_ENDED_ACTION,
    LIVE_AUCTION_MOVED,
    LIVE_AUCTION_MOVED_ACTION,
    LIVE_AUCTION_OPENED,
    LIVE_AUCTION_OPENED_ACTION,
    LIVE_AUCTION_PAUSED,
    LIVE_AUCTION_PAUSED_ACTION,
    LIVE_AUCTION_RESUMED,
    LIVE_AUCTION_RESUMED_ACTION,
    LIVE_AUCTION_STARTED,
    LIVE_AUCTION_STARTED_ACTION,
    LIVE_BID_ACCEPTED,
    LIVE_BID_RETRACTED,
    LIVE_LOT_CLOSED,
    LIVE_LOT_CLOSED_ACTION,
    LIVE_LOT_PASSED,
    LIVE_LOT_PASSED_ACTION,
    LIVE_LOT_REOPENED,
    LIVE_LOT_REOPENED_ACTION,
    LIVE_LOT_SOLD,
    LIVE_LOT_SOLD_ACTION,
    LIVE_LOT_UNSOLD,
    LIVE_LOT_UNSOLD_ACTION,
    LIVE_MISSIVE,
    LIVE_MISSIVE_ACTION,
    LIVE_NETWORK_DISCONNECTED,
    LIVE_NETWORK_DISCONNECTED_ACTION,
    LIVE_NETWORK_RECONNECTED,
    LIVE_NETWORK_RECONNECTED_ACTION,
    LIVE_NEXT_LOT_LOADED,
    LIVE_NEXT_LOT_LOADED_ACTION,
    LIVE_UPDATE_CATALOG_OCCUPANCY,
    LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION,
    LIVE_UPDATE_TIMESTAMP,
    LIVE_UPDATE_TIMESTAMP_ACTION,
    LiveBidAcceptedAction,
    LiveBidRetractedAction,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS,
    LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION,
} from './actions';
import { LiveCatalogData } from '@/types/LiveCatalogData';
import { LiveItemData } from '@/types/LiveItemData';
import { LOAD_CATALOGS_SUCCESS, LoadCatalogsSuccessAction } from '@/redux/modules/catalog/catalogs/catalog.actions';
import {
    LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS,
    LoadLiveCatalogRegistrationSuccessAction,
} from '@/redux/modules/actions/registerForCatalog';
import cloneDeep from 'lodash/cloneDeep';

/* helpers */
export const getCurrentTimestamp = () => Date.now();

export interface ConsoleSlice {
    readonly catalogs: {
        [catalogId: number]: LiveCatalogData;
    };
    readonly items: {
        [itemId: number]: LiveItemData;
    };
    readonly timestamps: {
        [catalogId: number]: number;
    };
}

export const defaultConsoleSlice: ConsoleSlice = {
    catalogs: {},
    items: {},
    timestamps: {},
};

export const DEFAULT_CATALOG: LiveCatalogData = {
    activeBidder: false,
    approved: false,
    bidderOutbid: false,
    bidders: 0, // this is computed in the selector
    closed: true,
    currentItem: 0,
    currentItemIndex: 0,
    networkStatus: 'connected',
    pubnubBidderCount: 0,
    pubNubPublishKey: '',
    sfsBidderCount: 0,
    status: AuctionStatus.NotLoaded,
    won: false,
};

export const DEFAULT_ITEM: LiveItemData = {
    bidCount: 0,
    created: 0,
    currentAskPrice: 0,
    isReserveMet: false,
    leadingBid: 0,
    leadingBidder: 0,
};

const setCatalogLive = (state: ConsoleSlice, catalogId: number): ConsoleSlice['catalogs'] => {
    const catalogs = cloneDeep(state.catalogs);
    catalogs[catalogId] = cloneDeep(catalogs[catalogId] || DEFAULT_CATALOG);
    catalogs[catalogId].status = AuctionStatus.Live;
    return catalogs;
};

const consoleReducer: Reducer<ConsoleSlice> = handleActions(
    {
        [LIVE_ASK_CHANGED]: (state: ConsoleSlice, action: LIVE_ASK_CHANGED_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].currentItem = action.payload.itemId;
            catalogs[action.payload.catalogId].currentItemIndex = action.payload.itemIndex;

            const items = cloneDeep(state.items);
            items[action.payload.itemId] = items[action.payload.itemId] || DEFAULT_ITEM;
            items[action.payload.itemId].currentAskPrice = action.payload.ask;

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_AUCTION_ENDED]: (state: ConsoleSlice, action: LIVE_AUCTION_ENDED_ACTION): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].status = AuctionStatus.Done;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_MOVED]: (state: ConsoleSlice, action: LIVE_AUCTION_MOVED_ACTION): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].status = AuctionStatus.NotLoaded;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_OPENED]: (state: ConsoleSlice, action: LIVE_AUCTION_OPENED_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_AUCTION_PAUSED]: (state: ConsoleSlice, action: LIVE_AUCTION_PAUSED_ACTION): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].status = AuctionStatus.Paused;

            return {
                ...state,
                catalogs,
            };
        },
        [combineActions(LIVE_AUCTION_RESUMED, LIVE_AUCTION_STARTED)]: (
            state: ConsoleSlice,
            action: LIVE_AUCTION_RESUMED_ACTION | LIVE_AUCTION_STARTED_ACTION
        ): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].status = AuctionStatus.Paused;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_BID_ACCEPTED]: (state: ConsoleSlice, action: LiveBidAcceptedAction): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            const { catalogId, itemId } = action.payload;
            catalogs[catalogId].activeBidder =
                Boolean(catalogs[catalogId].activeBidder) || Boolean(action.payload.myBid);
            catalogs[catalogId].bidderOutbid = Boolean(action.payload.bidderOutbid);
            catalogs[catalogId].closed = false;
            catalogs[catalogId].currentItem = itemId;
            catalogs[catalogId].currentItemIndex = action.payload.itemIndex;

            const items = cloneDeep(state.items);
            items[itemId] = cloneDeep(items[itemId] || DEFAULT_ITEM);

            if (items[itemId].created < action.payload.created || action.payload.isTimedPlusAuction) {
                items[itemId].created = action.payload.created;
                items[itemId].currentAskPrice = action.payload.ask;
                items[itemId].leadingBid = action.payload.amount;
                items[itemId].leadingBidder = action.payload.bidderId;
                items[itemId].bidCount = items[itemId].bidCount ? items[itemId].bidCount + 1 : 1;
                items[action.payload.itemId].isReserveMet = action.payload.isReserveMet;
            }

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_BID_RETRACTED]: (state: ConsoleSlice, action: LiveBidRetractedAction): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].bidderOutbid = false;
            catalogs[action.payload.catalogId].closed = false;
            catalogs[action.payload.catalogId].currentItem = action.payload.itemId;
            catalogs[action.payload.catalogId].currentItemIndex = action.payload.itemIndex;

            const items = cloneDeep(state.items);
            items[action.payload.itemId] = cloneDeep(items[action.payload.itemId] || DEFAULT_ITEM);
            items[action.payload.itemId].created = action.payload.created;
            items[action.payload.itemId].currentAskPrice = action.payload.ask;
            items[action.payload.itemId].leadingBid = action.payload.amount;
            items[action.payload.itemId].leadingBidder = action.payload.bidderId;
            items[action.payload.itemId].bidCount =
                items[action.payload.itemId]?.bidCount > 1 ? items[action?.payload?.itemId].bidCount - 1 : 0;
            items[action.payload.itemId].isReserveMet = action.payload.isReserveMet;

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_LOT_CLOSED]: (state: ConsoleSlice, action: LIVE_LOT_CLOSED_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].bidderOutbid = false;

            if (catalogs[action.payload.catalogId].currentItem === action.payload.itemId) {
                catalogs[action.payload.catalogId].closed = true;
            }

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_LOT_PASSED]: (state: ConsoleSlice, action: LIVE_LOT_PASSED_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].bidderOutbid = false;
            catalogs[action.payload.catalogId].closed = true;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_LOT_REOPENED]: (state: ConsoleSlice, action: LIVE_LOT_REOPENED_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].closed = false; // if from new system set variables, else don't -- old message does not have askprice

            if (
                action.payload.askPrice !== null &&
                action.payload.askPrice !== undefined &&
                action.payload.askPrice !== 0
            ) {
                catalogs[action.payload.catalogId].activeBidder =
                    Boolean(catalogs[action.payload.catalogId].activeBidder) || Boolean(action.payload.myBid);
                catalogs[action.payload.catalogId].bidderOutbid = false;
                catalogs[action.payload.catalogId].currentItem = action.payload.itemId;
                catalogs[action.payload.catalogId].currentItemIndex = action.payload.itemIndex;
                catalogs[action.payload.catalogId].won = false;

                const items = cloneDeep(state.items);

                items[action.payload.itemId] = cloneDeep(items[action.payload.itemId] || DEFAULT_ITEM);
                items[action.payload.itemId].created = action.payload.created;
                items[action.payload.itemId].currentAskPrice = action.payload.askPrice;
                items[action.payload.itemId].bidCount = 0;
                items[action.payload.itemId].leadingBid = action.payload.leadingBid;
                items[action.payload.itemId].leadingBidder = action.payload.leadingBidder;

                return {
                    ...state,
                    catalogs,
                    items,
                };
            } else {
                return {
                    ...state,
                    catalogs,
                };
            }
        },
        [LIVE_LOT_SOLD]: (state: ConsoleSlice, action: LIVE_LOT_SOLD_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].bidderOutbid = false;
            catalogs[action.payload.catalogId].closed = true;
            catalogs[action.payload.catalogId].won = action.payload.myBid;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_LOT_UNSOLD]: (state: ConsoleSlice, action: LIVE_LOT_UNSOLD_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].bidderOutbid = false;
            catalogs[action.payload.catalogId].closed = true;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_MISSIVE]: (state: ConsoleSlice, action: LIVE_MISSIVE_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_NETWORK_DISCONNECTED]: (state: ConsoleSlice, action: LIVE_NETWORK_DISCONNECTED_ACTION): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].networkStatus = 'disconnected';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_NETWORK_RECONNECTED]: (state: ConsoleSlice, action: LIVE_NETWORK_RECONNECTED_ACTION): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].networkStatus = 'connected';

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_NEXT_LOT_LOADED]: (state: ConsoleSlice, action: LIVE_NEXT_LOT_LOADED_ACTION): ConsoleSlice => {
            const catalogs = setCatalogLive(state, action.payload.catalogId);
            catalogs[action.payload.catalogId].activeBidder = false;
            catalogs[action.payload.catalogId].bidderOutbid = false;
            catalogs[action.payload.catalogId].closed = false;
            catalogs[action.payload.catalogId].currentItem = action.payload.itemId;
            catalogs[action.payload.catalogId].currentItemIndex = action.payload.itemIndex;
            catalogs[action.payload.catalogId].won = false;

            const items = cloneDeep(state.items);
            items[action.payload.itemId] = cloneDeep(items[action.payload.itemId] || DEFAULT_ITEM);
            items[action.payload.itemId].created = action.payload.created;
            items[action.payload.itemId].currentAskPrice = action.payload.askPrice;
            items[action.payload.itemId].bidCount = 0;
            items[action.payload.itemId].leadingBid = 0;
            items[action.payload.itemId].leadingBidder = 0;

            return {
                ...state,
                catalogs,
                items,
            };
        },
        [LIVE_UPDATE_CATALOG_OCCUPANCY]: (
            state: ConsoleSlice,
            action: LIVE_UPDATE_CATALOG_OCCUPANCY_ACTION
        ): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);
            catalogs[action.payload.catalogId].pubnubBidderCount = action.payload.bidderCount;

            return {
                ...state,
                catalogs,
            };
        },
        [LIVE_UPDATE_TIMESTAMP]: (state: ConsoleSlice, action: LIVE_UPDATE_TIMESTAMP_ACTION): ConsoleSlice => {
            return {
                ...state,
                timestamps: {
                    ...state.timestamps,
                    [action.payload.catalogId]: action.payload.timestamp,
                },
            };
        },
        [LOAD_CATALOGS_SUCCESS]: (state: ConsoleSlice, action: LoadCatalogsSuccessAction): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);

            action.payload.catalogs.forEach((catalog) => {
                if (catalog.catalogStatus === AuctionStatus.Done) {
                    // set the catalog to done if it is
                    catalogs[catalog.catalogId] = cloneDeep(catalogs[catalog.catalogId] || DEFAULT_CATALOG);
                    catalogs[catalog.catalogId].status = catalog.catalogStatus;
                }
            });

            return {
                ...state,
                catalogs,
            };
        },
        [LOAD_LIVE_CATALOG_REGISTRATION_SUCCESS]: (
            state: ConsoleSlice,
            action: LoadLiveCatalogRegistrationSuccessAction
        ): ConsoleSlice => {
            const catalogs = cloneDeep(state.catalogs);
            catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);

            if (catalogs[action.payload.catalogId].approved !== action.payload.approved) {
                catalogs[action.meta.catalogId].approved = action.payload.approved;

                return {
                    ...state,
                    catalogs,
                };
            }

            return state;
        },
        [LOAD_LIVE_CATALOG_STATUS_SUCCESS]: (
            state: ConsoleSlice,
            action: LOAD_LIVE_CATALOG_STATUS_SUCCESS_ACTION
        ): ConsoleSlice => {
            if (action.payload.status !== 'notLoaded') {
                const catalogs = cloneDeep(state.catalogs);
                catalogs[action.payload.catalogId] = cloneDeep(catalogs[action.payload.catalogId] || DEFAULT_CATALOG);

                if (catalogs[action.payload.catalogId].status !== AuctionStatus.Done) {
                    catalogs[action.payload.catalogId].status = action.payload.status;
                }

                const items = cloneDeep(state.items);

                if (action.payload.currentItem && action.payload.currentItem.itemId) {
                    const { askPrice, itemId, itemIndex, leadingBid, leadingBidder, lotsClosed } =
                        action.payload.currentItem;

                    catalogs[action.payload.catalogId].closed = false;
                    catalogs[action.payload.catalogId].timedPlusClosedCount = lotsClosed;
                    catalogs[action.payload.catalogId].currentItem = itemId;
                    catalogs[action.payload.catalogId].currentItemIndex = itemIndex || 0;
                    catalogs[action.payload.catalogId].won = false;

                    items[itemId] = items[itemId] || DEFAULT_ITEM;
                    items[itemId].created = getCurrentTimestamp();
                    items[itemId].currentAskPrice = askPrice;
                    items[itemId].leadingBid = leadingBid || 0;
                    items[itemId].leadingBidder = leadingBidder || 0;
                }

                return {
                    ...state,
                    catalogs,
                    items,
                };
            } else {
                return { ...state };
            }
        },
    },
    defaultConsoleSlice
);

export default consoleReducer;

/* SELECTORS */
const stateSelector = (state: GlobalState) => state;
const idSelector = (_: GlobalState, id: number) => id;

export const consoleSelector = createSelector(stateSelector, (state) => state.console);

const catalogsSelector = createSelector(consoleSelector, (state) => state.catalogs);
const itemsSelector = createSelector(consoleSelector, (state) => state.items);
const timestampSelector = createSelector(consoleSelector, (state) => state.timestamps);

export const getLiveCatalogData = createSelector([catalogsSelector, idSelector], (catalogs, id): LiveCatalogData => {
    const built = { ...DEFAULT_CATALOG, ...(catalogs[id] || {}) };
    built.bidders = built.sfsBidderCount + built.pubnubBidderCount || 1;
    return built;
});

export const getLatestMessageTimestamp = createSelector(
    [timestampSelector, idSelector],
    (timestamps, id) => timestamps[id] || 0
);

export const getCatalogNetworkStatus = createSelector(
    getLiveCatalogData,
    ({ networkStatus }) => networkStatus || 'connected'
);

export const getLiveCatalogSfsBidderCount = createSelector(getLiveCatalogData, ({ sfsBidderCount }) => sfsBidderCount);
export const getLiveCatalogPubnubBidderCount = createSelector(
    getLiveCatalogData,
    ({ pubnubBidderCount }) => pubnubBidderCount
);

export const getLiveCatalogBidders = createSelector(
    [getLiveCatalogSfsBidderCount, getLiveCatalogPubnubBidderCount],
    (sfsBidderCount, pubnubBidderCount) => {
        return (sfsBidderCount || 0) + (pubnubBidderCount || 0) || 1;
    }
);

/**
 * @returns The sum of sfsBidderCount and pubnubBidderCount.
 *   This function will always return AT LEAST 1 to account for the current user
 */
export const getLiveCatalogStatus = createSelector(getLiveCatalogData, ({ status }) => status);

export const getLiveCatalogPaused = createSelector(getLiveCatalogStatus, (status) => status === 'paused');

export const getLiveCatalogClosed = createSelector([getLiveCatalogData], ({ closed }) => closed);

export const getLiveActiveBidder = createSelector([getLiveCatalogData], ({ activeBidder }) => activeBidder);

export const getLiveBidderOutbid = createSelector([getLiveCatalogData], ({ bidderOutbid }) => bidderOutbid);

export const getLiveCatalogWon = createSelector([getLiveCatalogData], ({ won }) => won);

export const getLiveCatalogApproved = createSelector([getLiveCatalogData], ({ approved }) => approved);

export const getLiveCatalogCurrentItemId = createSelector([getLiveCatalogData], ({ currentItem }) => currentItem);

export const getLiveCatalogCurrentItemIndex = createSelector(
    [getLiveCatalogData],
    ({ currentItemIndex }) => currentItemIndex
);

export const getLiveItemData = createSelector(
    [itemsSelector, idSelector],
    (items, id): LiveItemData => items[id] || DEFAULT_ITEM
);

export const getLiveItemDataCurrentAskPrice = createSelector(
    getLiveItemData,
    ({ currentAskPrice }) => currentAskPrice || 0
);
export const getLiveItemDataLeadingBid = createSelector(getLiveItemData, ({ leadingBid }) => leadingBid || 0);
export const getLiveItemDataLeadingBidder = createSelector(getLiveItemData, ({ leadingBidder }) => leadingBidder || 0);
export const getLiveItemDataBidCount = createSelector(getLiveItemData, ({ bidCount }) => bidCount || 0);

export const getBrowserSessionCookieSuffix = (state: GlobalState) => {
    const mobileBrowser = getMobileBrowserOS(state);
    const tablet = isUiTablet(state);
    const { majorVersion, name } = getUiBrowser(state);
    const browser = `${name}${majorVersion}`;

    const deviceType = !mobileBrowser ? 'computer' : tablet ? 'tablet' : 'phone';
    return `::web-${deviceType}-${browser}`;
};
