import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { fetchCheckoutShippingQuote, postCheckoutShippingQuote } from './checkoutShippingQuote.api';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '@/redux/modules/config';
import { ShippingQuote } from '@/types/ShippingQuote';
import { SubmitCheckoutShippingQuoteInput } from './checkoutShippingQuote.types';

export type LoadCheckoutShippingQuotesResponse = {
    actionTime: number;
    data: ShippingQuote;
    errorCode: number;
};

export const loadCheckoutShippingQuote = createAsyncThunk<LoadCheckoutShippingQuotesResponse, number>(
    'la/domain/catalogDetail/loadCheckoutShippingQuote',
    async (invoiceId, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        let response;
        try {
            response = await fetchCheckoutShippingQuote({
                authToken,
                deployment,
                invoiceId,
            });
            if (response.statusCode >= 400) {
                response.statusCode = 400;
            } else {
                response.statusCode = 0;
            }
        } catch (error) {
            return {
                actionTime: Date.now(),
                data: {},
                errorCode: 500,
            };
        }
        return {
            actionTime: Date.now(),
            data: response?.payload || {},
            errorCode: response?.statusCode || 0,
        };
    }
);

export const submitCheckoutShippingQuote = createAsyncThunk<void, SubmitCheckoutShippingQuoteInput>(
    'la/domain/catalogDetail/submitCheckoutShippingQuote',
    async (input, { getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        await postCheckoutShippingQuote({
            amount: input.amount,
            authToken,
            bidderId: input.bidderId,
            deployment,
            invoiceId: input.invoiceId,
            usingLaShipping: input.usingLaShipping,
        });
    }
);
