import { AcceptedTermsOfService } from '@/redux/modules/account/user/user.types';
import { createAsyncThunk } from '@/redux/createAsyncThunk';
import { getAuthToken } from '@/redux/modules/account/user/user.selectors';
import { getDeployment } from '../config';
import { postBidderConsent } from './consent.api';
import { SubmitConsentPendingPayload } from '@/redux/modules/consent/consent.types';
import { TypedActionWithPayload } from '@/types/redux';

/**
 * @deprecated If using redux-toolkit's createSlice, use submitConsent.fulfilled
 */
export const SUBMIT_CONSENT_SUCCESS = 'la/ui/consent/SUCCESS';

export const submitConsent = createAsyncThunk<AcceptedTermsOfService, SubmitConsentPendingPayload>(
    'la/domain/consent/submitConsent',
    async ({ consent, context }, { dispatch, getState }) => {
        const state = getState();
        const authToken = getAuthToken(state);
        const deployment = getDeployment(state);
        const { payload } = await postBidderConsent({
            authToken,
            consent,
            context,
            deployment,
        });
        const successAction: TypedActionWithPayload<typeof SUBMIT_CONSENT_SUCCESS, 'accepted', { actionTime: number }> =
            {
                error: false,
                meta: {
                    actionTime: Date.now(),
                },
                payload: 'accepted',
                type: SUBMIT_CONSENT_SUCCESS,
            };
        dispatch(successAction);
        return payload;
    }
);
