import { CatalogDetail } from '@/types/CatalogDetail';

export type CatalogDetailState = {
    byId: { [id: number]: CatalogDetail };
    loaded: { [id: number]: number };
    loading: number[];
};

export const defaultCatalogDetailSlice: CatalogDetailState = {
    byId: {},
    loaded: {},
    loading: [],
};
